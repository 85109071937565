import React, { useState } from "react";
import { t } from "i18next";
import { updateReportFilter } from "../static/constants";
import { softwareService } from "../services/";
import { useAlert, positions } from "react-alert";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import Loading from "./Loading";
const SoftwareUpdateInfoModal = ({
  setIsOpen,
  updateReportData,
  setUpdateReportData,
}) => {
  const alert = useAlert();
  const [selectedNodes, setSelectedNodes] = useState([]);
  const onSubmit = async () => {
    if (selectedNodes.length > 0) {
      await softwareService
        .updateNext(selectedNodes)
        .then((res) => {
          if (res.data.code == 0) {
            alert.success("Successfull.", {
              position: positions.TOP_LEFT,
            });
          } else {
            alert.error("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    setIsOpen(false);
  };
  const channel = useChannel("software-update");
  useEvent(channel, "software-updated", (socketEvent) => {
    console.log("SOCKET", socketEvent);
    setUpdateReportData((prev) => {
      return prev.map((item) => {
        if (item.companyId == socketEvent.companyId) {
          return {
            ...item,
            item: item.item.map((node) => {
              if (node.nodeId == socketEvent.nodeId) {
                return {
                  ...node,
                  message: socketEvent.message,
                  newVersion: socketEvent.newVersion,
                };
              } else {
                return node;
              }
            }),
          };
        } else {
          return item;
        }
      });
    });
  });
  return (
    <div className="flex flex-col mt-4 gap-y-4 overflow-hidden overflow-x-scroll scrollbar-hide">
      {updateReportData?.map((company, index) => {
        return (
          <div key={index}>
            {company?.companyId != "undefined" ? (
              <>
                <div className="flex justify-between items-center w-full pb-2">
                  <div className="w-[90%] cursor-pointer flex items-center overflow-hidden gap-x-2">
                    <span className="font-medium text-base text-[#1D2939]">
                      {company?.companyName}
                    </span>
                    <span className="bg-primary-50 rounded-2xl font-medium text-primary-600 text-xs px-2 py-0.5">
                      {company?.item?.length}
                    </span>
                  </div>
                </div>
                <div className="border scrollbar-hide overflow-x-scroll rounded-lg border-gray-300">
                  <div className="w-full justify-between flex h-9 bg-gray-50  border-b z-10">
                    {updateReportFilter.map((item, i) => (
                      <span
                        key={item.value}
                        className={`flex gap-3 h-full items-center w-full text-xs text-gray-500 cursor-pointer hover:bg-gray-100 border-r pl-5`}
                      >
                        {t(`company:${item.value}`)}
                      </span>
                    ))}
                    <span className="flex gap-3 h-full max-w-[130px] min-w-[130px] items-center w-full text-xs text-gray-500 cursor-pointer hover:bg-gray-100 border-r" />
                  </div>
                  <div className="max-h-[500px] w-full overflow-y-scroll scrollbar-hide h-full">
                    {company?.item?.map((node, i) => {
                      return (
                        <div
                          key={i}
                          className="w-full h-[60px] relative overflow-x-scroll"
                        >
                          <ul
                            className="w-full h-[60px] flex items-center border-b border-gray-200 group"
                            key={i}
                          >
                            {updateReportFilter.map((d, i) => {
                              return (
                                <li
                                  key={i}
                                  onClick={() => {}}
                                  className="h-full border-r select-none w-full max-w-[223px] flex items-center justify-center text-sm text-secondary-600 group-hover:bg-slate-50"
                                >
                                  {d.value === "updateStatus" ? (
                                    <>
                                      {node.message ? (
                                        node.message == "Success" ? (
                                          <span className="font-semibold text-[#039855] text-sm">
                                            {t("software:success")}
                                          </span>
                                        ) : (
                                          <div className="flex flex-col gap-y-0.5">
                                            <span className="font-semibold text-[#D92D20] text-sm">
                                              {t("software:failed")}
                                            </span>
                                            <span className="text-[#667085] text-xs truncate">
                                              {node.reason}
                                            </span>
                                          </div>
                                        )
                                      ) : (
                                        <div className="w-4 h-4">
                                          <Loading />
                                        </div>
                                      )}
                                    </>
                                  ) : d.value === "brand" ? (
                                    <>
                                      {node.brand ? node.brand : "--"} /{" "}
                                      {node.model ? node.model : "--"}
                                    </>
                                  ) : (
                                    <>
                                      {node[d.value] ? (
                                        node[d.value]
                                      ) : (
                                        <div className="w-4 h-4">
                                          <Loading />
                                        </div>
                                      )}
                                    </>
                                  )}
                                </li>
                              );
                            })}
                            <li
                              onClick={() => {
                                if (node.message != "Success") {
                                  if (selectedNodes.includes(node.nodeId)) {
                                    setSelectedNodes((prev) =>
                                      prev.filter(
                                        (item) => item !== node.nodeId
                                      )
                                    );
                                  } else {
                                    setSelectedNodes((prev) => [
                                      ...prev,
                                      node.nodeId,
                                    ]);
                                  }
                                }
                              }}
                              className="h-full border-r select-none w-full max-w-[130px] min-w-[130px] flex items-center justify-center gap-x-1 text-sm text-secondary-600 group-hover:bg-slate-50"
                            >
                              {node.message ? (
                                node.message != "Success" && (
                                  <>
                                    <input
                                      checked={selectedNodes.includes(
                                        node.nodeId
                                      )}
                                      type="checkbox"
                                      className="w-4 h-4 rounded-md border text-primary-600 bg-primary-100 border-primary-50 accent-primary-500"
                                    />
                                    <label className="flex flex-col text-[10px] text-[#667085]">
                                      {t("software:updateNextTime")}
                                    </label>
                                  </>
                                )
                              ) : (
                                <div className="w-4 h-4">
                                  <Loading />
                                </div>
                              )}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="flex justify-between items-center w-full pb-2">
                  <div className="w-[90%] cursor-pointer flex items-center overflow-hidden gap-x-2">
                    <span className="font-medium text-base text-[#1D2939]">
                      {t("software:updateFailed")}
                    </span>
                  </div>
                </div>
                <div className="border scrollbar-hide overflow-x-scroll rounded-lg border-gray-300">
                  <div className="w-full justify-between flex h-9 bg-gray-50  border-b z-10">
                    <span
                      className={`flex w-1/2 gap-3 h-full items-center text-xs text-gray-500 cursor-pointer hover:bg-gray-100 border-r pl-5`}
                    >
                      {t(`company:company`)}
                    </span>
                    <span
                      className={`flex gap-3 h-full items-center w-full text-xs text-gray-500 cursor-pointer hover:bg-gray-100 border-r pl-5`}
                    >
                      {t(`company:reason`)}
                    </span>
                  </div>
                  <div className="max-h-[500px] w-full overflow-y-scroll scrollbar-hide h-full">
                    {company?.item?.map((node, i) => {
                      return (
                        <div
                          key={i}
                          className="w-full h-[60px] relative overflow-x-scroll"
                        >
                          <ul
                            className="w-full h-[60px] flex items-center border-b border-gray-200 group"
                            key={i}
                          >
                            <li
                              key={i}
                              onClick={() => {}}
                              className="h-full border-r select-none w-1/2 truncate text-xs flex items-center pl-5 text-secondary-600 group-hover:bg-slate-50"
                            >
                              {node.message.slice(
                                6,
                                Number(node.message.indexOf("için") - 1)
                              )}
                            </li>
                            <li
                              key={i}
                              onClick={() => {}}
                              className="h-full border-r select-none w-full flex items-center pl-5 text-xs text-secondary-600 group-hover:bg-slate-50"
                            >
                              {node?.message}
                            </li>
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
            <div className="absolute w-full max-w-[935px] bg-white bottom-[16px] flex items-center justify-center gap-4">
              <button
                type="button"
                onClick={() => setIsOpen(false)}
                className="w-1/2 h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 hover:bg-slate-100"
              >
                {t("buttons:stop")}
              </button>
              <button
                type="submit"
                onClick={() => onSubmit()}
                className="w-1/2 h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 hover:bg-primary-500"
              >
                {t("buttons:save")}
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SoftwareUpdateInfoModal;
