import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import DigitalSensorList from "./components/DigitalSensorList";
import AnalogSensorList from "./components/AnalogSensorList";
import { Button } from "../../components";
import PriorityTable from "./components/PriorityTable";
import defaultSensorService from "../../services/default-sensor.service";
import { UpuTowerText } from "./svg";
import { Loading } from "./svg/EmbeddedServerIcons";

const AddEmbeddedServerSettings = ({ modalIsOpen, closeModal, selectedSettings, refetch }) => {
  const [loading, setLoading] = useState(false);
  const [draggedSensor, setDraggedSensor] = useState(null);
  const [registeredSensors, setRegisteredSensors] = useState(null);
  const [priorityList, setPriorityList] = useState([]);
  const handleData = async () => {
    setLoading(true);
    await defaultSensorService.findOneDefaultSensor(selectedSettings).then(({ data }) => {
      setRegisteredSensors(data);
      setLoading(false);
    });
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <Modal
      appElement={document.getElementById("app")}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      className={`h-[97%] w-[1072px]`}
      style={{
        overlay: { background: "rgba(0, 0, 0, 0.3)" },
        content: {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          width: "95%",
          background: "#fff",
          height: "95%",
          padding: "24px 24px 16px",
          border: "1px solid #EAECF0",
          boxShadow: "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          borderRadius: "16px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <div className="w-full h-full flex flex-col">
        <div className="w-full h-[56px] border-b border-gray-300 flex items-center mb-6">
          <div className="flex items-center h-full">
            <div className="w-[135px]">
              <UpuTowerText />
            </div>
            <span className="text-lg lg:text-base md:text-base sm:text-sm text-secondary-500 truncate ml-3">Embedded Server</span>
          </div>
          <div className="gap-4 flex items-center ml-auto">
            <Button
              size={"sm"}
              width={177}
              label={"Exit"}
              colorType={"secondary-embedded"}
              onClick={() => {
                closeModal();
              }}
            />

            {/* <Button
                size={"sm"}
                width={180}
                colorType={"primary-embedded"}
                label={"Exit"}
                onClick={() => {
                  cancelButton();
                }}
              /> */}
          </div>
        </div>
        <div className="flex flex-1 w-full overflow-y-auto justify-between">
          <div className="w-[53%] h-full">
            <DigitalSensorList
              registeredSensors={registeredSensors}
              selectedSettings={selectedSettings}
              data={registeredSensors}
              setDraggedSensor={setDraggedSensor}
              priorityList={priorityList}
              refetch={handleData}
            />
            <AnalogSensorList
              data={registeredSensors}
              priorityList={priorityList}
              refetch={handleData}
              setDraggedSensor={setDraggedSensor}
              selectedSettings={selectedSettings}
              registeredSensors={registeredSensors}
            />
          </div>
          <div className="w-[45%] h-full py-0.5 pr-0.5">
            {loading ? (
              <div className="w-full rounded-xl h-full bg-gray-200 animate-pulse flex items-center justify-center">
                <Loading size={45} color={"#3E4784"} secondary={"#fff"} />
              </div>
            ) : (
              <PriorityTable
                loading={loading}
                defaultSensorId={selectedSettings}
                draggedSensor={draggedSensor}
                setPriorityList={setPriorityList}
                getRegisteredSensors={handleData}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddEmbeddedServerSettings;
