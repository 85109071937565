import { instance } from "../libs/client";
/* eslint-disable camelcase */

class StatusService {
  createStatus = async (data) => {
    return instance.post("system-status", data);
  };
  updateStatus = async (id, data) => {
    return instance.put(`system-status/${id}`, data);
  };
  deleteStatus = async (id) => {
    return instance.delete(`system-status/${id}`);
  };
  getStatusesPaginated = async (pageNo, search = "") => {
    return instance.get(
      `system-status/paginated?page=${pageNo}${
        search ? `&search=${search}` : ""
      }`
    );
  };
}

const statusService = new StatusService();

export default statusService;
