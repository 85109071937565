import React, { useState, useEffect, useRef } from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { DownArrow } from "../../assets/icons";
import generateFileUrl from "../../utils/generateFileUrl";
import diacritics from "diacritics";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const MultiSelect = ({
  selectedItems = [],
  setSelectedItems,
  items,
  placeholder,
  icon,
  disabled,
  errorMessage,
  validate,
  theme,
  size,
  className,
  isImage,
}) => {
  const inputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState(items);
  const [backspacePressed, setBackspacePressed] = useState(false);
  const selectedLabel =
    selectedItems?.length > 0
      ? selectedItems
          ?.map((item) =>
            item?.label?.length > 7 ? item?.label?.split(" ")[0] : item?.label
          )
          .join(", ")
      : "";
  const sizeStyles = {
    xs: "40px",
    sm: "44px",
  };
  const inputSize = sizeStyles[size] || "40px";
  const handleSearchInputChange = (e) => {
    if (e.target && e.target.value) {
      const input = e.target.value.toLowerCase();
      setSearchInput(input);
      const filteredResults = items.filter((item) =>
        diacritics
          .remove(item.label)
          .toLowerCase()
          .includes(
            diacritics.remove(input) ||
              diacritics
                .remove(item.shortLabel)
                .toLowerCase()
                .includes(diacritics.remove(input))
          )
      );
      setSearchResults(filteredResults);
    } else {
      setSearchInput("");
      setSearchResults([]);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Backspace") {
      setBackspacePressed(true);
      setTimeout(() => {
        setBackspacePressed(false);
      }, 100);
    }
  };
  useEffect(() => {
    if (!searchInput) {
      setSearchResults(items);
    }
  }, [searchInput, items, backspacePressed]);
  useEffect(() => {
    if (open) {
      inputRef.current.focus();
    }
  }, [open, handleKeyDown]);
  const handleToggle = () => {
    setOpen(!open);
  };
  const handleSelect = (item) => {
    const isSelected = selectedItems?.some(
      (selectedItem) => selectedItem.value === item.value
    );
    if (isSelected) {
      const updatedItems = selectedItems?.filter(
        (selectedItem) => selectedItem.value !== item.value
      );
      setSelectedItems(updatedItems);
    } else {
      const updatedItems = [...selectedItems, item];
      setSelectedItems(updatedItems);
    }
    setSearchInput("");
  };
  return (
    <div className="flex flex-col justify-between w-full relative h-[70px] min-h-[70px] max-h-[70px]">
      {placeholder && (
        <div className="text-sm pl-0 text-secondary-700 font-medium">
          {placeholder}
        </div>
      )}
      <div className={`relative group bg-white`}>
        <div
          style={{ height: inputSize }}
          onClick={handleToggle}
          className={`${
            open
              ? ` ${
                  className ? " " + className : ""
                } !rounded-t-default !rounded-b-none`
              : `${
                  className ? className : "bg-white border"
                } text-secondary-600`
          }     ${
            disabled ? "bg-secondary-50 " : ""
          } min-h-[44px] relative w-full px-[14px] py-[9px]  flex items-center border rounded-lg border-secondary-300 outline-secondary-300 text-secondary-500 leading-6 focus:ring-0 text-[16px]`}
        >
          {icon && icon}
          {open ? (
            <span className="block w-[95%] truncate ml-0.5 text-md font-medium text-secondary-900 text-sm">
              <input
                ref={inputRef}
                type="text"
                value={searchInput}
                onChange={handleSearchInputChange}
                className={`border-none outline-none focus:ring-0 w-full ${
                  disabled ? "bg-secondary-50" : "bg-white"
                } `}
              />
            </span>
          ) : (
            <span className="block truncate ml-0.5 text-md font-medium text-secondary-900 text-sm">
              {selectedItems?.length > 0 ? selectedLabel : ""}
            </span>
          )}
          <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
            <DownArrow
              className={`h-2 w-2 dark:text-[#FAFAFA]  ${
                open ? "rotate-180 text-secondary-600" : " text-secondary-600"
              } `}
              aria-hidden="true"
            />
          </span>
        </div>
        {open && (
          <div className="absolute z-[99999] w-full max-h-[200px] py-1 ring-opacity-5 overflow-auto scrollbar-hide focus:outline-none rounded-t-none rounded-lg shadow-lg bg-white border-secondary-200 border-t-0 border">
            {searchResults &&
              searchResults &&
              searchResults
                ?.slice()
                ?.sort((a, b) => {
                  const aIndex = selectedItems.findIndex(
                    (selectedItem) => selectedItem.value === a.value
                  );
                  const bIndex = selectedItems.findIndex(
                    (selectedItem) => selectedItem.value === b.value
                  );
                  return bIndex - aIndex;
                })
                ?.map((item) => (
                  <div
                    key={item.value}
                    onClick={() => {
                      handleSelect(item);
                      setSearchInput("");
                    }}
                    className={classNames(
                      selectedItems?.some(
                        (selectedItem) => selectedItem.value === item.value
                      )
                        ? "bg-white text-secondary-900"
                        : "text-secondary-900",
                      "cursor-pointer select-none flex flex-row items-center relative z-50 hover:bg-secondary-50 rounded-md mx-1 p-[10px]  text-xs"
                    )}
                  >
                    {isImage && (
                      <div className="h-full flex justify-center items-center mr-4 min-w-[48px]">
                        {item.image != null && item.image != "" ? (
                          <img
                            src={generateFileUrl(item.image)}
                            className="w-[48px] h-[36px] rounded border border-solid border-secondary-300"
                          />
                        ) : (
                          <div
                            className={
                              "w-[48px] h-[36px] bg-stone-600 rounded border border-solid border-secondary-300 shadow flex items-center justify-center text-white text-xl leading-none font-normal"
                            }
                          >
                            {item.label?.charAt(0).toUpperCase()}
                          </div>
                        )}
                      </div>
                    )}
                    <span
                      className={classNames(
                        "block truncate text-secondary-900",
                        selectedItems?.some(
                          (selectedItem) => selectedItem.value === item.value
                        )
                          ? "font-medium"
                          : "font-medium"
                      )}
                    >
                      {item.label}
                    </span>
                    {selectedItems?.some(
                      (selectedItem) => selectedItem.value === item.value
                    ) && (
                      <div className="flex items-center ml-auto">
                        <CheckIcon
                          width={20}
                          height={20}
                          color={
                            theme === "person"
                              ? "#0E9384"
                              : theme === "product"
                              ? "#DC6803"
                              : theme === "embedded"
                              ? "#3E4784"
                              : "#7F56D9"
                          }
                        />
                      </div>
                    )}
                  </div>
                ))}
          </div>
        )}
      </div>
      {validate && (
        <p
          className={`${
            validate === "success"
              ? "text-validate-success"
              : validate === "warning"
              ? "text-validate-warning"
              : validate === "error"
              ? "text-validate-error"
              : validate === "tooltip"
              ? "text-secondary-500 text-sm leading-5"
              : ""
          } text-sm mt-1.5 font-normal`}
        >
          {errorMessage || validate.toLocaleUpperCase()}
        </p>
      )}
    </div>
  );
};

export default MultiSelect;
