import React, { useState } from "react";
import { EditIcons, PlusIcons, TrashIcons } from "../../../assets/icons";
import { AdminModal } from "../../../components";
import NewTestTypeModal from "./NewTestTypeModal";
import { testingService } from "../../../services";

export default function TestTypesModal({ t, testTypes, handleTestTypes }) {
  const [isNewTestTypeModalOpen, setIsNewTestTypeModalOpen] = useState();
  const [selectedTestType, setSelectedTestType] = useState({});
  const deleteType = async (id) => {
    return await testingService
      .deleteType(id)
      .then((res) => {
        if (res.data.status == 1) {
          handleTestTypes();
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <div className="w-full h-full mt-5">
      <div className="flex flex-col min-h-[263px] max-h-[263px] gap-3 overflow-y-auto scrollbar-hide">
        {testTypes.map((type, i) => {
          return (
            <div className="w-full max-h-9 flex justify-between items-center">
              <span className="text-[#344054] font-semibold text-base">
                {type.name}
              </span>
              <div className="flex gap-x-3">
                <span
                  onClick={() => {
                    setSelectedTestType(type);
                    setIsNewTestTypeModalOpen(true);
                  }}
                  className="p-2"
                >
                  <EditIcons />
                </span>
                <span onClick={() => deleteType(type.id)} className="p-2">
                  <TrashIcons />
                </span>
              </div>
            </div>
          );
        })}
      </div>
      <div className="absolute w-[434px] bottom-3 right-8">
        <button
          className="w-full min-h-[44px] max-h-[44px] bg-white shadow-sm rounded-lg border border-[#D0D5DD] px-[14px] py-[10px] text-[#344054] text-sm font-semibold gap-x-1 flex items-center justify-center"
          onClick={() => {
            setSelectedTestType(null);
            setIsNewTestTypeModalOpen(true);
          }}
        >
          <PlusIcons width={20} height={20} />
          {t("test:newTest")}
        </button>
      </div>
      <AdminModal
        height={720}
        width={658}
        modalIsOpen={isNewTestTypeModalOpen}
        closeModal={() => setIsNewTestTypeModalOpen(false)}
        title={t("test:newTest")}
        children={
          <NewTestTypeModal
            handleTestTypes={handleTestTypes}
            setOpenModal={setIsNewTestTypeModalOpen}
            t={t}
            selectedTestType={selectedTestType}
          />
        }
      />
    </div>
  );
}
