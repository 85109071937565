import { Dialog, Transition } from "@headlessui/react";
import { Children, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";

const AlertModal = ({
  isAlert,
  setIsAlert,
  icon,
  header,
  text,
  label,
  deletedNode,
  applyFunction,
  isConfirm = false,
}) => {
  const { t } = useTranslation();
  function closeModal() {
    setIsAlert(false);
  }

  function openModal() {
    setIsAlert(true);
  }
  return (
    <>
      <Transition appear show={isAlert} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-[999] flex items-center"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto scrollbar-hide">
            <div className="flex min-h-full  w-auto  items-center justify-center sm:p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-auto xs:mt-40 sm:mt-40 transform overflow-hidden rounded-2xl bg-white p-2 xs:px-0 text-left align-middle shadow-xl transition-all">
                  <div className="overflow-auto w-full xs:w-fit max-w-[400px]">
                    <div className="flex flex-col items-center text-center justify-between gap-3 min-w-[400px] xs:min-w-fit max-w-[400px] min-h-[100px] p-3">
                      <div
                        className={` p-2 rounded-full border-8 ${
                          isConfirm
                            ? "text-primary-500 border-primary-50 bg-primary-100"
                            : "text-red-500 border-red-50 bg-red-100"
                        } `}
                      >
                        {icon}
                      </div>
                      <div className="flex flex-col items-center gap-3">
                        <div className="font-medium text-[18px] text-gray-900">
                          {header}
                        </div>
                        <div className="font-normal text-[14px] text-gray-500">
                          {text}
                        </div>
                      </div>
                      <div className="flex gap-2 items-center justify-center w-full font-medium text-[16px] mt-2">
                        <button
                          type="button"
                          onClick={() => closeModal()}
                          className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium border text-gray-600 border-gray-300 hover:bg-gray-50"
                        >
                          {t("buttons:stop")}
                        </button>
                        <button
                          type="button"
                          onClick={applyFunction}
                          className={`w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white shadow ${
                            isConfirm
                              ? "bg-primary-600 hover:bg-primary-500"
                              : "bg-red-600 hover:bg-red-500"
                          }}`}
                        >
                          {label}
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default AlertModal;
