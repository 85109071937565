import React, { useEffect, useState } from "react";
import PriorityTableCard from "./PriorityTableCard";
import { Loading } from "../svg/EmbeddedServerIcons";
// import { useChannel, useEvent } from "@harelpls/use-pusher";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { embeddedServerService } from "../../../services/embedded-server.service";
import { Button } from "../../../components";
import defaultSensorService from "../../../services/default-sensor.service";
import { useStrictDroppable } from "../../../hooks/useStrictDroppable";

const sensorNames = (e, type) => {
  if (type === "analog") {
    switch (e) {
      case "first":
        return "A1";
      case "second":
        return "A2";
      case "third":
        return "A3";
      case "fourth":
        return "A4";
      case "fifth":
        return "A1-A2-A3";
      default:
        return "Sensor Name";
    }
  } else {
    switch (e) {
      case "first":
        return "D1";
      case "second":
        return "D2";
      case "third":
        return "D3";
      case "fourth":
        return "D4";
      default:
        return "Sensor Name";
    }
  }
};

const PriorityTable = ({ metadataId, draggedSensor, deviceId, setTowerSatus, setPriorityList, getRegisteredSensors, defaultSensorId, loading }) => {
  const [cards, setCards] = useState([]);
  const [rejecteds, setRejecteds] = useState([]);
  const [isColorByCard, setIsColorByCard] = useState(null);
  const [isLoadingByCard, setIsLoadingByCard] = useState(null);
  const [enabled] = useStrictDroppable(loading);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleData = async () => {
    await defaultSensorService.getPriorityTable(defaultSensorId).then((res) => {
      setPriorityList(res.data);
      setCards(res.data);
    });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const items = reorder(cards, result.source.index, result.destination.index);
    const newItems = items?.map((_, i) => {
      return { ..._, order: i };
    });
    setCards(newItems);
    await defaultSensorService.setPriorityTable(defaultSensorId, newItems).then((res) => {
      handleData();
      getRegisteredSensors();
    });
  };

  const handleDrop = async () => {
    await defaultSensorService
      .setPriorityTable(
        defaultSensorId,
        [...cards, draggedSensor].map((_, i) => {
          return { ..._, order: i };
        })
      )
      .then(({ data }) => {
        if (data.code === 2) {
          // Toast("error", data.message);
        }
        handleData();
        getRegisteredSensors();
      });
  };

  useEffect(() => {
    handleData();
  }, []);

  return (
    <div
      onDrop={() => handleDrop()}
      onDragOver={(e) => e.preventDefault()}
      className={`w-full h-full border rounded-xl border-secondary-200 shadow flex flex-col overflow-hidden bg-white ${
        draggedSensor ? "ring-2 ring-blue-500" : ""
      }`}
    >
      <div className="w-full h-[60px] flex items-center justify-between pl-6 pr-4">
        <p className="text-lg font-semibold text-secondary-900">Öncelik Tablosu</p>
        <div className="flex items-center gap-3">
          <Button
            width={67}
            size={"sm"}
            label={"Reset"}
            colorType={"secondary-error"}
            onClick={async () => await defaultSensorService.resetPriorityTable(defaultSensorId).then((res) => handleData())}
          />
        </div>
      </div>
      <div className="h-[34px] w-full flex items-center pr-3 mb-1">
        <div className="h-full w-[8%] flex items-center justify-center">
          <p className="text-xs font-semibold text-secondary-600">No</p>
        </div>
        <div className="h-full w-[15%] flex items-center px-3">
          <p className="text-xs font-semibold text-secondary-600">Sensörler</p>
        </div>
        <div className="h-full w-[21%] flex items-center justify-center">
          <p className="text-xs font-semibold text-secondary-600">Gecikme</p>
        </div>
        <div className="h-full w-[15%] flex items-center justify-center">
          <p className="text-xs font-semibold text-secondary-600">Değer</p>
        </div>
        <div className="h-full w-[33%] flex items-center justify-center">
          <p className="text-xs font-semibold text-secondary-600">Durum</p>
        </div>
        <div className="h-full w-[8%] flex items-center justify-center">
          <p className="text-xs font-semibold text-secondary-600">Kaldır</p>
        </div>
      </div>
      <React.StrictMode>
        <DragDropContext onDragEnd={onDragEnd}>
          {enabled && (
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="w-full h-full flex-1 border-t border-secondary-200 overflow-y-auto scrollbar-hide cursor-default"
                >
                  {cards?.map((item, index) => (
                    <Draggable key={String(item?.id)} draggableId={String(item?.id)} index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                        group w-full max-w-full border-b h-[64px] min-h-[64px] pr-3 flex items-center`}
                        >
                          <div className="w-[8%] h-12 flex items-center justify-center">
                            <div className="group-hover:hidden">
                              {item?.whichSensorKey === isLoadingByCard ? (
                                <Loading size={22} color={"#3E4784"} secondary={"#fff"} />
                              ) : rejecteds.includes(item?.whichSensorKey) ? (
                                <>{isColorByCard == item?.whichSensorKey ? <CheckIcon /> : <CloseIcon />}</>
                              ) : (
                                <p className="text-secondary-600">{index + 1}.</p>
                              )}
                            </div>
                            <LineIcon className={"hidden group-hover:block"} />
                          </div>
                          <div className="flex-1 h-full flex items-center">
                            <PriorityTableCard
                              id={item?.id}
                              deviceId={deviceId}
                              defaultSensorId={defaultSensorId}
                              value={item?.value}
                              refetch={handleData}
                              status={item?.status}
                              metadataId={metadataId}
                              priorityTimeDelay={item?.priorityTimeDelay}
                              isLoading={isLoadingByCard == item?.whichSensorKey}
                              isColor={isColorByCard == item?.whichSensorKey ? "success" : rejecteds.includes(item?.whichSensorKey) ? "error" : null}
                              name={
                                item?.defaultDigitalSensor
                                  ? sensorNames(item?.defaultDigitalSensor?.whichSensor, "digital")
                                  : sensorNames(item?.defaultAnalogSensor?.whichSensor, "analog")
                              }
                            />
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </DragDropContext>
      </React.StrictMode>
      {/* <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="w-full h-full flex-1 border-t border-secondary-200 overflow-y-auto scrollbar-hide cursor-default"
            >
              {cards?.map((item, index) => {
                return (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <div
                          className={`${index % 2 === 0 ? "bg-gray-50" : "bg-white"}
                            group w-full max-w-full border-b h-[64px] min-h-[64px] pr-3 flex items-center  `}
                        >
                          <div className="w-[8%] h-12 flex items-center justify-center">
                            <div className="group-hover:hidden">
                              {item?.whichSensorKey === isLoadingByCard ? (
                                <Loading size={22} color={"#3E4784"} secondary={"#fff"} />
                              ) : rejecteds.includes(item?.whichSensorKey) ? (
                                <>{isColorByCard == item?.whichSensorKey ? <CheckIcon /> : <CloseIcon />}</>
                              ) : (
                                <p className="text-secondary-600">{index + 1}.</p>
                              )}
                            </div>
                            <LineIcon className={"hidden group-hover:block"} />
                          </div>
                          <div className="flex-1 h-full flex items-center">
                            <PriorityTableCard
                              id={item?.id}
                              deviceId={deviceId}
                              defaultSensorId={defaultSensorId}
                              value={item?.value}
                              refetch={handleData}
                              status={item?.status}
                              metadataId={metadataId}
                              priorityTimeDelay={item?.priorityTimeDelay}
                              isLoading={isLoadingByCard == item?.whichSensorKey}
                              isColor={isColorByCard == item?.whichSensorKey ? "success" : rejecteds.includes(item?.whichSensorKey) ? "error" : null}
                              name={
                                item?.defaultDigitalSensor
                                  ? sensorNames(item?.defaultDigitalSensor?.whichSensor, "digital")
                                  : sensorNames(item?.defaultAnalogSensor?.whichSensor, "analog")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext> */}
    </div>
  );
};

export default PriorityTable;

const LineIcon = ({ className }) => {
  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path d="M2.5 7.0835H17.5M2.5 12.9168H17.5" stroke="#98A2B3" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const CloseIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15 9L9 15M9 9L15 15M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="#D92D20"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
        stroke="#079455"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
