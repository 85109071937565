import React, { useEffect } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { useStore } from "../../hooks/useStores";
import { useAlert, positions } from "react-alert";
import { companyService } from "../../services";
import { Input } from "../../components";
const CCountForm = ({
  setOpenModal,
  selectedCCount,
  handleCCountsPaginated,
}) => {
  const { app } = useStore();
  const alert = useAlert();
  const schema = yup.object({
    zone: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    value: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      zone: "",
      value: "",
    },
  });
  useEffect(() => {
    if (selectedCCount) {
      setValue("zone", selectedCCount?.zone);
      setValue("value", selectedCCount?.value);
    } else {
    }
  }, [selectedCCount]);
  const onSubmit = async (data) => {
    app.setLoadingOverlay(true);
    if (selectedCCount && selectedCCount.id) {
      await companyService
        .updateCCount(selectedCCount.id, data)
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Updated.", {
              position: positions.TOP_LEFT,
            });
            handleCCountsPaginated();
            setOpenModal(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    } else {
      await companyService
        .addCCount(data)
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Successfull.", {
              position: positions.TOP_LEFT,
            });
            handleCCountsPaginated();
            setOpenModal(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    }
  };
  const deleteCCount = async () => {
    await companyService
      .deleteCCount(selectedCCount.id)
      .then((res) => {
        if (res.data.status === 1) {
          app.setLoadingOverlay(false);
          alert.success("Deleted.", {
            position: positions.TOP_LEFT,
          });
          handleCCountsPaginated();
          setOpenModal(false);
        } else {
          app.setLoadingOverlay(false);
          alert.success("Failed.", {
            position: positions.TOP_LEFT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        app.setLoadingOverlay(false);
      });
  };
  return (
    <div className="w-full h-full flex flex-col justify-between">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-between w-full h-full gap-6 mt-3 mb-3"
      >
        <div className="w-full flex flex-col justify-between gap-4">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("ccount:zone")}
                width={"100%"}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.zone ? "error" : ""}
                errorMessage={errors.zone ? errors.zone.message : ""}
              />
            )}
            name="zone"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("ccount:value")}
                width={"100%"}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.value ? "error" : ""}
                errorMessage={errors.value ? errors.value.message : ""}
              />
            )}
            name="value"
          />
        </div>
        <div className="w-[390px] absolute bottom-3 mx-auto flex gap-4">
          {selectedCCount?.id && (
            <button
              type="button"
              onClick={() => deleteCCount()}
              className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-red-600 shadow hover:bg-red-500"
            >
              {t("buttons:delete")}
            </button>
          )}
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
          >
            {t("buttons:save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CCountForm;
