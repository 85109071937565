import React from "react";

const TestCard = ({ name, value, values, status }) => {
  return (
    <div
      className={`w-full h-full max-h-[86px] flex flex-col pt-3 px-5 rounded-xl border-[1px]  
    ${
      status === "done"
        ? "bg-[#F9FAFB] border-[#EAECF0]"
        : status === "failed"
        ? "bg-[#FEF3F2] border-[#FDA29B]"
        : "bg-white border-[#EAECF0]"
    }
    `}
    >
      <div className="w-full h-full flex items-start ">
        <span className="w-3 h-3 bg-[#7F56D9] mt-2 rounded-sm "></span>
        <div className="w-full flex flex-col   ml-2">
          <p className="text-[#344054] text-[18px] font-medium mb-1 ">{name}</p>

          {values ? (
            values.map((e) => (
              <p className="text-[#667085] font-normal">{e.value}</p>
            ))
          ) : (
            <p className="text-[#667085] font-normal">{value}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TestCard;
