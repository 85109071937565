import "./App.css";
import "./services/i18n";
import { observer } from "mobx-react-lite";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import { PusherProvider } from "@harelpls/use-pusher";
import { AlertTemplate, PrivateRoute } from "./components";
import routes from "./static/routes";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const App = observer(() => {
  const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
  });
  const options = {
    position: positions.TOP_LEFT,
    timeout: 2500,
    offset: "20px",
    transition: transitions.SCALE,
  };
  const pusherConfig = {
    clientKey: "3fd6b03f01edee2945a0",
    cluster: "eu",
  };
  return (
    <PusherProvider {...pusherConfig}>
      <BrowserRouter>
        <AlertProvider template={AlertTemplate} {...options}>
          <QueryClientProvider client={queryClient}>
            <Routes>
              {routes.map((route, i) => {
                return (
                  <Route
                    key={i}
                    path={route.path}
                    element={
                      route.isPrivate ? (
                        <PrivateRoute>
                          <route.element routes={route?.subRoutes} />
                        </PrivateRoute>
                      ) : (
                        <route.element routes={route?.subRoutes} />
                      )
                    }
                  >
                    {route.subRoutes?.map((subRoute, key) => {
                      return (
                        <Route
                          key={key}
                          visible={subRoute.visible}
                          path={subRoute.path}
                          element={
                            route.isPrivate ? (
                              <PrivateRoute>
                                <subRoute.element routes={subRoute?.subRoutes} />
                              </PrivateRoute>
                            ) : (
                              <subRoute.element routes={subRoute?.subRoutes} />
                            )
                          }
                        ></Route>
                      );
                    })}
                  </Route>
                );
              })}
              <Route path={"*"} element={<Navigate to={"/auth/login"} />} />
            </Routes>
          </QueryClientProvider>
        </AlertProvider>
      </BrowserRouter>
    </PusherProvider>
  );
});

export default App;
