import { instance } from "../libs/client";
/* eslint-disable camelcase */

class SensorService {
  createSensor = async (data) => {
    return instance.post("system-sensor", data);
  };
  updateSensor = async (id, data) => {
    return instance.put(`system-sensor/${id}`, data);
  };
  deleteSensor = async (id) => {
    return instance.delete(`system-sensor/${id}`);
  };
  getSensorsPaginated = async (pageNo, search = "") => {
    return instance.get(
      `system-sensor/paginated?page=${pageNo}${
        search ? `&search=${search}` : ""
      }`
    );
  };
  getSensors = async (pageNo, search = "") => {
    return instance.get("system-sensor/list");
  };
}

const sensorService = new SensorService();

export default sensorService;
