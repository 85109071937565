import { instance } from "../libs/client";
/* eslint-disable camelcase */

class SoftwareService {
  getList = async (pageNo, search = "", type = "") => {
    return instance.get(
      `system-software/paginated?page=${pageNo}${
        search ? `&search=${search}` : ""
      }${type != "all" ? `&type=${type}` : ""}`
    );
  };
  getSoftwares = async () => {
    return instance.get("system-software/list");
  };
  update = async (id, data) => {
    return instance.put(`system-software/${id}`, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };
  delete = async (id) => {
    return instance.delete(`system-software/${id}`);
  };
  upload = async (data) => {
    return instance.post("system-software", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };
  sendUpdate = async (data) => {
    return instance.post(`system-software/send/update`, data);
  };
  updateNext = async (data) => {
    return instance.post(`system-software/update/next`, data);
  };
}

const softwareService = new SoftwareService();

export default softwareService;
