import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../hooks/useStores";
import { userService } from "../../services";
import { useAlert, positions } from "react-alert";
import { StepImage } from "../../assets/images";
import { Input } from "../../components/index";
import { KeyIcon } from "../../assets/icons";
import { DetailBackground, UpuIoLogo } from "./svg";
import useWindowDimensions from "../../hooks/useWindowDimension";
const NewPassword = () => {
  const { width, height } = useWindowDimensions();
  const { auth } = useStore();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const alert = useAlert();
  const onSubmit = async () => {
    await userService
      .changePassword(auth.user.id, {
        passwordRepeat,
        password,
      })
      .then((res) => {
        if (passwordRepeat != password) {
          alert.error("Şifreler uyuşmuyor.", {
            position: positions.TOP_LEFT,
          });
        } else if (password.length < 6 && passwordRepeat.length < 6) {
          alert.error("Şifre en az 6 karakter olmalıdır.", {
            position: positions.TOP_LEFT,
          });
        } else {
          if (res.data.code == 0) {
            alert.success("Başarıyla yeni şifre eklediniz.", {
              position: positions.TOP_LEFT,
            });
            auth?.logOut();
            navigate("/auth/login");
          } else {
            alert.error("Hata!", {
              position: positions.TOP_LEFT,
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div style={{ width, height }} className="flex flex-col">
        <div className="w-full h-[60px] min-h-[60px] border-b flex items-center px-16 justify-between">
          <UpuIoLogo width={100} />
          <select className="w-28 h-11 flex items-center justify-center border-transparent border-0 focus:border-transparent focus:ring-0 font-semibold text-gray-600">
            <option>English</option>
            <option disabled>Turkish</option>
          </select>
        </div>
        <div className="flex-1 flex items-center justify-center relative mb-20">
          <DetailBackground className="absolute" />
          <div className="w-full h-full flex items-center justify-center relative xs:justify-start xs:flex-col">
            <div className="w-[360px] flex flex-col relative z-20 items-center">
              <div className="w-14 h-14 rounded-xl border shadow bg-white flex items-center justify-center">
                <KeyIcon />
              </div>
              <h1 className="text-[30px] font-semibold text-gray-900 mt-4">
                New Password
              </h1>
              <p className="text-gray-600 mt-3">
                Must be at least 6 characters.
              </p>
              <div className="mt-7 w-full">
                <Input
                  inputType={"password"}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder={"New Password"}
                />
              </div>
              <div className="my-5 w-full">
                <Input
                  inputType={"password"}
                  onChange={(e) => setPasswordRepeat(e.target.value)}
                  placeholder={"New Password Repeat"}
                />
              </div>
              <button
                onClick={(e) => {
                  onSubmit();
                }}
                className="w-full h-10 rounded-lg flex items-center justify-center bg-primary-600"
              >
                <p className="font-semibold text-white">Continue</p>
              </button>
            </div>
          </div>
        </div>
        <div className="w-full max-h-[300px] flex items-center justify-center xs:flex sm:hidden -z-10 ">
          <img
            src={StepImage}
            className="object-cover h-[300px] absolute bottom-0  mx-auto -z-10 "
          />
        </div>
      </div>
    </>
  );
};

export default NewPassword;
