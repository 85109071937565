import React from "react";

const Input = ({
  onChange,
  placeholder,
  value,
  onBlur,
  disabled,
  inputType,
  label,
  width,
  name,
  errorMessage = "",
  validate = "",
  textarea = false,
  maxLength,
}) => {
  return (
    <>
      <label
        style={{ width: width }}
        className={`text-gray-700 text-sm font-medium leading-5 flex flex-col relative ${
          textarea ? "" : "h-[70px]"
        }`}
      >
        {label}
        {textarea ? (
          <textarea
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            onBlur={onBlur}
            disabled={disabled}
            type={inputType}
            name={name}
            rows="3"
            className={`py-2.5 px-3.5 bg-white border border-gray-300 shadow-sm rounded-lg text-base text-gray-600 focus:outline-none mt-1.5 ${
              disabled ? "bg-secondary-50 " : <></>
            }`}
          />
        ) : (
          <input
            onChange={onChange}
            placeholder={placeholder}
            value={value}
            onBlur={onBlur}
            disabled={disabled}
            min={maxLength}
            max={maxLength}
            type={inputType}
            name={name}
            className={`h-11 py-2.5 px-3.5 bg-white border border-gray-300 shadow-sm rounded-lg text-base text-gray-600 focus:outline-none mt-1.5 ${
              disabled ? "bg-secondary-50 " : <></>
            }`}
          />
        )}
        {errorMessage && validate && (
          <p className="text-[10px] text-red-500 absolute -bottom-2 right-3 bg-white">
            {errorMessage || validate.toLocaleUpperCase()}
          </p>
        )}
      </label>
    </>
  );
};

export default Input;
