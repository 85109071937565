import React, { useState, useEffect } from "react";
import { LeftArrow } from "../assets/icons";
import { useTranslation } from "react-i18next";
const PaginationComponent = ({ pageNo = 4, setPageNo, pageCount = 12 }) => {
  const { t } = useTranslation();
  return (
    <nav className="flex items-center justify-between border-gray-200 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <a
          onClick={() => {
            if (pageNo != 1) {
              setPageNo(pageNo - 1);
            }
          }}
          className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          <span className="mr-1.5">
            <LeftArrow />
          </span>

          {t("buttons:prev")}
        </a>
      </div>
      {pageCount <= 11 ? (
        <div className="md:-mt-px flex max-w-[800px]">
          <div className="flex">
            {[...Array(pageCount)].map((x, i) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    setPageNo(i + 1);
                  }}
                >
                  <a
                    className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium  ${
                      pageNo == i + 1
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                    }`}
                  >
                    {i + 1}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="md:-mt-px flex max-w-[800px]">
          {pageNo - 3 > 1 && pageNo + 3 < pageCount && (
            <div className="flex">
              <div
                onClick={() => {
                  setPageNo(1);
                }}
              >
                <a
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300
                `}
                >
                  1
                </a>
              </div>
              <div>
                <a
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300
                `}
                >
                  ...
                </a>
              </div>
              {[...Array(pageCount)].map((x, i) => {
                if (
                  i + 1 < pageNo
                    ? Math.abs(pageNo - i) <= 3
                    : Math.abs(pageNo - i) <= 1
                ) {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setPageNo(i + 1);
                      }}
                    >
                      <a
                        className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium  ${
                          pageNo == i + 1
                            ? "border-primary-500 text-primary-600"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                        }`}
                      >
                        {i + 1}
                      </a>
                    </div>
                  );
                }
              })}
              <div>
                <a
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300
                `}
                >
                  ...
                </a>
              </div>
              <div
                key={pageCount}
                onClick={() => {
                  setPageNo(pageCount);
                }}
              >
                <a
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300
                `}
                >
                  {pageCount}
                </a>
              </div>
            </div>
          )}
          {pageNo - 3 <= 1 && (
            <div className="flex">
              {[...Array(pageCount)].map((x, i) => {
                if (i - 1 <= pageNo) {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setPageNo(i + 1);
                      }}
                    >
                      <a
                        className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium  ${
                          pageNo == i + 1
                            ? "border-primary-500 text-primary-600"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                        }`}
                      >
                        {i + 1}
                      </a>
                    </div>
                  );
                }
              })}
              <div>
                <a
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300
                `}
                >
                  ...
                </a>
              </div>
              <div
                key={pageCount}
                onClick={() => {
                  setPageNo(pageCount);
                }}
              >
                <a
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300
                `}
                >
                  {pageCount}
                </a>
              </div>
            </div>
          )}
          {pageNo + 3 >= pageCount && (
            <div className="flex">
              <div
                key={pageCount}
                onClick={() => {
                  setPageNo(1);
                }}
              >
                <a
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300
                `}
                >
                  1
                </a>
              </div>
              <div>
                <a
                  className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300
                `}
                >
                  ...
                </a>
              </div>
              {[...Array(pageCount)].map((x, i) => {
                if (i + 7 > pageCount) {
                  return (
                    <div
                      key={i}
                      onClick={() => {
                        setPageNo(i + 1);
                      }}
                    >
                      <a
                        className={`inline-flex items-center border-t-2 px-4 pt-4 text-sm font-medium  ${
                          pageNo == i + 1
                            ? "border-primary-500 text-primary-600"
                            : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300"
                        }`}
                      >
                        {i + 1}
                      </a>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      )}
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <a
          onClick={() => {
            if (pageNo != pageCount) {
              setPageNo(pageNo + 1);
            }
          }}
          className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
        >
          {t("buttons:next")}
          <span className="rotate-180 ml-1.5">
            <LeftArrow />
          </span>
        </a>
      </div>
    </nav>
  );
};
export default PaginationComponent;
