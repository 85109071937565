import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import Input from "../../components/Inputs/Input";
import i18n from "i18next";
import { useStore } from "../../hooks/useStores";
import { useAlert, positions } from "react-alert";
import roleService from "../../services/role.service";
const RoleForm = ({ setOpenModal, permissions, selectedRole }) => {
  const { app } = useStore();
  const alert = useAlert();
  const [rolesArray, setRolesArray] = useState([]);
  const schema = yup.object({
    tr: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    en: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    key: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    roles: yup
      .array()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tr: "",
      en: "",
      key: "",
      roles: [],
    },
  });
  const roles = useWatch({
    control: control,
    name: "roles",
  });
  useEffect(() => {
    if (selectedRole) {
      setValue("tr", selectedRole.name.tr);
      setValue("en", selectedRole.name.en);
      setValue("key", selectedRole.key);
      setValue("roles", selectedRole.roles);
      setRolesArray(selectedRole.roles);
    } else {
    }
  }, [selectedRole]);
  const onSubmit = (data) => {
    app.setLoadingOverlay(true);
    if (selectedRole && selectedRole._id) {
      roleService
        .updateRole(selectedRole._id, {
          name: {
            tr: data.tr,
            en: data.en,
          },
          roles: data.roles,
          key: data.key,
        })
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Updated.", {
              position: positions.TOP_LEFT,
            });
            setOpenModal(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    } else {
      roleService
        .createRole({
          name: {
            tr: data.tr,
            en: data.en,
          },
          roles: data.roles,
          key: data.key,
        })
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Successfull.", {
              position: positions.TOP_LEFT,
            });
            setOpenModal(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    }
  };
  return (
    <div className=" w-full h-full flex flex-col">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full h-full gap-6 mt-3 mb-3"
      >
        <div className="w-full flex justify-between">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("company:roleName")}
                width={320}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.tr ? "error" : ""}
                errorMessage={errors.tr ? errors.tr.message : ""}
              />
            )}
            name="tr"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("company:roleNames")}
                width={320}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.en ? "error" : ""}
                errorMessage={errors.en ? errors.en.message : ""}
              />
            )}
            name="en"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("company:roleKey")}
                width={320}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.key ? "error" : ""}
                errorMessage={errors.key ? errors.key.message : ""}
              />
            )}
            name="key"
          />
        </div>
        <div className=" w-full flex flex-col gap-1.5">
          <span className="text-gray-700 text-sm font-medium leading-5">
            {t("company:permissionList")}
          </span>
          <div className="max-h-[200px] w-full flex flex-wrap gap-y-2 justify-between h-min border border-gray-300 rounded-lg py-3 px-4 overflow-y-scroll no-scrollbar shadow relative ">
            {permissions?.map((p, i) => {
              return (
                <div
                  key={i}
                  className="flex items-center gap-2 group select-none w-[30%] pl-3"
                >
                  <label className="flex items-center gap-2 text-sm text-gray-500 group-hover:text-gray-800 whitespace-nowrap">
                    <input
                      type="checkbox"
                      className="rounded-md bg-white text-primary-600 w-4 h-4 checked:bg-primary-600 checked:text-primary-600 border-gray-300"
                      checked={
                        (rolesArray.find((e) => e.id === p._id) && true) ||
                        (rolesArray.find(
                          (e) => e.id === "62d98f7300fb466c7ce0cc3c"
                        ) &&
                          true)
                      }
                      onChange={() => {
                        if (rolesArray.find((e) => e.id === p._id)) {
                          setValue(
                            "roles",
                            rolesArray.filter((d) => d.id !== p._id)
                          );
                          setRolesArray(
                            rolesArray.filter((d) => d.id !== p._id)
                          );
                        } else {
                          setValue("roles", [...rolesArray, { id: p._id }]);
                          setRolesArray([...rolesArray, { id: p._id }]);
                        }
                      }}
                    />
                    {p.name[i18n.language]}
                  </label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            onClick={""}
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
          >
            {t("buttons:save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RoleForm;
