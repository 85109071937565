import React, { useEffect, useState } from "react";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { useQuery } from "@tanstack/react-query";
import { t } from "i18next";
import {
  Loading,
  PaginationComponent,
  AdminModal,
  AddNewNod,
  EditNode,
  SoftwareUpdateInfoModal,
} from "../../components";
import NodesPageHeader from "./components/NodesPageHeader";
import { companyService, nodeService } from "../../services";
import { newNodeTableFilter } from "../../static/constants";
import NodeTable from "./components/NodeTable";
import { useSearchParams } from "react-router-dom";
import FilterSortDropdown from "./components/FilterSortDropdown";
import { BsThreeDots } from "react-icons/bs";
import { CLoseIcon } from "../../assets/icons";
import { useTranslation } from "react-i18next";

const NodesPage = ({ location, chartData, tableHeader, isOpen }) => {
  const pieChartPath = "/dashboard/system-node-chart";
  const channel = useChannel("adminNodeList");
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get("page");
  const filter = searchParams.get("filter");
  const sort = searchParams.get("sort");
  const search = searchParams.get("search");
  const order = searchParams.get("order");
  //
  const [badges, setBadges] = useState([]);
  const [selectedNode, setSelectedNode] = useState();
  const [selectedNodes, setSelectedNodes] = useState([]);
  //
  const [selectLanguage, setSelectLanguage] = useState("en");
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  //
  const [updateReportModal, setUpdateReportModal] = useState(false);
  const [updateReportData, setUpdateReportData] = useState([]);
  //
  const [addNodModal, setAddNodModal] = useState(false);
  //
  const [filterDropdown, setFilterDropdown] = useState(false);
  const [filterDropdownData, setFilterDropdownData] = useState({});
  //
  const { data, refetch, isLoading } = useQuery({
    queryKey: ["get-nodes-paginated", page, search, filter, sort, order],
    queryFn: async () =>
      await nodeService?.listNodesPaginated(
        page ? page : 1,
        search,
        filter,
        sort,
        order
      ),
    retry: 0,
  });
  const companies = useQuery({
    queryKey: ["get-companies"],
    queryFn: async () => await companyService?.listCompanies(),
    retry: 0,
  });
  const nodes = useQuery({
    queryKey: ["get-nodes"],
    queryFn: async () => await nodeService?.listNodes(),
    retry: 0,
  });
  const companyNodes = useQuery({
    queryKey: ["get-company-nodes"],
    queryFn: async () => await nodeService.listNodesByCompany(),
    retry: 0,
  });

  const handleBadges = (arr, type) => {
    if (arr?.length > 0) {
      setBadges((p) => [...p?.filter((el) => el?.type != type), ...arr]);
    }
  };
  useEffect(() => {
    setBadges([]);
    if (filter) {
      const spilt = filter?.split(";");
      const mapper = spilt?.map((el, index) => {
        const splitone = el?.split("=");
        switch (splitone[0]) {
          case "company":
            const companyFilter = companies?.data?.data?.filter((item) =>
              splitone[1]?.split(":").includes(item?.id)
            );
            const companyMap = companyFilter?.map((a) => {
              return {
                id: a?.id,
                label: `${a?.name} Firma`,
                type: "company",
              };
            });
            handleBadges(companyMap, "company");
            break;
          case "stmVersion":
            const stmVersionFilter = nodes?.data?.data
              ?.map((n) => {
                return {
                  id: n?.stmVersion,
                  label: `STM Version ${n?.stmVersion}`,
                  type: "stmVersion",
                };
              })
              .filter((v, i, a) => a.findIndex((t) => t?.id === v?.id) === i)
              ?.filter((item) => splitone[1]?.split(":").includes(item?.id));
            handleBadges(stmVersionFilter, "stmVersion");
            break;
          case "espVersion":
            const espVersionFilter = nodes?.data?.data
              ?.map((n) => {
                return {
                  id: n?.espVersion,
                  label: `ESP Version ${n?.espVersion}`,
                  type: "espVersion",
                };
              })
              .filter((v, i, a) => a.findIndex((t) => t?.id === v?.id) === i)
              ?.filter((item) => splitone[1]?.split(":").includes(item?.id));
            handleBadges(espVersionFilter, "espVersion");
            break;
          case "deviceState":
            const deviceStateFilter = [
              {
                label: "NOT_CONNECTED",
                id: "NOT_CONNECTED",
                type: "deviceState",
              },
              { label: "CONNECTED", id: "CONNECTED", type: "deviceState" },
            ]?.filter((item) => splitone[1]?.split(":").includes(item?.id));
            handleBadges(deviceStateFilter, "deviceState");
            break;
          default:
            break;
        }
      });
    }
  }, [filter]);

  const removeBadges = (badgesId, type) => {
    let newFilter;
    setBadges((p) => p?.filter((a) => a?.id != badgesId));
    const filterBadges = badges
      ?.filter((a) => {
        return a?.type == type && a?.id != badgesId;
      })
      ?.map((b) => b?.id);
    let newFilterByType;
    if (filterBadges?.length > 0) {
      newFilterByType = `${type}=${filterBadges
        .filter((item) => item !== "")
        .join(":")}`;
    } else {
      newFilterByType = "";
    }
    if (filter) {
      const spilt = filter?.split(";");
      const filterSplitAndJoin = spilt?.filter((item) => !item.includes(type));
      if (newFilterByType == "") {
        if (filterSplitAndJoin?.length > 0) {
          newFilter = `${filterSplitAndJoin.join(";")}`;
        }
      } else {
        if (filterSplitAndJoin?.length > 0) {
          newFilter = `${filterSplitAndJoin.join(";")};${newFilterByType}`;
        } else {
          newFilter = newFilterByType;
        }
      }
    } else {
      newFilter = newFilterByType;
    }
    const newParams = {
      page: 1,
      search: search ? search : "",
      filter: newFilter,
      sort: sort,
      order: order,
    };
    sort == null && delete newParams.sort;
    search == null && delete newParams.search;
    order == null && delete newParams.order;
    (newFilter == "" || newFilter == null) && delete newParams.filter;
    setFilterDropdown(false);
    setFilterDropdownData(null);
    setSearchParams(newParams);
  };

  useEvent(channel, "update-node-data", (socketEvent) => {
    refetch();
  });

  return (
    <>
      <div className="w-full h-full no-scrollbar xs:hidden overflow-hidden">
        {location === pieChartPath ? null : (
          <NodesPageHeader
            t={t}
            selectedNode={selectedNode}
            setSelectedNode={setSelectedNode}
            selectedNodes={selectedNodes}
            setSelectedNodes={setSelectedNodes}
            selectLanguage={selectLanguage}
            setSelectLanguage={setSelectLanguage}
            visibleLanguage={visibleLanguage}
            setVisibleLanguage={setVisibleLanguage}
            setUpdateReportData={setUpdateReportData}
            setUpdateReportModal={setUpdateReportModal}
            setAddNodModal={setAddNodModal}
            setSearchParams={setSearchParams}
            filter={filter}
            sort={sort}
            search={search}
            order={order}
            refetch={refetch}
            companyNodes={companyNodes?.data?.data}
          />
        )}

        <div className="h-px w-full drop-shadow-md mt-3" />
        {location === pieChartPath ? (
          <>
            <div className=" flex w-full ">
              <div className="w-full flex-row items-center flex ml-2 gap-x-2">
                <span className="bg-primary-50 rounded gap-x-1.5 flex items-center min-h-8 h-8 justify-center whitespace-nowrap font-medium text-primary-600 text-sm px-2 py-0.5">
                  {chartData?.reduce((total, current) => {
                    return (
                      total +
                      (current?.dataArray ? current?.dataArray?.length : 0)
                    );
                  }, 0)}
                  <span className="font-medium text-primary-600 text-sm flex">
                    Total Nodes
                  </span>
                  {filter && t("buttons:filtered")}
                </span>

                {filter && (
                  <div className="flex flex-row w-full overflow-x-auto scrollbar-hide gap-x-2 items-center">
                    {badges?.map((b, i) => {
                      return (
                        <div className="h-[32px] flex items-center flex-row justify-center gap-x-2 px-2 py-1 rounded-lg bg-primary-50 ">
                          <p className="text-primary-600 font-normal text-sm whitespace-nowrap">
                            {b?.label || b?.value}
                          </p>
                          <button
                            onClick={() => {
                              removeBadges(b?.id, b?.type);
                            }}
                            className="w-[12px] h-[12px]"
                          >
                            <CLoseIcon className="w-[10px] h-[10px]" />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="min-w-[135px] h-8 flex justify-end items-center mr-4 ">
                <div
                  type="button"
                  onClick={() => isOpen(false)}
                  className="cursor-pointer bg-primary-50 gap-x-3 rounded flex items-center min-h-8 h-8 justify-center whitespace-nowrap font-medium text-secondary-600 text-sm px-2 py-0.5"
                >
                  <CLoseIcon /> {t("buttons:closeTable")}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="w-full flex-row items-center flex ml-2 gap-x-2">
            <span className="bg-primary-50 rounded flex items-center min-h-8 h-8 justify-center whitespace-nowrap font-medium text-primary-600 text-sm px-2 py-0.5">
              {data?.data?.meta?.itemCount} Total Nodes
              {filter && t("buttons:filtered")}
            </span>

            {filter && (
              <div className="flex flex-row w-full overflow-x-auto scrollbar-hide gap-x-2 items-center">
                {badges?.map((b, i) => {
                  return (
                    <div className="h-[32px] flex items-center flex-row justify-center gap-x-2 px-2 py-1 rounded-lg bg-primary-50 ">
                      <p className="text-primary-600 font-normal text-sm whitespace-nowrap">
                        {b?.label || b?.value}
                      </p>
                      <button
                        onClick={() => {
                          removeBadges(b?.id, b?.type);
                        }}
                        className="w-[12px] h-[12px]"
                      >
                        <CLoseIcon className="w-[10px] h-[10px]" />
                      </button>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {location === pieChartPath ? (
          <div className="h-[82%] w-full mt-4 overflow-y-scroll overflow-x-scroll no-scrollbar border border-gray-300 rounded-lg shadow-md relative">
            <div className="sticky top-0 flex h-11 bg-gray-50 border-b border-gray-200">
              <span
                className={`flex justify-center border-r h-full items-center min-w-[100px] text-xs text-gray-500 cursor-pointer hover:bg-gray-100`}
              />
              {tableHeader?.map((item, index) => {
                return (
                  <div className="flex w-full items-center">
                    <span
                      key={item?.value}
                      className={`flex w-full border-r relative justify-center h-full min-w-[100px] items-center text-xs text-gray-500 cursor-pointer hover:bg-gray-100`}
                    >
                      {t(`company:${item?.value}`)}
                      {item?.canFilter && (
                        <>
                          <div className="absolute right-1 top-0">
                            <div
                              onClick={() => {
                                if (item?.value == "company") {
                                  setFilterDropdownData({
                                    type: item?.value,
                                    name: t(`company:${item.name}`),
                                    items: companies?.data?.data?.map((c) => {
                                      return {
                                        label: c?.name,
                                        value: c?.id,
                                        image: c?.image,
                                      };
                                    }),
                                  });
                                } else if (item?.value == "deviceState") {
                                  setFilterDropdownData({
                                    type: item.value,
                                    name: t(`company:${item.value}`),
                                    items: [
                                      {
                                        label: "NOT_CONNECTED",
                                        value: "NOT_CONNECTED",
                                      },
                                      {
                                        label: "CONNECTED",
                                        value: "CONNECTED",
                                      },
                                    ],
                                  });
                                } else if (item?.value == "espVersion") {
                                  setFilterDropdownData({
                                    type: item?.value,
                                    name: t(`company:${item?.value}`),
                                    items: nodes?.data?.data
                                      ?.map((n) => {
                                        return {
                                          label: n?.espVersion,
                                          value: n?.espVersion,
                                        };
                                      })
                                      .filter(
                                        (v, i, a) =>
                                          a.findIndex(
                                            (t) => t?.label === v?.label
                                          ) === i
                                      ),
                                  });
                                } else if (item?.value == "stmVersion") {
                                  setFilterDropdownData({
                                    type: item?.value,
                                    name: t(`company:${item?.value}`),
                                    items: nodes?.data?.data
                                      ?.map((n) => {
                                        return {
                                          label: n?.stmVersion,
                                          value: n?.stmVersion,
                                        };
                                      })
                                      .filter(
                                        (v, i, a) =>
                                          a?.findIndex(
                                            (t) => t?.label === v?.label
                                          ) === i
                                      ),
                                  });
                                } else {
                                  setFilterDropdownData({
                                    type: item?.value,
                                    name: t(`company:${item?.value}`),
                                  });
                                }
                                setFilterDropdown(!filterDropdown);
                              }}
                              className="w-4 h-4"
                            >
                              <BsThreeDots className="w-4 h-4" />
                            </div>
                            {filterDropdown &&
                              filterDropdownData?.type == item?.value && (
                                <div className={`absolute top-[40px] p-4 pt-0`}>
                                  <FilterSortDropdown
                                    t={t}
                                    filterDropdownData={filterDropdownData}
                                    setFilterDropdownData={
                                      setFilterDropdownData
                                    }
                                    setFilterDropdown={setFilterDropdown}
                                    setSearchParams={setSearchParams}
                                    filter={filter}
                                    search={search}
                                    sort={sort}
                                    order={order}
                                  />
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="w-full h-[90%] flex flex-col">
              {isLoading ? (
                <div className="flex items-center w-full justify-center h-full">
                  <div className="w-12 h-12">
                    <Loading />
                  </div>
                </div>
              ) : (
                chartData?.flatMap((data, index) => {
                  if (!data?.dataArray || !Array.isArray(data?.dataArray)) {
                    return [];
                  }
                  return data?.dataArray?.map((array, arrayIndex) => {
                    return (
                      <NodeTable
                        key={`${index}-${arrayIndex}`}
                        node={array}
                        tableFilter={tableHeader}
                        setSelectedNode={setSelectedNode}
                        onClick={() => setAddNodModal(true)}
                        setAddNodModal={setAddNodModal}
                        selectedNodes={selectedNodes}
                        setSelectedNodes={setSelectedNodes}
                      />
                    );
                  });
                })
              )}
            </div>
          </div>
        ) : (
          <div className="h-[82%] w-full mt-4 overflow-y-scroll overflow-x-scroll no-scrollbar border border-gray-300 rounded-lg shadow-md relative">
            <div className="sticky top-0 flex h-11 bg-gray-50 border-b border-gray-200">
              <span
                className={`flex justify-center border-r h-full items-center min-w-[100px] text-xs text-gray-500 cursor-pointer hover:bg-gray-100`}
              />
              {newNodeTableFilter.map((item, index) => {
                return (
                  <div className="flex w-full items-center">
                    <span
                      key={item.value}
                      className={`flex w-full border-r relative justify-center h-full min-w-[100px] items-center text-xs text-gray-500 cursor-pointer hover:bg-gray-100`}
                    >
                      {t(`company:${item.value}`)}
                      {item?.canFilter && (
                        <>
                          <div className="absolute right-1 top-0">
                            <div
                              onClick={() => {
                                if (item.value == "company") {
                                  setFilterDropdownData({
                                    type: item.value,
                                    name: t(`company:${item.value}`),
                                    items: companies?.data?.data?.map((c) => {
                                      return {
                                        label: c.name,
                                        value: c.id,
                                        image: c.image,
                                      };
                                    }),
                                  });
                                } else if (item.value == "deviceState") {
                                  setFilterDropdownData({
                                    type: item.value,
                                    name: t(`company:${item.value}`),
                                    items: [
                                      {
                                        label: "NOT_CONNECTED",
                                        value: "NOT_CONNECTED",
                                      },
                                      {
                                        label: "CONNECTED",
                                        value: "CONNECTED",
                                      },
                                    ],
                                  });
                                } else if (item.value == "espVersion") {
                                  setFilterDropdownData({
                                    type: item.value,
                                    name: t(`company:${item.value}`),
                                    items: nodes?.data?.data
                                      ?.map((n) => {
                                        return {
                                          label: n.espVersion,
                                          value: n.espVersion,
                                        };
                                      })
                                      .filter(
                                        (v, i, a) =>
                                          a.findIndex(
                                            (t) => t.label === v.label
                                          ) === i
                                      ),
                                  });
                                } else if (item.value == "stmVersion") {
                                  setFilterDropdownData({
                                    type: item.value,
                                    name: t(`company:${item.value}`),
                                    items: nodes?.data?.data
                                      ?.map((n) => {
                                        return {
                                          label: n.stmVersion,
                                          value: n.stmVersion,
                                        };
                                      })
                                      .filter(
                                        (v, i, a) =>
                                          a.findIndex(
                                            (t) => t.label === v.label
                                          ) === i
                                      ),
                                  });
                                } else {
                                  setFilterDropdownData({
                                    type: item.value,
                                    name: t(`company:${item.value}`),
                                  });
                                }
                                setFilterDropdown(!filterDropdown);
                              }}
                              className="w-4 h-4"
                            >
                              <BsThreeDots className="w-4 h-4" />
                            </div>
                            {filterDropdown &&
                              filterDropdownData.type == item.value && (
                                <div className={`absolute top-[40px] p-4 pt-0`}>
                                  <FilterSortDropdown
                                    t={t}
                                    filterDropdownData={filterDropdownData}
                                    setFilterDropdownData={
                                      setFilterDropdownData
                                    }
                                    setFilterDropdown={setFilterDropdown}
                                    setSearchParams={setSearchParams}
                                    filter={filter}
                                    search={search}
                                    sort={sort}
                                    order={order}
                                  />
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="w-full h-[90%] flex flex-col">
              {isLoading ? (
                <div className="flex items-center w-full justify-center h-full">
                  <div className="w-12 h-12">
                    <Loading />
                  </div>
                </div>
              ) : (
                data?.data?.data?.map((node) => {
                  return (
                    <NodeTable
                      node={node}
                      tableFilter={newNodeTableFilter}
                      setSelectedNode={setSelectedNode}
                      onClick={() => setAddNodModal(true)}
                      setAddNodModal={setAddNodModal}
                      selectedNodes={selectedNodes}
                      setSelectedNodes={setSelectedNodes}
                    />
                  );
                })
              )}
            </div>
          </div>
        )}

        {location === pieChartPath ? null : (
          <PaginationComponent
            pageNo={data?.data?.meta?.page}
            setPageNo={(page) => {
              const newParams = {
                page: page,
                search: search,
                filter: filter,
                sort: sort,
                order: order,
              };
              filter == null && delete newParams.filter;
              sort == null && delete newParams.sort;
              search == null && delete newParams.search;
              order == null && delete newParams.order;
              setSearchParams(newParams);
            }}
            pageCount={data?.data?.meta?.pageCount}
          />
        )}
      </div>
      <AdminModal
        modalIsOpen={addNodModal}
        closeModal={() => setAddNodModal(false)}
        height={"650px"}
        title={!selectedNode ? t("buttons:addNewNode") : t("form:editNode")}
        children={
          <AddNewNod
            setIsOpen={setAddNodModal}
            companies={companies?.data?.data}
            setSelectedNode={setSelectedNode}
            selectedNode={selectedNode}
            handleNodes={refetch}
            companyNodes={companyNodes?.data?.data}
            children={<EditNode />}
          />
        }
      />
      <AdminModal
        modalIsOpen={updateReportModal}
        closeModal={() => {
          setUpdateReportModal(false);
        }}
        height={"780px"}
        width={"1000px"}
        title={t("software:updateReport")}
        children={
          <SoftwareUpdateInfoModal
            updateReportData={updateReportData}
            setIsOpen={setUpdateReportModal}
            setUpdateReportData={setUpdateReportData}
          />
        }
      />
    </>
  );
};

export default NodesPage;
