import React, { useState, useEffect } from "react";
import { companyService } from "../../services";
import { t } from "i18next";
import i18next from "i18next";
import { PlusIcon, DownIcon, UpIcon } from "../../assets/icons";
import { industryTableFilter } from "../../static/constants";
import {
  PaginationComponent,
  Loading,
  SearchInput,
  AddNewIndustry,
  NewAddButton,
  AdminModal,
  ChangeLanguage,
} from "../../components";
const IndustriesList = () => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [searchVal, setSearchVal] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState(i18next.language);
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  useEffect(() => {
    setSelectLanguage(i18next.language);
  }, [i18next.language]);
  const handleIndustriesPaginated = async () => {
    setLoading(true);
    await companyService
      .listIndustryPaginated(pageNo, searchVal)
      .then((res) => {
        setTotalCount(res.data.meta.itemCount);
        setPageCount(res.data.meta.pageCount);
        setPageData(res.data.data);
        if ((Number(pageNo) + 1) >> res.data.meta.pageCount) {
          setPageNo(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  useEffect(() => {
    handleIndustriesPaginated();
  }, [pageNo, searchVal]);
  return (
    <>
      <div className="w-full h-full overflow-y-scroll no-scrollbar xs:hidden">
        <div className="w-full flex flex-row items-center justify-between">
          <span className="text-secondary-900 text-[28px] font-medium ml-10 select-none">
            {t("auth:industries")}
          </span>
          <div className="flex items-center gap-4">
            <NewAddButton
              onClick={() => {
                setSelectedIndustry(null);
                setOpenModal(true);
              }}
              label={t("buttons:addNewIndustry")}
              width={180}
              icon={<PlusIcon className="w-4 h-4 text-gray-400" />}
            />
            <SearchInput
              setSearchVal={setSearchVal}
              width={320}
              placeholder={t("auth:search")}
            />
            <ChangeLanguage
              setVisibleLanguage={setVisibleLanguage}
              setSelectedLanguage={setSelectLanguage}
              selectedLanguage={selectLanguage}
              visibleLanguage={visibleLanguage}
            />
          </div>
        </div>
        <div className="h-px w-full bg-secondary-200 drop-shadow-md mt-3" />
        <div className="h-[85%] w-full mt-6 overflow-y-scroll no-scrollbar border border-gray-300 rounded-lg shadow-md relative overflow-x-scroll">
          <div className="w-full justify-around sticky top-0 flex h-11 bg-gray-50 border-b border-gray-200 ">
            {industryTableFilter.map((item, i) => (
              <span
                key={item.value}
                className={`flex justify-center gap-3 h-full items-center min-w-[240px] text-xs text-gray-500 cursor-pointer hover:bg-gray-100 ${
                  item.checked || "hidden"
                } `}
              >
                {item.checked ? t(`company:${item.label}`) : ""}
                {item.value == sortBy ? (
                  <UpIcon className="w-3 h-3" />
                ) : (
                  <DownIcon className="w-3 h-3" />
                )}
              </span>
            ))}
          </div>
          <div className="w-full h-[90%] flex flex-col">
            {loading ? (
              <div className="flex items-center w-full justify-center h-full">
                <div className="w-12 h-12">
                  <Loading />
                </div>
              </div>
            ) : (
              pageData.map((industry, i) => (
                <ul
                  className="w-full min-h-[65px] flex items-center border-b border-x border-gray-200 group"
                  key={i}
                >
                  {industryTableFilter.map((d, i) => {
                    if (d.value === "name.en") {
                      return (
                        <li
                          key={i}
                          onClick={() => {
                            setSelectedIndustry(industry);
                            setOpenModal(true);
                          }}
                          className="h-full w-full select-none min-w-[240px] flex items-center justify-evenly text-sm text-secondary-600 group-hover:bg-slate-50"
                        >
                          {industry.name.en && industry.name.en}
                        </li>
                      );
                    }
                    if (d.value === "name.tr") {
                      return (
                        <li
                          onClick={() => {
                            setSelectedIndustry(industry);
                            setOpenModal(true);
                          }}
                          className="h-full w-full select-none min-w-[240px] flex items-center justify-evenly text-sm text-secondary-600 group-hover:bg-slate-50"
                        >
                          {industry.name.tr && industry.name.tr}
                        </li>
                      );
                    }
                    if (d.value === "company") {
                      return (
                        <li
                          onClick={() => {
                            setSelectedIndustry(industry);
                            setOpenModal(true);
                          }}
                          className="h-full w-full select-none min-w-[240px] flex items-center justify-evenly text-sm text-secondary-600 group-hover:bg-slate-50"
                        >
                          {industry.company && industry.company.length}
                        </li>
                      );
                    }
                  })}
                </ul>
              ))
            )}
          </div>
        </div>
        <PaginationComponent
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageCount={pageCount}
        />
      </div>
      <AdminModal
        modalIsOpen={openModal}
        closeModal={() => setOpenModal(false)}
        height={"520px"}
        width={"1100px"}
        isIndustry={true}
        canScroll={false}
        children={
          <AddNewIndustry
            setIsOpen={setOpenModal}
            handleIndustries={handleIndustriesPaginated}
            selectedIndustry={selectedIndustry}
          />
        }
        title={t("buttons:addNewIndustry")}
      />
    </>
  );
};

export default IndustriesList;
