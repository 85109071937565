import React, { useEffect, useState } from "react";
import { Button } from "../../../components";
import defaultSensorService from "../../../services/default-sensor.service";
import { TrashIcons } from "../../../assets/icons";

const DigitalSensorCard = ({ isActive, name, whichSensor, savedData, setDraggedSensor, sensorId, refetch, priorityList, defaultSensorId }) => {
  const [value, setValue] = useState(0);
  const [isDrag, setIsDrag] = useState(false);
  const [isConsumption, setIsConsumption] = useState(false);

  const sensorData = { sensorId, value, isConsumption, whichSensor, defaultSensorId, type: "digital" };
  const saveSensor = async (consumption) => {
    await defaultSensorService.saveDigital({ sensorId, isConsumption: consumption, whichSensor, defaultSensorId, type: "digital" }).then(() => refetch());
  };

  const findByPriorityCard = priorityList?.filter((a) => a?.digitalSensor?.id === sensorId);
  const disableValueButton = findByPriorityCard?.map((e) => e?.value);
  const valueButtons = [{ value: 0 }, { value: 1 }];

  useEffect(() => {
    if (savedData?.isConsumption || savedData?.value) {
      setValue(savedData?.value);
      setIsConsumption(savedData?.isConsumption);
    }
  }, [savedData]);

  return (
    <div
      onDragStart={(e) => {
        setIsDrag(true);
        setDraggedSensor(sensorData);
      }}
      onDragEnd={(e) => {
        setDraggedSensor(null);
        setIsDrag(false);
      }}
      draggable
      style={{ opacity: isDrag ? 0 : 1 }}
      className={`w-full h-[48px] border border-secondary-200 transition-all rounded-xl flex items-center ${
        isActive ? " hover:border-gray-300 active:shadow-none bg-white shadow cursor-grab" : "bg-gray-50"
      }`}
    >
      <div className="h-full w-1/2 flex items-center pl-3">
        <div style={{ backgroundColor: isActive ? "#17B26A" : "#F04438" }} className={`w-3 h-3 rounded`} />
        <p className="font-semibold text-secondary-700 ml-2">{name || "Sensor Name"}</p>
      </div>
      <div className="flex items-center justify-center w-[20%] h-full">
        <div className="flex gap-2">
          {valueButtons.map((item, index) => {
            const isDisabled = disableValueButton?.includes(item.value);
            return (
              <Button
                width={38}
                theme={"light"}
                size={"sm"}
                key={index}
                label={String(item.value)}
                disabled={!isActive || isDisabled}
                colorType={value === item.value ? "" : "secondary"}
                onClick={() => {
                  setValue(item.value);
                }}
              />
            );
          })}
        </div>
      </div>
      <div
        onClick={() => {
          if (isConsumption) {
            setIsConsumption(false);
            saveSensor(false);
          } else {
            setIsConsumption(true);
            saveSensor(true);
          }
        }}
        className="w-[20%] flex items-center justify-center h-full hover:bg-gray-50"
      >
        <div className={`w-5 h-5 rounded-md flex items-center justify-center ${isConsumption ? "bg-[#3E4784]" : "border border-gray-300"}`}>
          {isConsumption && <CheckIcon />}
        </div>
      </div>
      {sensorId && (
        <div
          onClick={async () => {
            await defaultSensorService.resetDigital(defaultSensorId, sensorId).then((res) => refetch());
          }}
          className="flex-1 h-full flex items-center justify-center hover:bg-red-50"
        >
          <TrashIcons />
        </div>
      )}
    </div>
  );
};

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path d="M12.1667 3.5L5.75004 9.91667L2.83337 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export default DigitalSensorCard;
