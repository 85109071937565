import React, { useState } from "react";
import generateFileUrl from "../utils/generateFileUrl";
import i18n from "../services/i18n";
const UserTable = ({
  companyOpen,
  company,
  setSelectedCompany,
  tableFilter,
}) => {
  return (
    <>
      <div className="w-[100%] flex flex-col select-none overflow-x-hidden overflow-y-scroll min-h-[71px] ">
        <ul className="flex justify-between border-b border-gray-200 items-center h-[72px] bg-white">
          <div
            className="flex h-full cursor-pointer"
            onClick={() => setSelectedCompany(company)}
          >
            <li
              onClick={companyOpen}
              className="w-[200px] min-w-[200px] hover:bg-slate-50 flex items-center justify-center h-full"
            >
              {company?.image ? (
                <img
                  className="h-11 w-11 min-h-8 min-w-8 rounded-full border-2 border-gray-300 shadow"
                  src={generateFileUrl(company.image)}
                  alt=""
                />
              ) : (
                <div
                  className={
                    "w-11 h-11 rounded-full bg-primary-600 flex items-center justify-center text-white text-xl leading-none font-medium border-4 border-stone-700 shadow"
                  }
                >
                  {company.name?.charAt(0).toUpperCase()}
                </div>
              )}
            </li>
          </div>
          {tableFilter.map((d, i) => {
            if (d.checked)
              return (
                <>
                  <li
                    key={i}
                    onClick={(key) => {
                      setSelectedCompany(company);
                      companyOpen();
                    }}
                    className="w-[200px] min-w-[200px] text-center h-full hover:bg-slate-50 flex items-center justify-center"
                  >
                    <span className="text-sm text-gray-500">
                      {d.value == "country"
                        ? company?.country.label
                          ? company?.country.label
                          : company?.country
                        : d.value == "industry"
                        ? company?.industry?.name[i18n.language]
                          ? company?.industry?.name[i18n.language]
                          : "---"
                        : company[d.value]}
                    </span>
                  </li>
                </>
              );
          })}
        </ul>
      </div>
    </>
  );
};

export default UserTable;
