import { instance } from "../libs/client";
/* eslint-disable camelcase */

class DefaultSensorService {
  async getDefaultSensor() {
    return await instance.get("default-sensor");
  }
  async addDefaultSensor(data) {
    return await instance.post("default-sensor", data);
  }
  async updateDefaultSensor(id, data) {
    return await instance.put(`default-sensor/${id}`, data);
  }
  async findOneDefaultSensor(id) {
    return await instance.get(`default-sensor/${id}`);
  }
  async deleteDefaultSensor(id) {
    return await instance.delete(`default-sensor/${id}`);
  }
  async saveAnalog(data) {
    return await instance.post(`default-sensor/analog`, data);
  }
  async saveDigital(data) {
    return await instance.post(`default-sensor/digital`, data);
  }
  async resetAnalog(defaultSensorId, id) {
    return await instance.delete(`default-sensor/analog/${defaultSensorId}/${id}`);
  }
  async resetDigital(defaultSensorId, id) {
    return await instance.delete(`default-sensor/digital/${defaultSensorId}/${id}`);
  }
  async setPriorityTable(sensorId, data) {
    return await instance.post(`default-sensor/set-priority/${sensorId}`, data);
  }
  async getPriorityTable(sensorId) {
    return await instance.get(`default-sensor/get-priority/${sensorId}`);
  }
  async updatePriorityCard(id, data) {
    return await instance.put(`default-sensor/update-priority-card/${id}`, data);
  }
  async deletePriorityTableCard(defaultSensorId, id) {
    return await instance.delete(`default-sensor/delete-priority-card/${defaultSensorId}/${id}`);
  }
  async resetPriorityTable(defaultSensorId) {
    return await instance.delete(`default-sensor/reset-priority/${defaultSensorId}`);
  }
}

const defaultSensorService = new DefaultSensorService();

export default defaultSensorService;
