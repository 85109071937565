import { instance } from "../libs/client";

/* eslint-disable camelcase */

class NodeService {
  registerNode = async (data) => {
    return instance.post("nodes/register", data);
  };
  listNodes = async () => {
    return instance.get("nodes/");
  };
  listNodesByCompany = async (search = "") => {
    return instance.get(
      `nodes/company-nodes${search ? `?search=${search}` : ""}`
    );
  };
  listNodesPaginated = async (
    pageNo = 1,
    search = "",
    filter = "",
    sort = "",
    order = "ASC"
  ) => {
    return instance.get(
      `nodes/paginated?page=${pageNo}${search ? `&search=${search}` : ""}${
        filter != null ? `&filter=${filter}` : ""
      }${sort != null ? `&sort=${sort}` : ""}${
        order != null ? `&order=${order}` : ""
      }`
    );
  };
  updateNode = async (id, data) => {
    return instance.put(`nodes/${id}`, data);
  };
  deleteNode = async (id) => {
    return instance.delete(`nodes/deleted/${id}`);
  };
  findByCompany = async (id) => {
    return instance.get(`nodes/company/${id}`);
  };
  createMaintenance = async (id, data) => {
    return instance.post(`nodes/maintenance/${id}`, data);
  };
  updateMaintenance = async (id, data) => {
    return instance.put(`nodes/maintenance/${id}`, data);
  };
  findOneMaintenance = async (id) => {
    return instance.get(`nodes/${id}/maintenance`);
  };
  deleteMaintenance = async (id) => {
    return instance.delete(`nodes/maintenance/${id}`);
  };
  reseteDevice = async (id) => {
    return instance.post(`nodes/device/reset/${id}`);
  };
  updateNodeId = async (id, data) => {
    return instance.put(`nodes/changeId/${id}`, data);
  };
}

const nodeService = new NodeService();

export default nodeService;
