import React from "react";
import Input from "../../Inputs/Input";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
const GeneralTab = ({ control, errors }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full flex gap-6">
      <div className="flex flex-col w-2/4 gap-6">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t("company:industryName")}
              value={value}
              disabled={false}
              onBlur={onBlur}
              onChange={onChange}
              validate={errors.tr ? "error" : ""}
              errorMessage={errors.tr ? errors.tr.message : ""}
            />
          )}
          name="tr"
        />
      </div>
      <div className="flex flex-col w-2/4 gap-6">
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t("company:industryNames")}
              value={value}
              disabled={false}
              onBlur={onBlur}
              onChange={onChange}
              validate={errors.en ? "error" : ""}
              errorMessage={errors.en ? errors.en.message : ""}
            />
          )}
          name="en"
        />
      </div>
    </div>
  );
};

export default GeneralTab;
