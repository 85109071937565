import React from "react";
import Select from "react-select";

const SelectBox = ({
  labelBg,
  options,
  placeholder,
  isMulti,
  styles,
  textColor,
  validate,
  onChange,
  value,
  width,
  defaultValue,
  ...rest
}) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      padding: 8,
      paddingLeft: 10,
      backgroundColor: state.isSelected ? "#8E43E7" : "#fff",
      "&:hover": {
        backgroundColor: state.isSelected ? "none" : "#DEC7F8",
      },
      "&:focus": {
        backgroundColor: state.isFocused ? " #DEC7F8" : "red",
      },
    }),
    input: (rest) => ({
      ...rest,
      outline: "none !important",
      border: "0 !important",
      boxShadow: "none !important",
      "&:focus": {
        outlineOffset: 0,
      },
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      border: "1px solid rgb(204, 204, 204)",
      height: 44,
      display: "flex",
      flexDirection: "row",
      borderRadius: 8,
      paddingLeft: 6,
    }),
    menu: (provided, state) => ({
      ...provided,
      borderRadius: 8,
    }),
    multiValue: (provided, state) => {
      return { ...provided, backgroundColor: "white", padding: 0 };
    },
    multiValueRemove: (provided, state) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition, color: textColor };
    },
  };
  return (
    <label
      style={{ width: width }}
      className="text-gray-700 text-sm font-medium leading-5 flex flex-col gap-1.5 w"
    >
      {placeholder}
      <Select
        defaultValue={"defaultValue"}
        isMulti={isMulti}
        options={options}
        placeholder={""}
        styles={{ ...customStyles }}
        onChange={onChange}
        value={value}
        className="dark:bg-darkTheme-mainColor dark:text-darkTheme"
        {...rest}
      />
    </label>
  );
};

export default SelectBox;
