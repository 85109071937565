import React, { useState, useEffect } from "react";
import { useAlert, positions } from "react-alert";
import { t } from "i18next";
import i18next from "i18next";
import { softwareService } from "../../services";
import SoftwareForm from "./SoftwareForm";
import { softwaresTableFilter } from "../../static/constants";
import { BsTrash } from "react-icons/bs";
import { PlusIcon, DownIcon, Update } from "../../assets/icons";
import {
  SearchInput,
  PaginationComponent,
  Loading,
  ChangeLanguage,
  NewAddButton,
  AdminModal,
  AlertModal,
} from "../../components";
const Softwares = () => {
  const [selectedLanguage, setSelectedLanguage] = useState(i18next.language);
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [approval, setApproval] = useState(false);
  const [type, setType] = useState("");
  const [isAlert, setIsAlert] = useState(false);
  const [selectedSoftware, setSelectedSoftware] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([]);
  const alert = useAlert();
  useEffect(() => {
    setSelectedLanguage(i18next.language);
  }, [i18next.language]);
  const handleSoftwaresPaginated = async () => {
    setLoading(true);
    await softwareService
      .getList(pageNo, searchVal)
      .then((res) => {
        setPageCount(res.data.meta.pageCount);
        setPageData(res.data.data);
        if ((Number(pageNo) + 1) >> res.data.meta.pageCount) {
          setPageNo(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  useEffect(() => {
    handleSoftwaresPaginated();
  }, [searchVal, pageNo]);
  const approveSoftware = () => {
    var formData = new FormData();
    formData.append(type, approval);
    softwareService
      .update(selectedSoftware.id, formData)
      .then((res) => {
        if (res.data.code == 0) {
          alert.success(t("software:softwareApproved"), {
            position: positions.TOP_LEFT,
          });
          handleSoftwaresPaginated();
        } else {
          alert.error(t("software:softwareApproveFailed"), {
            position: positions.TOP_LEFT,
          });
        }
        setConfirmModal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const deleteSoftware = () => {
    softwareService
      .delete(selectedSoftware?.id)
      .then((res) => {
        if (res.data.status == 0) {
          alert.success(t("software:softwareDeleted"), {
            position: positions.TOP_LEFT,
          });
          handleSoftwaresPaginated();
        } else {
          alert.error(t("software:softwareDeleteFailed"), {
            position: positions.TOP_LEFT,
          });
        }
        setIsAlert(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div className="w-full h-full overflow-y-scroll no-scrollbar xs:hidden">
        <div className="w-full flex flex-row items-center justify-between">
          <span className="text-secondary-900 text-[28px] font-medium ml-10 select-none">
            {t("software:softwares")}
          </span>
          <div className="flex items-center gap-4">
            <NewAddButton
              onClick={() => {
                setSelectedSoftware(null);
                setIsOpen(true);
              }}
              label={t("software:addNewSoftware")}
              width={166}
              icon={<PlusIcon className="w-4 h-4 text-gray-400" />}
            />
            <SearchInput
              setSearchVal={setSearchVal}
              width={320}
              placeholder={t("auth:search")}
            />
            <ChangeLanguage
              setSelectedLanguage={setSelectedLanguage}
              selectedLanguage={selectedLanguage}
              setVisibleLanguage={setVisibleLanguage}
              visibleLanguage={visibleLanguage}
            />
          </div>
        </div>
        <div className="h-px w-full bg-secondary-200 drop-shadow-md mt-3" />
        <div className="h-[85%] w-full mt-6 overflow-hidden no-scrollbar border border-gray-300 rounded-lg shadow-md relative overflow-x-scroll">
          <div className="w-full justify-between flex h-11 bg-gray-50 border-b border-x border-gray-300">
            {softwaresTableFilter.map((item, i) => (
              <li
                key={item.value}
                className={`h-full w-full border-r border-gray-300 flex items-center gap-x-2 justify-center text-xs text-gray-500 cursor-pointer hover:bg-gray-100`}
              >
                {t(`software:${item.label}`)}
                <DownIcon className="text-secondary-500 w-2 h-2" />
              </li>
            ))}
            <li
              className={`h-full min-w-[80px] flex items-center justify-center text-gray-500 cursor-pointer hover:bg-gray-100`}
            />
          </div>
          {loading ? (
            <div className="flex items-center w-full justify-center h-full">
              <div className="w-12 h-12">
                <Loading />
              </div>
            </div>
          ) : (
            pageData.map((role, i) => (
              <ul
                className="w-full min-h-[72px] flex items-center border-b border-x border-gray-200 group"
                key={i}
              >
                {softwaresTableFilter.map((d, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        if (
                          !(
                            d.value === "softwareArchitectureApproval" ||
                            d.value === "testEngineerApproval" ||
                            d.value === "fieldTestApproval"
                          )
                        ) {
                          setSelectedSoftware(role);
                          setIsOpen(true);
                        } else {
                          setType(d.value);
                          setApproval(role[d.value] ? "no" : "yes");
                          setSelectedSoftware(role);
                          setConfirmModal(true);
                        }
                      }}
                      className="cursor-pointer h-full text-center min-h-[72px] w-full border-r border-gray-300 select-none flex items-center justify-center text-sm text-secondary-600 group-hover:bg-slate-50"
                    >
                      {!(
                        d.value === "softwareArchitectureApproval" ||
                        d.value === "testEngineerApproval" ||
                        d.value === "fieldTestApproval"
                      ) ? (
                        role[d.value] && role[d.value]
                      ) : (
                        <div className="flex flex-col-reverse items-center justify-center gap-y-2">
                          {role[d.value] && (
                            <div className="text-sm text-secondary-600">
                              {role[d.value].name +
                                " " +
                                role[d.value].lastName}
                            </div>
                          )}
                          <div className="flex items-center gap-x-1.5">
                            <input
                              checked={role[d.value] ? true : false}
                              type="checkbox"
                              className="w-4 h-4 rounded-md border text-primary-600 bg-primary-100 border-primary-50 accent-primary-500"
                            />
                          </div>
                        </div>
                      )}
                    </li>
                  );
                })}
                <li
                  onClick={() => {
                    setSelectedSoftware(role);
                    setIsAlert(true);
                  }}
                  className="cursor-pointer h-full min-h-[72px] min-w-[80px] select-none flex items-center justify-center text-secondary-600 group-hover:bg-slate-50 text-xl"
                >
                  <BsTrash width={24} height={24} />
                </li>
              </ul>
            ))
          )}
        </div>
        <PaginationComponent
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageCount={pageCount}
        />
      </div>
      <AdminModal
        height={520}
        width={470}
        modalIsOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        title={
          selectedSoftware
            ? t("software:editSoftware")
            : t("software:addNewSoftware")
        }
        children={
          <SoftwareForm
            setOpenModal={setIsOpen}
            selectedSoftware={selectedSoftware}
            handleSoftwaresPaginated={handleSoftwaresPaginated}
          />
        }
      />
      <AlertModal
        isAlert={confirmModal}
        icon={<Update className="w-4 h-4 text-primary-600" />}
        setIsAlert={setConfirmModal}
        text={t("software:confirmApprovalHeader")}
        header={t("software:confirmApproval")}
        label={t("software:confirm")}
        applyFunction={() => approveSoftware()}
        isConfirm={true}
      />
      <AlertModal
        isAlert={isAlert}
        icon={<BsTrash className="w-4 h-4 text-red-600" />}
        setIsAlert={setIsAlert}
        text={t("software:deleteSoftwareHeader")}
        header={t("software:deleteSoftware")}
        label={t("software:delete")}
        applyFunction={() => deleteSoftware()}
      />
    </>
  );
};

export default Softwares;
