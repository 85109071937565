import { instance } from "../libs/client";
/* eslint-disable camelcase */

class UserService {
  getUsersPaginated = async (pageNo, search = "") => {
    return instance.get(
      `user/admin?page=${pageNo}${search ? `&search=${search}` : ""}`
    );
  };
  createUser = async (data) => {
    return instance.post("user/admin", data);
  };
  updateUser = async (id, data) => {
    return instance.put(`user/admin/${id}`, data);
  };
  deleteUser = async (id) => {
    return instance.delete(`user/admin/${id}`);
  };
  async removePersonal(id, data) {
    return instance.post(`user/remove/${id}`, data);
  }
  async changePassword(id, data) {
    return instance.put(`user/change-password/${id}`, data);
  }
}

const userService = new UserService();

export default userService;
