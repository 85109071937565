import { CheckIcon, XIcon } from "@heroicons/react/outline";
import React, { useState, useEffect } from "react";
import TestCard from "./components/TestCard";
import { useChannel, useEvent } from "@harelpls/use-pusher";
import { Loading } from "../../components";
import * as _ from "lodash";
import isCheckedControl from "../../utils/isCheckedControl";
import { testingService } from "../../services";
const TestStep = ({
  selectedTestType,
  selectedRecord,
  setTestStarted,
  setSelectedRecord,
  setSelectedNode,
  setSelectedTestType,
  handleTestRecordsPaginated,
}) => {
  const [nodeId, setNodeId] = useState("");
  const [calibratorId, setCalibratorId] = useState("");
  const [statusSnap, setStatusSnap] = useState({});
  const [steps, setSteps] = useState(
    selectedTestType?.info?.map((step) => {
      return { name: step?.type, status: "upcoming", order: step?.order };
    })
  );
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(true);
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  useEffect(() => {
    if (selectedRecord?.id) {
      setStatusSnap(
        selectedRecord?.info?.node?.find((a) => a?.type == "Status")
      );
      const nodeValues = selectedRecord?.info?.node.reduce(
        (obj, cur) => ({ ...obj, [cur.type]: cur }),
        {}
      );
      const calibValues = selectedRecord?.info?.calibrator.reduce(
        (obj, cur) => ({ ...obj, [cur.type]: cur }),
        {}
      );
      const mapper = selectedRecord?.type?.info?.map((i) => {
        return {
          type: i.type,
          node: nodeValues[i.type],
          calibrator: calibValues[i.type],
        };
      });
      const newValues = mapper.reduce(
        (obj, cur) => ({
          ...obj,
          [cur.type]: { node: cur.node, calibrator: cur.calibrator },
        }),
        {}
      );
      setValues(newValues);
      const newSteps = selectedRecord?.type?.info?.map((step) => {
        return {
          name: step?.type,
          status: "complete",
          isChecked: isCheckedControl(step.type, newValues),
          order: step?.order,
        };
      });
      setSteps(newSteps);
      setLoading(false);
    }
  }, []);
  const channel = useChannel("tower-test");
  useEvent(channel, "started", (socketEvent) => {
    console.log("sockettt", socketEvent);
    setNodeId(socketEvent?.nodeSnap?.nodeId);
    setCalibratorId(socketEvent?.calibratorSnap?.nodeId);
    if (socketEvent.type == "Status") {
      setStatusSnap(socketEvent.nodeSnap);
    }
    const findCurrentTestStep = socketEvent?.reciept?.filter((step) => {
      return step?.type == socketEvent?.type;
    })[0];
    values[findCurrentTestStep.type] = {
      node: socketEvent.towerValue,
      calibrator: socketEvent.calibratorValue,
    };
    setValues(values);
    if (values?.started?.node?.type == values?.started?.calibrator?.type) {
      setLoading(false);
    }
    const newsteps = steps.map((ss) => {
      if (ss?.order == findCurrentTestStep.order) {
        return { ...ss, isChecked: socketEvent.isChecked, status: "complete" };
      } else if (ss?.order == findCurrentTestStep.order + 1) {
        return { ...ss, status: "current" };
      } else if (ss?.order > findCurrentTestStep.order) {
        return { ...ss, status: "upcoming" };
      } else {
        return { ...ss, status: "complete" };
      }
    });
    setSteps(newsteps);
  });

  const stopTesting = async () => {
    if (selectedRecord?.id) {
      setNodeId("");
      setCalibratorId("");
      setSelectedRecord(null);
      setTestStarted(false);
      setSelectedNode(null);
      setSelectedTestType(null);
    } else {
      await testingService
        .stopTesting({ nodeId: nodeId, calibratorId: calibratorId })
        .then((res) => {
          setNodeId("");
          setCalibratorId("");
          setSelectedRecord(null);
          setTestStarted(false);
          setSelectedNode(null);
          setSelectedTestType(null);
          handleTestRecordsPaginated();
        });
    }
  };
  return (
    <div className="w-full h-full overflow-y-scroll no-scrollbar xs:hidden">
      <div className="w-full flex flex-row items-center justify-between border-b-[1px] mb-5">
        <span className="text-secondary-900 text-[28px] font-medium ml-10 select-none">
          Nodes
        </span>
      </div>
      {loading ? (
        <div className="w-12 h-12 mx-auto mt-96">
          <Loading />
        </div>
      ) : (
        <>
          <div className="w-full h-[86%] flex">
            <div className="w-1/5 h-full ">
              <nav aria-label="Progress">
                <ol role="list" className="overflow-hidden">
                  {steps?.map((step, stepIdx) => (
                    <li
                      key={step?.name}
                      className={classNames(
                        stepIdx !== steps.length - 1 ? "pb-20" : "",
                        "relative"
                      )}
                    >
                      {step?.status === "complete" ? (
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div
                              className={`absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 ${
                                step?.isChecked ? "bg-[#7F56D9]" : "bg-red-600"
                              } `}
                              aria-hidden="true"
                            />
                          ) : null}
                          <a
                            href={step?.href}
                            className="group relative flex items-start"
                          >
                            <span className="flex h-9 items-center">
                              {step?.isChecked ? (
                                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-[#7F56D9] group-hover:bg-indigo-800">
                                  <CheckIcon
                                    className="h-5 w-5 text-white"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : (
                                <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-red-600 group-hover:bg-indigo-800">
                                  <XIcon
                                    className="h-5 w-5 text-white"
                                    aria-hidden="true"
                                  />
                                </span>
                              )}
                            </span>
                            <span className="ml-4 flex min-w-0 flex-col pt-2">
                              <span className="text-sm font-medium text-[#344054] ">
                                {step?.name}
                              </span>
                            </span>
                          </a>
                        </>
                      ) : step?.status === "current" ? (
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div
                              className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-300"
                              aria-hidden="true"
                            />
                          ) : null}
                          <a
                            href={step?.href}
                            className="group relative flex items-start"
                            aria-current="step"
                          >
                            <span
                              className="flex h-9 items-center"
                              aria-hidden="true"
                            >
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-indigo-600 bg-white">
                                <span className="h-2.5 w-2.5 rounded-full bg-indigo-600" />
                              </span>
                            </span>
                            <span className="ml-4 flex min-w-0 flex-col pt-2">
                              <span className="text-sm font-medium text-indigo-600">
                                {step?.name}
                              </span>
                            </span>
                          </a>
                        </>
                      ) : (
                        <>
                          {stepIdx !== steps.length - 1 ? (
                            <div
                              className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5"
                              aria-hidden="true"
                            />
                          ) : null}
                          <a
                            href={step?.href}
                            className="group relative flex items-start"
                          >
                            <span
                              className="flex h-9 items-center"
                              aria-hidden="true"
                            >
                              <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
                                <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
                              </span>
                            </span>
                            <span className="ml-4 flex min-w-0 flex-col pt-2">
                              <span className="text-sm font-medium text-gray-500">
                                {step?.name}
                              </span>
                            </span>
                          </a>
                        </>
                      )}
                    </li>
                  ))}
                </ol>
              </nav>
            </div>
            <div className="flex w-full h-full flex-col gap-y-4">
              {steps?.map((ss) => {
                if (ss?.name == "started") {
                  return (
                    <div className="w-full h-full max-h-[96px] gap-x-4 flex  ">
                      <TestCard
                        name={"Node ID"}
                        value={values[ss?.name]?.node?.nodeId}
                      />
                      <TestCard
                        name={"Versiyon No"}
                        value={values[ss?.name]?.node?.espVersion}
                      />
                      <TestCard
                        name={"RSSI"}
                        value={values[ss?.name]?.node?.RSSI}
                      />
                      <TestCard
                        name={"IP Address"}
                        value={values[ss?.name]?.node?.ipAddress}
                      />
                      <TestCard
                        name={"Mac Address"}
                        value={values[ss?.name]?.node?.macAddress}
                      />
                    </div>
                  );
                } else if (ss?.name == "andonTest") {
                  return (
                    <div className="w-full h-full max-h-[96px] gap-x-4 flex  ">
                      <TestCard
                        name={"Tower Andon Button"}
                        value={values[ss?.name]?.node?.AndonTest}
                      />
                      <TestCard
                        name={"Calibrator Andon Button"}
                        value={"Completed"}
                      />
                    </div>
                  );
                } else if (ss?.name == "digitalSensor1") {
                  return (
                    <div className="w-full h-full max-h-[96px] gap-x-4 flex  ">
                      <TestCard
                        name={"Tower Digital Sensor 1"}
                        value={values[ss?.name]?.node?.digitalValues}
                      />
                      <TestCard
                        name={"D1 Plug"}
                        value={values[ss?.name]?.calibrator?.d1Plug}
                      />
                      <TestCard
                        name={"Calibrator Digital Sensor 1"}
                        value={values[ss?.name]?.calibrator?.d1Status}
                      />
                    </div>
                  );
                } else if (ss?.name == "digitalSensor2") {
                  return (
                    <div className="w-full h-full max-h-[96px] gap-x-4 flex  ">
                      <TestCard
                        name={"Digital Sensor 2"}
                        value={values[ss?.name]?.node?.digitalValues}
                      />
                      <TestCard
                        name={"D2 Plug"}
                        value={values[ss?.name]?.calibrator?.d2Plug}
                      />
                      <TestCard
                        name={"Digital Sensor 2"}
                        value={values[ss?.name]?.calibrator?.d2Status}
                      />
                    </div>
                  );
                } else if (ss?.name == "analogSensor1") {
                  return (
                    <div className="w-full h-full max-h-[96px] gap-x-4 flex  ">
                      <TestCard
                        name={"Tower Analog Sensor 1"}
                        value={values[ss?.name]?.node?.analogSensor1}
                      />
                      <TestCard
                        name={"A1 Plug"}
                        value={values[ss?.name]?.calibrator?.a1Plug}
                      />
                      <TestCard
                        name={"Calibrator Input"}
                        value={values[ss?.name]?.calibrator?.a1Input}
                      />
                      <TestCard
                        name={"Calibrator Analog Sensor 1"}
                        value={values[ss?.name]?.calibrator?.a1CurrentValue}
                      />
                    </div>
                  );
                } else if (ss?.name == "analogSensor2") {
                  return (
                    <div className="w-full h-full max-h-[96px] gap-x-4 flex  ">
                      <TestCard
                        name={"Analog Sensor 2"}
                        value={values[ss?.name]?.node?.analogSensor2}
                      />
                      <TestCard
                        name={"A2 Plug"}
                        value={values[ss?.name]?.calibrator?.a2Plug}
                      />
                      <TestCard
                        name={"Calibrator Input"}
                        value={values[ss?.name]?.calibrator?.a2Input}
                      />
                      <TestCard
                        name={"Calibrator Analog Sensor 2"}
                        value={values[ss?.name]?.calibrator?.a2CurrentValue}
                      />
                    </div>
                  );
                }
              })}
              <div className="w-full h-full max-h-[96px] gap-x-4 flex  ">
                <TestCard
                  name={"Status"}
                  value={statusSnap?.statusTest ? statusSnap?.statusTest : "-"}
                />
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              stopTesting();
            }}
            className="mt-2 ml-auto w-full max-w-[120px] min-h-[44px] max-h-[44px] bg-[#7F56D9] shadow-sm rounded-lg border border-[#D0D5DD] px-[14px] py-[10px] text-[#FFFFFF] text-sm font-semibold gap-x-1 flex items-center justify-center"
          >
            Close
          </button>
        </>
      )}
    </div>
  );
};

export default TestStep;
