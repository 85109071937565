import React, { useState, useEffect } from "react";
import { Input } from "../../../components";
import { PlusIcons, TrashIcons } from "../../../assets/icons";
import { testingService } from "../../../services";
export default function NewTestTypeModal({
  t,
  setOpenModal,
  handleTestTypes,
  selectedTestType,
}) {
  const [isDigital1OnOff, setIsDigital1OnOff] = useState(false);
  const [isDigital1Plugged, setIsDigital1Plugged] = useState(false);
  const [isDigital2OnOff, setIsDigital2OnOff] = useState(false);
  const [isDigital2Plugged, setIsDigital2Plugged] = useState(false);
  const [isAnalog1Plugged, setIsAnalog1Plugged] = useState(false);
  const [isAnalog2Plugged, setIsAnalog2Plugged] = useState(false);
  const [testName, setTestName] = useState("");
  const [valueAnalog1, setValueAnalog1] = useState("");
  const [valueAnalog2, setValueAnalog2] = useState("");
  const [selectedTest, setSelectedTest] = useState([]);

  const handleSelectTests = (object) => {
    setSelectedTest([...selectedTest, object]);
  };

  const handleRadioChange = (sensorName) => {
    switch (sensorName) {
      case "digital1":
        setIsDigital1OnOff(!isDigital1OnOff);
        break;
      case "digital2":
        setIsDigital2OnOff(!isDigital2OnOff);
        break;
      default:
        // Handle the default case or throw an error for an unknown sensorName
        break;
    }
  };

  const handlePluggedChange = (sensorName) => {
    switch (sensorName) {
      case "digital1":
        setIsDigital1Plugged(!isDigital1Plugged);
        break;
      case "digital2":
        setIsDigital2Plugged(!isDigital2Plugged);
        break;
      case "analog1":
        setIsAnalog1Plugged(!isAnalog1Plugged);
        break;
      case "analog2":
        setIsAnalog2Plugged(!isAnalog2Plugged);
        break;
      default:
        // Handle the default case or throw an error for an unknown sensorName
        break;
    }
  };

  const handleRemoveTest = (index) => {
    const updatedSelectedTest = [...selectedTest];
    updatedSelectedTest.splice(index, 1);
    setSelectedTest(updatedSelectedTest);
  };

  const handleSubmit = async () => {
    const sendData = {
      name: testName,
      info: selectedTest?.map((item, index) => {
        return { ...item, order: index + 1 };
      }),
    };
    if (selectedTestType) {
      await testingService
        .updateTestTypes(selectedTestType.id, sendData)
        .then((res) => {
          if (res.data.status == 1) {
            handleTestTypes();
            setOpenModal(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await testingService
        .createTestTypes(sendData)
        .then((res) => {
          if (res.data.status == 1) {
            handleTestTypes();
            setOpenModal(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (selectedTestType) {
      setTestName(selectedTestType.name);
      setSelectedTest(selectedTestType.info);
    }
  }, []);
  return (
    <div className="pt-5 flex flex-col gap-y-[24px] w-full h-full">
      <div className="flex flex-col gap-y-[6px] text-[#344054]">
        <span className="text-sm font-medium">{t("test:testName")}</span>
        <Input
          value={testName}
          onChange={(e) => {
            setTestName(e.target.value);
          }}
        />
      </div>
      <div className="flex gap-6 w-full h-[460px]">
        <div className="w-1/2 flex gap-y-4 flex-col">
          <div className="border-b border-[#EAECF0] px-2 pb-1">
            <span className="text-md font-semibold text-[#344054]">
              {t("test:tests")}
            </span>
          </div>
          <div className="flex flex-col h-full overflow-y-auto scrollbar-hide">
            <div className="flex flex-col gap-y-3 accent-[#7F56D9]">
              <div className="border w-full px-3 py-1 rounded-lg text-[#344054] text-sm font-semibold border-[#D0D5DD] flex justify-between items-center">
                <span>Connection</span>
                <button
                  className="flex p-2"
                  onClick={() => {
                    handleSelectTests({
                      type: "started",
                    });
                  }}
                >
                  <PlusIcons />
                </button>
              </div>
              <div className="border w-full px-3 py-1 rounded-lg text-[#344054] text-sm font-semibold border-[#D0D5DD] flex justify-between items-center">
                <span>Andon Button</span>
                <button
                  onClick={() => {
                    handleSelectTests({
                      type: "andonTest",
                    });
                  }}
                  className="flex p-2"
                >
                  <PlusIcons />
                </button>
              </div>
              <div className="border w-full px-3 pt-1 pb-3 rounded-lg text-[#344054] text-sm font-semibold border-[#D0D5DD]">
                <div className="flex justify-between items-center">
                  <span>Dijital Sensör 1</span>
                  <button
                    className="flex p-2"
                    onClick={() => {
                      handleSelectTests({
                        type: "digitalSensor1",
                        plug: isDigital1Plugged ? 1 : 0,
                        status: isDigital1OnOff ? 1 : 0,
                      });
                    }}
                  >
                    <PlusIcons />
                  </button>
                </div>
                <div className="flex flex-col text-[#667085] text-sm font-normal w-full gap-2">
                  <div className="flex gap-x-4 w-full">
                    <label className="w-full flex items-center justify-start gap-2">
                      <input
                        type="radio"
                        checked={isDigital1Plugged}
                        onChange={() => handlePluggedChange("digital1")}
                      />
                      Plugged
                    </label>
                    <label className="w-full flex items-center justify-start gap-2">
                      <input
                        type="radio"
                        checked={!isDigital1Plugged}
                        onChange={() => handlePluggedChange("digital1")}
                      />
                      Unplugged
                    </label>
                  </div>
                  <div className="flex gap-x-4 w-full">
                    <label className="w-full flex items-center justify-start gap-2">
                      <input
                        type="radio"
                        checked={isDigital1OnOff}
                        onChange={() => handleRadioChange("digital1")}
                      />
                      On
                    </label>
                    <label className="w-full flex items-center justify-start gap-2">
                      <input
                        type="radio"
                        checked={!isDigital1OnOff}
                        onChange={() => handleRadioChange("digital1")}
                      />
                      Off
                    </label>
                  </div>
                </div>
              </div>
              <div className="border w-full px-3 pt-1 pb-3 rounded-lg text-[#344054] text-sm font-semibold border-[#D0D5DD]">
                <div className="flex justify-between items-center">
                  <span>Dijital Sensör 2</span>
                  <button
                    className="flex p-2"
                    onClick={() => {
                      handleSelectTests({
                        type: "digitalSensor2",
                        plug: isDigital2Plugged ? 1 : 0,
                        status: isDigital2OnOff ? 1 : 0,
                      });
                    }}
                  >
                    <PlusIcons />
                  </button>
                </div>
                <div className="flex flex-col text-[#667085] text-sm font-normal w-full gap-2">
                  <div className="flex gap-x-4 w-full">
                    <label className="w-full flex items-center justify-start gap-2">
                      <input
                        type="radio"
                        checked={isDigital2Plugged}
                        onChange={() => handlePluggedChange("digital2")}
                      />
                      Plugged
                    </label>
                    <label className="w-full flex items-center justify-start gap-2">
                      <input
                        type="radio"
                        checked={!isDigital2Plugged}
                        onChange={() => handlePluggedChange("digital2")}
                      />
                      Unplugged
                    </label>
                  </div>
                  <div className="flex gap-x-4 w-full">
                    <label className="w-full flex items-center justify-start gap-2">
                      <input
                        type="radio"
                        checked={isDigital2OnOff}
                        onChange={() => handleRadioChange("digital2")}
                      />
                      On
                    </label>
                    <label className="w-full flex items-center justify-start gap-2">
                      <input
                        type="radio"
                        checked={!isDigital2OnOff}
                        onChange={() => handleRadioChange("digital2")}
                      />
                      Off
                    </label>
                  </div>
                </div>
              </div>
              <div className="border w-full px-3 pt-1 pb-3 rounded-lg text-[#344054] text-sm font-semibold border-[#D0D5DD]">
                <div className="flex justify-between items-center">
                  <span>Analog Sensör 1</span>
                  <button
                    className="flex p-2"
                    onClick={() => {
                      handleSelectTests({
                        type: "analogSensor1",
                        plug: isAnalog1Plugged ? 1 : 0,
                        inputCurrent: valueAnalog1,
                      });
                    }}
                  >
                    <PlusIcons />
                  </button>
                </div>
                <div className="flex gap-x-4 w-full text-[#667085] text-sm font-normal">
                  <label className="w-full flex items-center justify-start gap-2">
                    <input
                      type="radio"
                      checked={isAnalog1Plugged}
                      onChange={() => handlePluggedChange("analog1")}
                    />
                    Plugged
                  </label>
                  <label className="w-full flex items-center justify-start gap-2">
                    <input
                      type="radio"
                      checked={!isAnalog1Plugged}
                      onChange={() => handlePluggedChange("analog1")}
                    />
                    Unplugged
                  </label>
                </div>
                <div className="w-full max-h-[40px] min-h-[40px] border px-3 py-2 rounded-lg mt-3">
                  <input
                    type="text"
                    className="w-full outline-none"
                    value={valueAnalog1}
                    onChange={(e) => setValueAnalog1(e.target.value)}
                  />
                </div>
              </div>
              <div className="border w-full px-3 pt-1 pb-3 rounded-lg text-[#344054] text-sm font-semibold border-[#D0D5DD]">
                <div className="flex justify-between items-center">
                  <span>Analog Sensör 2</span>
                  <button
                    className="flex p-2"
                    onClick={() => {
                      handleSelectTests({
                        type: "analogSensor2",
                        plug: isAnalog2Plugged ? 1 : 0,
                        inputCurrent: valueAnalog2,
                      });
                    }}
                  >
                    <PlusIcons />
                  </button>
                </div>
                <div className="flex gap-x-4 w-full text-[#667085] text-sm font-normal">
                  <label className="w-full flex items-center justify-start gap-2">
                    <input
                      type="radio"
                      checked={isAnalog2Plugged}
                      onChange={() => handlePluggedChange("analog2")}
                    />
                    Plugged
                  </label>
                  <label className="w-full flex items-center justify-start gap-2">
                    <input
                      type="radio"
                      checked={!isAnalog2Plugged}
                      onChange={() => handlePluggedChange("analog2")}
                    />
                    Unplugged
                  </label>
                </div>
                <div className="w-full max-h-[40px] min-h-[40px] border px-3 py-2 rounded-lg mt-3">
                  <input
                    type="text"
                    className="w-full outline-none"
                    value={valueAnalog2}
                    onChange={(e) => setValueAnalog2(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-full w-[1px] bg-[#EAECF0]"></div>
        <div className="w-1/2 flex flex-col h-full overflow-y-auto scrollbar-hide">
          <div className="border-b border-[#EAECF0] px-2 pb-1">
            <span className="text-md font-semibold text-[#344054]">
              {t("test:result")}
            </span>
          </div>
          <div className="flex flex-col flex-1 gap-y-3 mt-4">
            {selectedTest?.map((item, index) => {
              return (
                <div className="flex gap-x-3 items-center" key={index}>
                  <div className="min-w-[24px] max-w-[24px] min-h-[24px] max-h-[24px] py-[3px] text-xs font-semibold text-white bg-[#7F56D9] flex items-center justify-center rounded">
                    {index + 1}
                  </div>
                  <div className="flex flex-col">
                    <div
                      key={index}
                      className="w-full text-sm font-semibold text-[#344054]"
                    >
                      {item?.type}
                    </div>
                    <div className="flex text-[#667085] text-xs font-normal">
                      {item?.plug !== undefined && (
                        <div>{item.plug ? "Plugged" : "Unplugged"}</div>
                      )}
                      {item?.status !== undefined && (
                        <div>{item?.status ? ", On" : ", Off"}</div>
                      )}
                      {item?.inputCurrent && <div>, {item?.inputCurrent}</div>}
                    </div>
                  </div>
                  <button
                    className="flex items-center justify-center p-2 ml-auto"
                    onClick={() => handleRemoveTest(index)}
                  >
                    <TrashIcons />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="absolute w-[592px] bottom-3 right-8 flex gap-x-3">
        <button
          onClick={() => {
            setOpenModal(false);
          }}
          className="w-full min-h-[44px] max-h-[44px] bg-white shadow-sm rounded-lg border border-[#D0D5DD] px-[14px] py-[10px] text-[#344054] text-sm font-semibold gap-x-1 flex items-center justify-center"
        >
          {t("buttons:stop")}
        </button>
        <button
          onClick={() => {
            handleSubmit();
          }}
          className="w-full min-h-[44px] max-h-[44px] bg-[#7F56D9] shadow-sm rounded-lg border border-[#D0D5DD] px-[14px] py-[10px] text-[#FFFFFF] text-sm font-semibold gap-x-1 flex items-center justify-center"
        >
          {t("test:create")}
        </button>
      </div>
    </div>
  );
}
