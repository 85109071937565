import React, { useState, useEffect } from "react";
import { t } from "i18next";
import i18next from "i18next";
import { roleService } from "../../services";
import RoleForm from "./RoleForm";
import { rolesTableFilter } from "../../static/constants";
import { PlusIcon, DownIcon, UpIcon } from "../../assets/icons";
import {
  SearchInput,
  PaginationComponent,
  Loading,
  NewAddButton,
  AdminModal,
  ChangeLanguage,
} from "../../components";
const Roles = () => {
  const [openModal, setOpenModal] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [selectedRole, setselectedRole] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const [sortBy, setSortBy] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectLanguage, setSelectLanguage] = useState(i18next.language);
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  useEffect(() => {
    setSelectLanguage(i18next.language);
  }, [i18next.language]);
  const handleRolesPaginated = async () => {
    setLoading(true);
    await roleService
      .getRolesPaginated(pageNo, searchVal)
      .then((res) => {
        setTotalCount(res.data.meta.itemCount);
        setPageCount(res.data.meta.pageCount);
        setPageData(res.data.data);
        if ((Number(pageNo) + 1) >> res.data.meta.pageCount) {
          setPageNo(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  const handlePermissions = async () => {
    roleService
      .listPermissions()
      .then((res) => {
        setPermissions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handlePermissions();
  }, []);
  useEffect(() => {
    handleRolesPaginated();
  }, [pageNo, searchVal]);
  return (
    <>
      <div className="w-full h-full overflow-y-scroll no-scrollbar xs:hidden">
        <div className="w-full flex flex-row items-center justify-between">
          <span className="text-secondary-900 text-[28px] font-medium ml-10 select-none">
            {t("auth:roles")}
          </span>
          <div className="flex items-center gap-4">
            <NewAddButton
              onClick={() => {
                setselectedRole(null);
                setOpenModal(true);
              }}
              label={t("buttons:addNewRole")}
              width={166}
              icon={<PlusIcon className="w-4 h-4 text-gray-400" />}
            />
            <SearchInput
              setSearchVal={setSearchVal}
              width={320}
              placeholder={t("auth:search")}
            />
            <ChangeLanguage
              setVisibleLanguage={setVisibleLanguage}
              setSelectedLanguage={setSelectLanguage}
              selectedLanguage={selectLanguage}
              visibleLanguage={visibleLanguage}
            />
          </div>
        </div>
        <div className="h-px w-full bg-secondary-200 drop-shadow-md mt-3" />
        <div className="h-[85%] w-full mt-6 overflow-hidden no-scrollbar border border-gray-300 rounded-lg shadow-md relative overflow-x-scroll">
          <div className="w-full justify-around flex h-11 bg-gray-50 border-b border-x border-gray-300">
            {rolesTableFilter.map((item, i) => (
              <span
                key={item.value}
                className={`flex justify-center gap-3 h-full items-center min-w-[240px] text-xs text-gray-500 cursor-pointer hover:bg-gray-100 ${
                  item.checked || "hidden"
                } `}
              >
                {item.checked ? t(`company:${item.label}`) : ""}
                {item.value == sortBy ? (
                  <UpIcon className="w-3 h-3" />
                ) : (
                  <DownIcon className="w-3 h-3" />
                )}
              </span>
            ))}
          </div>
          {loading ? (
            <div className="flex items-center w-full justify-center h-full">
              <div className="w-12 h-12">
                <Loading />
              </div>
            </div>
          ) : (
            pageData.map((role, i) => (
              <ul
                className="w-full h-[72px] flex items-center border-b border-x border-gray-200 group"
                key={i}
              >
                {rolesTableFilter.map((d, index) => {
                  if (d.value === "name.en") {
                    return (
                      <li
                        key={index}
                        onClick={() => {
                          setselectedRole(role);
                          setOpenModal(true);
                        }}
                        className="h-full w-full select-none min-w-[240px] flex items-center justify-center text-sm text-secondary-600 group-hover:bg-slate-50"
                      >
                        {role.name.en && role.name.en}
                      </li>
                    );
                  }
                  if (d.value === "name.tr") {
                    return (
                      <li
                        onClick={() => {
                          setselectedRole(role);
                          setOpenModal(true);
                        }}
                        className="h-full w-full select-none min-w-[240px] flex items-center justify-center text-sm text-secondary-600 group-hover:bg-slate-50"
                      >
                        {role.name.tr && role.name.tr}
                      </li>
                    );
                  }
                  if (d.checked)
                    return (
                      <li
                        onClick={() => {
                          setselectedRole(role);
                          setOpenModal(true);
                        }}
                        className="h-full w-full select-none min-w-[240px] flex items-center justify-center text-sm text-secondary-600 group-hover:bg-slate-50"
                      >
                        {role[d.value] && role[d.value]}
                      </li>
                    );
                })}
              </ul>
            ))
          )}
        </div>
        <PaginationComponent
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageCount={pageCount}
        />
      </div>
      <AdminModal
        height={500}
        modalIsOpen={openModal}
        closeModal={() => setOpenModal(false)}
        title={t("dashboard:addNewRoles")}
        children={
          <RoleForm
            setOpenModal={setOpenModal}
            permissions={permissions}
            selectedRole={selectedRole}
          />
        }
      />
    </>
  );
};

export default Roles;
