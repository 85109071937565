import React, { memo } from "react";
import Modal from "react-modal";
import { CLoseIcon } from "../assets/icons";

const CustomModal = ({ children, isOpen, setIsOpen, modalTitle, width, onClose, height }) => {
  function closeModal() {
    setIsOpen(false);
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: 24,
      zIndex: 99999,
      borderRadius: "12px",
      width: width || "fit-content",
      height: height || "auto",
      backgroundColor: "#FFF",
      borderColor: "#FFF",
      overflow: "hidden",
    },
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose ? onClose : closeModal}
        style={customStyles}
        overlayClassName="fixed inset-0 flex items-center justify-center bg-[#0F0F0F] bg-opacity-50 backdrop-blur-[4px] z-[999]"
      >
        <div className="w-full h-full relative flex flex-col ">
          <div className={`flex flex-row relative items-center w-full`}>
            <>
              <p className="text-xl font-semibold text-secondary-900">{modalTitle}</p>
            </>
          </div>

          <div className="w-full h-full"> {children}</div>

          <div className="absolute -right-4 -top-4">
            <div onClick={closeModal} className="w-9 h-9 rounded-lg flex items-center justify-center hover:bg-gray-50">
              <CLoseIcon />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default memo(CustomModal);
