import React, { useState } from "react";
import { Button, Input } from "../../../components";
import defaultSensorService from "../../../services/default-sensor.service";

const SensorSettingsFirstStep = ({ closeModal, handleData }) => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const onSubmit = async () => {
    const data = { code: id, name, description };
    await defaultSensorService.addDefaultSensor(data).then((res) => {
      closeModal();
      handleData();
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <Input label={"ID"} value={id} onChange={({ target }) => setId(target.value)} />
      <Input label={"Sensör Ayar Adı"} value={name} onChange={({ target }) => setName(target.value)} />
      <Input label={"Açıklama"} value={description} onChange={({ target }) => setDescription(target.value)} />
      <div className="flex w-full items-center justify-between gap-3 mt-3">
        <Button label={"Ekle"} onClick={onSubmit} />
      </div>
    </div>
  );
};

export default SensorSettingsFirstStep;
