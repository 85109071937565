import React, { useState, useEffect } from "react";
import { SearchInput } from "../../../components";
import { testingService } from "../../../services";
export default function NodeListModal({
  t,
  tableContent,
  setOpenModal,
  setIsStartTestModalOpen,
  selectedTestType,
  setTestStarted,
  setSelectedNode,
  selectedNode,
}) {
  const [nodes, setNodes] = useState([]);
  const [tempNodes, setTempNodes] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const handleNodes = async () => {
    await testingService.getTestNodes().then((res) => {
      setNodes(res.data);
      setTempNodes(res.data);
    });
  };
  const handleTypeChange = (node) => {
    setSelectedNode(node);
  };
  const startTesting = async () => {
    await testingService
      .startTesting({
        nodeId: selectedNode.nodeId,
        typeId: selectedTestType.id,
        calibratorId: "9694552",
      })
      .then((res) => {
        setOpenModal(false);
        setIsStartTestModalOpen(false);
        setTestStarted(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleNodes();
  }, []);
  useEffect(() => {
    if (searchVal) {
      const filter = tempNodes.filter((e) => {
        let name = `${e.nodeId}`;
        return name
          .replace(/^\./g, "")
          .toLowerCase()
          .includes(searchVal.toLowerCase());
      });
      setNodes(filter);
    } else {
      setNodes(tempNodes);
    }
  }, [searchVal, setSearchVal]);
  return (
    <div className="w-full h-full mt-4">
      <SearchInput setSearchVal={setSearchVal} />
      <div className="flex flex-col gap-y-3 mt-4">
        {nodes?.map((node, i) => {
          return (
            <div
              className="w-full max-h-6 flex items-center gap-x-3 accent-[#7F56D9]"
              key={i}
            >
              <input
                type="radio"
                id={`node_${i}`}
                name="testNode"
                checked={node.nodeId === selectedNode?.nodeId}
                onChange={() => handleTypeChange(node)}
              />
              <label className="text-[#344054] font-semibold text-base">
                {node?.nodeId}
              </label>
            </div>
          );
        })}
      </div>
      <div className="absolute w-[434px] bottom-3 right-8 flex gap-x-3">
        <button
          className="w-full min-h-[44px] max-h-[44px] bg-white shadow-sm rounded-lg border border-[#D0D5DD] px-[14px] py-[10px] text-[#344054] text-sm font-semibold gap-x-1 flex items-center justify-center"
          onClick={() => {
            setOpenModal(false);
          }}
        >
          {t("buttons:stop")}
        </button>
        <button
          className="w-full min-h-[44px] max-h-[44px] bg-[#7F56D9] shadow-sm rounded-lg border border-[#D0D5DD] px-[14px] py-[10px] text-[#FFFFFF] text-sm font-semibold gap-x-1 flex items-center justify-center"
          onClick={() => {
            startTesting();
          }}
        >
          {t("buttons:next")}
        </button>
      </div>
    </div>
  );
}
