import React, { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { embeddedTableFilter } from "../../static/constants";
import { AdminModal, Loading, NewAddButton } from "../../components";
import { DownIcon, EditIcons, PlusIcon, TrashIcons } from "../../assets/icons";
import AddEmbeddedServerSettings from "./AddEmbeddedServerSettings";
import SensorSettingsFirstStep from "./components/SensorSettingsFirstStep";
import defaultSensorService from "../../services/default-sensor.service";

const SavedEmbeddedServerSettings = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSettings, setSelectedSettings] = useState(null);
  const handleData = async () => {
    await defaultSensorService.getDefaultSensor().then(({ data }) => setData(data));
  };

  useEffect(() => {
    handleData();
  }, []);
  return (
    <>
      <div className="w-full h-full overflow-y-scroll no-scrollbar xs:hidden">
        <div className="w-full flex flex-row items-center justify-between">
          <span className="text-secondary-900 text-[28px] font-medium ml-10 select-none">Kayıtlı Sensör Ayarları Listesi</span>
          <div className="flex items-center gap-4">
            <NewAddButton
              onClick={() => {
                setIsOpen(true);
              }}
              width={280}
              label={"Yeni Sensör Ayarları Ekle"}
              icon={<PlusIcon className="w-4 h-4 text-white" />}
              primary
            />
          </div>
        </div>
        <div className="h-px w-full bg-secondary-200 drop-shadow-md mt-3" />
        <div className="h-[85%] w-full mt-6 overflow-hidden no-scrollbar border border-gray-300 rounded-lg shadow-md relative overflow-x-scroll">
          <div className="w-full justify-between flex h-11 bg-gray-50 border-b border-x border-gray-300">
            {embeddedTableFilter.map((item, i) => (
              <li
                key={item.value}
                className={`h-full w-full border-r border-gray-300 flex items-center gap-x-2 justify-center text-xs text-gray-500 cursor-pointer hover:bg-gray-100`}
              >
                {item.label}
                <DownIcon className="text-secondary-500 w-2 h-2" />
              </li>
            ))}
            <li
              className={`h-full min-w-[80px] max-w-[80px] flex items-center justify-center border-r border-gray-300 text-gray-500 cursor-pointer hover:bg-gray-100`}
            />
            <li className={`h-full min-w-[80px] max-w-[80px] flex items-center justify-center text-gray-500 cursor-pointer hover:bg-gray-100`} />
          </div>
          {loading ? (
            <div className="flex items-center w-full justify-center h-full">
              <div className="w-12 h-12">
                <Loading />
              </div>
            </div>
          ) : (
            data.map((role, i) => {
              const sensorNames = (e, type, sensor) => {
                if (type === "analog") {
                  switch (e) {
                    case "first":
                      return "A1" + ` (${sensor})`;
                    case "second" + ` (${sensor})`:
                      return "A2" + ` (${sensor})`;
                    case "third":
                      return "A3" + ` (${sensor})`;
                    case "fourth":
                      return "A4" + ` (${sensor})`;
                    case "fifth":
                      return "A1-A2-A3" + ` (${sensor})`;
                    default:
                      return "Sensor Name";
                  }
                } else {
                  switch (e) {
                    case "first":
                      return "D1";
                    case "second":
                      return "D2";
                    case "third":
                      return "D3";
                    case "fourth":
                      return "D4";
                    default:
                      return "Sensor Name";
                  }
                }
              };
              return (
                <ul className="w-full min-h-[72px] flex items-center border-b border-x border-gray-200 group" key={i}>
                  {embeddedTableFilter.map((d, index) => {
                    return (
                      <li
                        key={index}
                        onClick={() => {}}
                        className="cursor-pointer h-full text-center min-h-[72px] w-full border-r border-gray-300 select-none flex items-center justify-center text-sm text-secondary-600 group-hover:bg-slate-50"
                      >
                        {d?.value === "sensors_included" ? (
                          <>
                            {[...role?.defaultAnalogSensor, ...role?.defaultDigitalSensor].map((item, index) => {
                              return <p className="ml-1">{sensorNames(item?.whichSensor, item?.type ? "analog" : "digital", item?.type?.name)},</p>;
                            })}
                          </>
                        ) : (
                          role[d.value]
                        )}
                      </li>
                    );
                  })}
                  <li
                    onClick={() => {
                      setModalIsOpen(true);
                      setSelectedSettings(role?.id);
                    }}
                    className="cursor-pointer h-full min-h-[72px] min-w-[80px] select-none flex items-center justify-center border-r border-gray-300 text-secondary-600 group-hover:bg-slate-50 text-xl"
                  >
                    <EditIcons width={24} height={24} />
                  </li>
                  <li
                    onClick={async () => {
                      defaultSensorService.deleteDefaultSensor(role?.id).then((res) => console.log("res", res));
                    }}
                    className="cursor-pointer h-full min-h-[72px] min-w-[80px] select-none flex items-center justify-center text-secondary-600 group-hover:bg-slate-50 text-xl"
                  >
                    <TrashIcons width={24} height={24} />
                  </li>
                </ul>
              );
            })
          )}
        </div>
      </div>
      <AdminModal
        width={500}
        height={"auto"}
        modalIsOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        children={<SensorSettingsFirstStep closeModal={() => setIsOpen(false)} handleData={handleData} />}
        title={"Yeni Sensör Ayarları Ekle"}
      />
      {selectedSettings && (
        <AddEmbeddedServerSettings
          selectedSettings={selectedSettings}
          modalIsOpen={modalIsOpen}
          closeModal={() => setModalIsOpen(false)}
          refetch={handleData}
        />
      )}
    </>
  );
};

export default SavedEmbeddedServerSettings;
