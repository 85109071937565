import React from "react";
import { DownIcon } from "../assets/icons";

const EditNode = ({ logContent }) => {
  return (
    <div className="w-full flex flex-col gap-y-2 justify-between  border border-gray-300 rounded-lg py-3 px-4 overflow-y-scroll no-scrollbar shadow">
      {logContent?.map((d, i) => (
        <div
          key={i}
          className="flex items-center gap-2 group select-none w-1/2 pl-3"
        >
          <DownIcon className="h-2.5 w-2.5 group-hover:-rotate-90 transition-[300ms]" />
          <p className="text-sm text-gray-500 group-hover:text-gray-800">
            {d.content}
          </p>
        </div>
      ))}
    </div>
  );
};

export default EditNode;
