import React, { useRef, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Input from "./Inputs/Input";
import SearchInput from "./Inputs/SearchInput";
import { observer } from "mobx-react-lite";
import { useAlert } from "react-alert";
import { nodeService } from "../services";
const ChangeNodeModal = observer(
  ({
    changeNode,
    companyNodes,
    setIsOpenChangeNode,
    setIsOpen,
    handleNodes,
  }) => {
    const radio = useRef();
    const alert = useAlert();
    const [checked, setChecked] = useState(false);
    const [value, setValue] = useState("");
    const [cardSerialNo, setCardSerialNo] = useState("");
    const [searchVal, setSearchVal] = useState("");
    const [tempCompanyNodes, setTempCompanyNodes] = useState(companyNodes);
    const [companyNodesData, setCompanyNodesData] = useState(companyNodes);
    const { t } = useTranslation();
    const changeNodeFunction = async () => {
      let data;
      if (value) {
        if (value.length == 7 && cardSerialNo != "") {
          data = {
            selectedNodeId: changeNode.id,
            deviceId: value,
            electronicCardSerialNo: cardSerialNo,
          };
        } else {
          alert.error("Enter 7 digit node id or enter serial no");
        }
      }
      if (checked) {
        data = {
          selectedNodeId: changeNode.id,
          deviceId: checked,
        };
      }
      if (data.deviceId) {
        await nodeService.updateNodeId(changeNode.id, data).then((res) => {
          if (res.data.status == 1) {
            alert.success("Node Id changed successfully");
            handleNodes();
            setIsOpenChangeNode(false);
            setIsOpen(false);
          } else {
            alert.error("Something went wrong");
          }
        });
      }
    };
    const handleSearch = () => {
      const filteredData = companyNodes.filter(
        (x) =>
          x?.name?.toLowerCase().includes(searchVal.toLowerCase()) ||
          x?.metadata?.brand.toLowerCase().includes(searchVal.toLowerCase()) ||
          x?.metadata?.model.toLowerCase().includes(searchVal.toLowerCase())
      );
      setCompanyNodesData(filteredData);
    };
    useEffect(() => {
      if (searchVal) {
        handleSearch();
      } else {
        setCompanyNodesData(tempCompanyNodes);
      }
    }, [searchVal, setSearchVal]);
    return (
      <div className="w-full mt-1.5 pb-2 px-1 no-scrollbar">
        <SearchInput
          setSearchVal={setSearchVal}
          placeholder={t("auth:search")}
        />
        <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg rounded-2xl scrollbar-hide overflow-y-scroll max-h-[270px] mt-2 ">
          <table className="min-w-full rounded-2xl">
            <thead className="bg-gray-50 w-full top-0 sticky z-10 border-b border-gray-300">
              <tr className="w-full ">
                <th
                  scope="col"
                  className="py-3.5 w-5 max-w-5 pl-4 pr-3 text-left  text-sm font-semibold text-[#667085] sm:pl-6 border-r-[1px] border-[#EAECF0]"
                ></th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-center  text-sm font-semibold text-[#667085] sm:pl-6 border-r-[1px] border-[#EAECF0]"
                >
                  Node ID
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-center  text-sm font-semibold text-[#667085] sm:pl-6 border-r-[1px] border-[#EAECF0]"
                >
                  Machine Brand
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {companyNodesData?.map((x) => (
                <tr
                  onClick={() => {
                    if (checked == x?.deviceId) {
                      setChecked(false);
                    } else {
                      setValue("");
                      setCardSerialNo("");
                      setChecked(x?.deviceId);
                    }
                  }}
                  key={x?.deviceId}
                >
                  <td
                    scope="col"
                    className="relative px-2  w-16 h-16 border-r-[1px] border-[#EAECF0] flex items-center justify-center "
                  >
                    <input
                      type="checkbox"
                      className=" w-5 h-5 rounded-3xl text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      ref={radio}
                      checked={checked == x?.deviceId}
                    />
                  </td>
                  <td className="whitespace-nowrap text-center py-4 pl-4 pr-3 text-sm border-r-[1px] border-[#EAECF0] font-medium text-[#667085] sm:pl-6">
                    {x.name}
                  </td>
                  <td className="whitespace-nowrap text-center px-3 py-4 text-sm border-r-[1px] border-[#EAECF0] text-[#667085]">
                    {x?.metadata?.brand + " " + x?.metadata?.model}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="w-full mt-5 flex flex-col gap-y-2.5">
          <Input
            label={"Enter New Node ID"}
            maxLength={"7"}
            inputType={"number"}
            value={value}
            disabled={checked ? true : false}
            onChange={(e) => {
              setChecked(false);
              setValue(e.target.value);
            }}
          />
          <Input
            label={"Enter New Serial No"}
            value={cardSerialNo}
            disabled={checked ? true : false}
            onChange={(e) => {
              setChecked(false);
              setCardSerialNo(e.target.value);
            }}
          />
        </div>

        <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
          <button
            type="button"
            onClick={() => {
              setIsOpenChangeNode(false);
            }}
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
          >
            {t("buttons:stop")}
          </button>
          <button
            onClick={() => {
              changeNodeFunction();
            }}
            type="button"
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
          >
            {t("buttons:save")}
          </button>
        </div>
      </div>
    );
  }
);

export default ChangeNodeModal;
