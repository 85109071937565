import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import Input from "../../components/Inputs/Input";
import { useStore } from "../../hooks/useStores";
import { useAlert, positions } from "react-alert";
import roleService from "../../services/role.service";
const PermissionForm = ({
  setOpenModal,
  selectedPermission,
  handlePermissions,
}) => {
  const { app } = useStore();
  const alert = useAlert();
  const schema = yup.object({
    tr: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    en: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    key: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    role: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tr: "",
      en: "",
      key: "",
      role: "",
    },
  });
  useEffect(() => {
    if (selectedPermission) {
      setValue("tr", selectedPermission.name.tr);
      setValue("en", selectedPermission.name.en);
      setValue("role", selectedPermission.role);
      setValue("key", selectedPermission.key);
    } else {
    }
  }, [selectedPermission]);
  const onSubmit = (data) => {
    app.setLoadingOverlay(true);
    if (selectedPermission && selectedPermission._id) {
      roleService
        .updatePermission(selectedPermission._id, {
          name: {
            tr: data.tr,
            en: data.en,
          },
          role: data.role,
          key: data.key,
        })
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Updated.", {
              position: positions.TOP_LEFT,
            });
            handlePermissions();
            setOpenModal(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
            handlePermissions();
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    } else {
      roleService
        .createPermission({
          name: {
            tr: data.tr,
            en: data.en,
          },
          role: data.role,
          key: data.key,
        })
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Successfull.", {
              position: positions.TOP_LEFT,
            });
            handlePermissions();
            setOpenModal(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
            handlePermissions();
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    }
  };
  return (
    <div className=" w-full h-full flex flex-col">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full h-full gap-6 mt-3 mb-3"
      >
        <div className="flex flex-col gap-y-4">
          <div className="w-full flex justify-between">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("company:permissionName")}
                  width={475}
                  value={value}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  validate={errors.tr ? "error" : ""}
                  errorMessage={errors.tr ? errors.tr.message : ""}
                />
              )}
              name="tr"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("company:permissionNames")}
                  width={475}
                  value={value}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  validate={errors.en ? "error" : ""}
                  errorMessage={errors.en ? errors.en.message : ""}
                />
              )}
              name="en"
            />
          </div>
          <div className="w-full flex justify-between">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("company:permissionKey")}
                  width={475}
                  value={value}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  validate={errors.key ? "error" : ""}
                  errorMessage={errors.key ? errors.key.message : ""}
                />
              )}
              name="key"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("company:role")}
                  width={475}
                  value={value}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  validate={errors.role ? "error" : ""}
                  errorMessage={errors.role ? errors.role.message : ""}
                />
              )}
              name="role"
            />
          </div>
        </div>
        <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            onClick={""}
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
          >
            {t("buttons:save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default PermissionForm;
