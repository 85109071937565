import {
  Dashboard,
  DashboardNavigation,
  Nodes,
  AuthNavigation,
  Login,
  Roles,
  Permission,
  IndustriesList,
  Statuses,
  Sensors,
  Softwares,
  Users,
  NewPassword,
  Test,
  CCount,
  NodesPage,
} from "../screens";
import {
  FactoryIcon,
  IndustryIcon,
  NewRoleIcon,
  NodeIcon,
  RoleIcon,
  Upload,
  KeyIcon,
  TestIcon,
  SensorsIcon,
  PollIcon,
  CCountIcon,
  SettingsIcon,
} from "../assets/icons";
import NodesChart from "../screens/Nodes/NodesChart";
import AddEmbeddedServerSettings from "../screens/EmbeddedServer/AddEmbeddedServerSettings";
import SavedEmbeddedServerSettings from "../screens/EmbeddedServer/SavedEmbeddedServerSettings";
const routes = [
  {
    path: "/auth",
    name: "auth",
    element: AuthNavigation,
    isPrivate: false,
    subRoutes: [
      {
        path: "login",
        name: "login",
        element: Login,
      },
    ],
  },
  {
    path: "/change-password",
    name: "changePassword",
    element: AuthNavigation,
    isPrivate: true,
    subRoutes: [
      {
        path: "",
        name: "newPassword",
        element: NewPassword,
      },
    ],
  },
  {
    path: "/dashboard",
    name: "dashboard",
    isPrivate: true,
    element: DashboardNavigation,
    Dashboard,
    subRoutes: [
      {
        path: "industries",
        name: "industries",
        icon: IndustryIcon,
        element: IndustriesList,
      },
      {
        path: "",
        name: "company",
        icon: FactoryIcon,
        element: Dashboard,
      },
      {
        path: "c-count",
        name: "ccount",
        icon: CCountIcon,
        element: CCount,
      },
      {
        path: "system-node",
        name: "nodeList",
        icon: NodeIcon,
        element: Nodes,
      },
      {
        path: "system-node-chart",
        name: "nodeListChartView",
        icon: NodeIcon,
        element: NodesChart,
      },
      {
        path: "nodes",
        name: "newNodeList",
        icon: NodeIcon,
        element: NodesPage,
      },
      {
        path: "embedded-server-settings",
        name: "embeddedServerSettings",
        icon: SettingsIcon,
        element: SavedEmbeddedServerSettings,
      },
      {
        path: "admin-users",
        name: "adminUsers",
        icon: NewRoleIcon,
        element: Users,
      },
      {
        path: "system-roles",
        name: "roles",
        icon: RoleIcon,
        element: Roles,
      },
      {
        path: "softwares",
        name: "softwares",
        icon: Upload,
        element: Softwares,
      },
      {
        path: "system-permission",
        name: "permission",
        icon: PollIcon,
        element: Permission,
      },
      {
        path: "statuses",
        name: "statuses",
        icon: KeyIcon,
        element: Statuses,
      },
      {
        path: "sensors",
        name: "sensors",
        icon: SensorsIcon,
        element: Sensors,
      },
      {
        path: "test/list",
        name: "upu.test",
        icon: TestIcon,
        element: Test,
      },
    ],
  },
];
export default routes;
