import { createContext } from "react";
import { appStore } from "../stores/app.store";
import { authStore } from "../stores/auth.store";
import { modalStore } from "../stores/modal.store";

const StoreContext = createContext({
  auth: authStore,
  app: appStore,
  modal: modalStore,
});
const StoreContextProvider = ({ children }) => (
  <StoreContext.Provider>{children}</StoreContext.Provider>
);
export { StoreContext,StoreContextProvider };
