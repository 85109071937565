import React, { useState, useEffect } from "react";
import { CLoseIcon } from "../../assets/icons";
import Modal from "react-modal";
import { t } from "i18next";
import Select from "../Select/Select";
const FilterModal = ({
  isOpen,
  onRequestClose,
  companies,
  filterVal,
  setFilterVal,
}) => {
  return (
    <Modal
      appElement={document.getElementById("app")}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={{
        overlay: {
          background: "rgba(0, 0, 0, 0.3)",
        },
        content: {
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
          // width: "48%",
          height: "50%",
          background: "#fff",
          padding: "24px 32px 66px",
          border: "1px solid #EAECF0",
          boxShadow:
            "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
          borderRadius: "16px",
          overflow: "hidden",
          display: "flex",
          flexDirection: "column",
          maxHeight: "450px",
        },
      }}
      className={"lg:w-[60%] sm:w-[95%] xs:w-full"}
    >
      <div className="flex w-full items-center justify-between mb-3">
        <span className="font-semibold text-2xl text-[#101828]">
          {t("form:sortFilter")}
        </span>
        <button
          onClick={onRequestClose}
          className={`ml-auto p-2 rounded-md hover:bg-slate-50`}
        >
          <CLoseIcon />
        </button>
      </div>
      <div className="w-full h-full flex flex-col items-center pt-4 gap-y-5 mb-2 overflow-y-scroll no-scrollbar">
        <Select
          placeholder={t("routes:company")}
          items={companies}
          width={580}
          onChange={(v) => {
            setFilterVal(v);
          }}
          value={filterVal}
        />
      </div>
      <div className="absolute bg-white bottom-4 right-0 left-0 flex gap-4 w-full justify-end pr-8">
        <button
          onClick={() => {
            onRequestClose();
          }}
          className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
        >
          {t("buttons:removeFilter")}
        </button>
        <button
          onClick={onRequestClose}
          className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
        >
          {t("buttons:closeButton")}
        </button>
        <button
          onClick={() => {
            onRequestClose();
          }}
          className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
        >
          {t("buttons:save")}
        </button>
      </div>
    </Modal>
  );
};

export default FilterModal;
