import axios from "axios";

// const api = "http://localhost:8083/v1/";
// const api = "http://192.168.1.121:8083/v1/";
const api = "https://admin-api.upu.io/v1/";

const instance = axios.create({
  baseURL: api,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (err) => {
    if (err?.response?.status === 401) {
      window.location.href = "/auth/login";
    }
  }
);

export { instance };
