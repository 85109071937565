const en = {
  auth: {
    login: "Login",
    search: "Search",
    language: "Language",
    userList: "User List",
    nodeList: "Node List",
    newNodeList: "New Node List",
    nodeListChartView: "Node List Chart View",
    userList: "User List",
    settings: "Settings",
    industries: "Industries",
    roles: "Roles",
    permission: "Permission",
    statuses: "Statuses",
    sensors: "Sensors",
    softwares: "Softwares",
    users: "Users",
    ccount: "C-Count",
  },
  dashboard: {
    companyName: "Company Name",
    company: "Company",
    companyInfo: "Company Info",
    contactInfo: "Contact Info",
    contractInfo: "Contract Info",
    registerNode: "Register Node",
    nodeInfo: "Node Info",
    userList: "User List",
    maintanence: "Fault and Maintanence",
    liveStatus: "Instant Status",
    logRecord: "Log Record",
    addNewRoles: "Add New Role",
    addNewStatus: "Add New Status",
    addNewPermission: "Add New Permission",
    country: "Country",
    image: "Image",
    name: "Name",
    email: "E-mail",
    addToAnotherCompany: "Add to Another Company",
    deleteUser: "Delete User",
    chooseCompanyContent:
      "Select the companies to which you want to add the user.",
    chooseCompany: "Choose Company (ies)",
    lastName: "Last Name",
    surname: "Surname",
    industry: "Industry",
    city: "City",
    nodeLimit: "Node Limit",
    userLimit: "User Limit",
    solution: "Solution",
    accountType: "Account Type",
    adress: "Adress",
    contactNo: "Contact No",
    contactPhone: "Contact Phone",
    taxOffice: "Tax Office",
    taxNo: "Tax No",
    phone: "Phone No",
    status: "Status",
    contactName: "Contact Name",
    contactEmail: "Contact E-mail",
    limitZone: "Limit Zone",
    device: "Device State",
    stm: "STM Version",
    esp: "ESP Version",
    email: "E-mail",
    nodeList: "Node List",
    newNodeList: "New Node List",
    nodeListChartView: "Node List Chart View",
    logRecord: "Log Record",
    nodeText: "There Is No Node Registered To The Company.",
    noNode: "No Node",
    software: "ESP",
    deleteUserText: "Are you sure you want to delete this user?",
  },
  buttons: {
    start: "Start",
    login: "Login",
    next: "Next",
    prev: "Prev",
    add: "Add",
    delete: "Delete",
    addNew: "Add New",
    change: "Change",
    stop: "Cancel",
    save: "Save",
    addNewIndustry: "Add New Industry",
    addNewNode: "Add New Node",
    addPermission: "Add Permission",
    addNewRole: "Add New Role",
    addNewStatus: "Add New Status",
    removeFilter: "Remove Filter",
    closeButton: "Close",
    updateDevices: "Update Devices",
    updateAllDevices: "Update All Devices",
    updateSelectedDevices: "Update Selected Devices",
    addNewUser: "Add New User",
    send: "Send",
    applyFilter: "Apply Filter",
    sortBy: "Sort By",
    removeAllFilters: "Remove All Filters",
    removeFilter: "Remove Filter",
    filtered: "Filtered",
    closeTable: "Close Table",
  },
  companyContent: {
    active: "Active",
    yearlySubscription: "Yearly Subscription",
    membershipStatus: "Membership Status",
  },
  company: {
    deleteUser: "Delete User",
    addNewCompany: "Add New Company",
    editCompany: "Edit Company",
    accountType: "Account Type",
    membershipTime: "Membership Time",
    purchasedPackages: "Purchased Packages",
    industry: "Industry",
    industryName: "Industry Name (TR)",
    industryNames: "Industry Name (EN)",
    permissionName: "Permission Name (TR)",
    permissionNames: "Permission Name (EN)",
    key: "Key",
    role: "Role",
    status: "Status",
    nameEn: "Name (EN)",
    nameTr: "Name (TR)",
    companies: "Companies",
    roleName: "Role Name (TR)",
    roleNames: "Role Name (EN)",
    roleKey: "Role Key",
    statusName: "Status Name",
    statusCode: "Status Code",
    statusColor: "Status Color",
    permissionList: "Permission List",
    timeZone: "Timezone",
    fiscalYear: "Fiscal Year Start Date",
    fiscalYearEnd: "Fiscal Year End Date",
    authorizedLastName: "Authorized Lastname",
    contractNo: "Contract No",
    cardVersion: "Electronic Card Version",
    cardSerialNo: "Electronic Card Serial No",
    softwareTest: "Software Test",
    espVersionNo: "Esp Version",
    stmVersionNo: "Stm Version",
    hardwareTest: "Hardware Test",
    hardwareVersion: "Hardware Version",
    faultRepairDate: "Fault Repair Date",
    fault: "Fault Cause",
    productionDate: "Production Date",
    faultDescription: "Fault Description",
    sendingMethod: "Sending Method",
    sendingDate: "Sending Date",
    transactionsMade: "Transaction Made",
    replacedModule: "Replaced Module",
    faultNotification: "Fault Notification Date",
    previousRecord: "No Previous Record Found",
    deviceState: "Device State",
    ipAddress: "IP Adress",
    companyList: "Company List",
    companyName: "Company",
    nodeId: "Node ID",
    deviceId: "Device ID",
    nodeSnapLimit: "Node Snap Limit",
    status: "Status",
    stmVersion: "STM Version",
    espVersion: "ESP Version",
    rssi: "RSSI",
    productDate: "Product Date",
    general: "General",
    permissionKey: "Permission Key",
    brand: "Machine Brand",
    faultReporter: "Fault Reporter",
    faultRepairman: "Fault Repairman",
    faultApprover: "Fault Approver",
    description: "Description",
    image: "Image",
    name: "Name",
    lastName: "Last Name",
    email: "E-mail",
    addToAnotherCompany: "Add to Another Company",
    update: "Update",
    updateStatus: "Update Status",
    company: "Company",
    reason: "Reason",
    avatar: "Avatar",
    password: "Password",
    passwordRepeat: "Password Repeat",
    electronicCardSerialNo: "Electronic Card No",
    oldVersion: "Old Version",
    newVersion: "New Version",
    type: "Type",
    date: "Date",
    createdDate: "Created Date",
    updatedDate: "Updated Date",
  },
  form: {
    edit: "Edit",
    selectFile: "Select File",
    removePicture: "Remove Picture",
    selectDate: "Select Date",
    startAnd: "Fiscal Year Start and End Date",
    authorizedName: "Authorized Name",
    companyPhone: "Company Phone Number",
    authorizedMail: "Authorized E-mail",
    authorizedPhone: "Authorized Number",
    timePeriod: "Time Period",
    equipmentCredit: "Equipment Credit",
    personalCredit: "Personal Credit",
    sortFilter: "Filter",
    editNode: "Edit Node",
    node: "Node Limit",
    user: "User Limit",
    junction: "Junction Limit",
    message: "Message Limit",
    operation: "Operation Limit",
    poll: "Poll Limit",
    task: "Task Limit",
    department: "Department Limit",
    notification: "Notification Limit",
    embeddedServer: "Embedded Server Limit",
    stockCardOperation: "Stock Card Operation Limit",
    externalOperation: "External Operation Limit",
    supplier: "Supplier Limit",
    order: "Order Limit",
    stockCard: "Stock Card Limit",
    attend: "Attend Limit",
    shift: "Shift Limit",
  },
  validation: {
    empty: "Cannot be empty",
    string: "Input must be in string format",
    email: "Input must be in email format",
    number: "Input must be in number format",
    max30: "30 is the maximum value for this input",
    min0: "0 is the minimum value for this input",
    date: "Input must be in date format",
  },
  fileInput: {
    profilSubTitle: "Click to upload picture.",
    uploadHeaderPicture: "Profile Picture",
    documentSubTitle: "Click To Upload Document",
    uploaderHeaderDoc: "Document",
    uploadAvatar: "Upload Avatar",
    changePic: "Change Picture",
    cropPicture: "Crop Picture",
    removePicture: "Remove Picture",
    uploadFile: "Upload File",
    removeFile: "Change File",
    //Equipment
    equipmentPicture: "Equipment Picture",
    equipSubTitle: "Click to upload profile picture.",
  },
  routes: {
    industries: "Industries",
    company: "Company",
    nodeList: "Node List",
    newNodeList: "New Node List",
    nodeListChartView: "Node List Chart View",
    roles: "Roles",
    permission: "Permission",
    settings: "Settings",
    statuses: "Statuses",
    sensors: "Sensors",
    softwares: "Softwares",
    users: "Users",
    adminUsers: "Admin Users",
    ccount: "C-Count",
    embeddedServerSettings: "Kayıtlı Sensör Ayar...",
  },
  sensor: {
    addNewSensor: "Add New Sensor",
    addNewUnit: "Add New Unit",
    name: "Sensor Name",
    type: "Sensor Type",
    unitNameShort: "Unit Short",
    unitName: "Unit Name",
  },
  status: {
    addNewStatus: "Add New Status",
    addNewDescription: "Add New Description",
    name: "Status Name",
    type: "Status Type",
    descriptionEn: "Desc (EN)",
    descriptionTr: "Desc (TR)",
  },
  test: {
    testTypes: "Test Types",
    startTest: "Start New Test",
    newTest: "Create New Test Type",
    testName: "Test Adı",
    create: "Oluştur",
    tests: "Testler",
    result: "Sonuç",
  },
  software: {
    softwares: "Softwares",
    addNewSoftware: "Add Software",
    editSoftware: "Edit Software",
    softwareName: "Software Name",
    softwareVersion: "Software Version",
    softwareDate: "Software Date",
    updateDevice: "Update Device",
    serialNo: "Serial No",
    selectAll: "Select All",
    updateAll: "Update All",
    selectSoftware: "Select Software",
    softwareArchitectureApproval: "Software Architecture Approval",
    testEngineerApproval: "Test Engineer Approval",
    fieldTestApproval: "Field Test Approval",
    confirmApproval: "Approve Software",
    confirmApprovalHeader: "Do you want to approve this software?",
    confirm: "Confirm",
    deleteSoftware: "Delete Software",
    deleteSoftwareHeader: "Do you want to delete this software?",
    delete: "Delete",
    version: "Version",
    type: "Type",
    description: "Description",
    updateSelectedDevices: "Update Selected Devices",
    updateAllDevicesForThisCompany: "Update For Company",
    softwareUpdated: "Software Updated",
    softwareUpdateFailed: "Software Update Failed",
    softwareDeleted: "Software Deleted",
    softwareDeleteFailed: "Software Delete Failed",
    softwareApproved: "Software Approved",
    softwareApproveFailed: "Software Approve Failed",
    softwareAdded: "Software Added",
    softwareAddFailed: "Software Add Failed",
    softwareUpdateSent: "Software Update Sent",
    updateReport: "Update Report",
    success: "Success",
    failed: "Failed",
    updateNextTime: "Update Next Time",
    updateFailed: "Update Failed",
    deselect: "Deselect",
  },
  ccount: {
    addNewCCount: "Add New C-Count",
    editCCount: "Edit C-Count",
    zone: "Zone",
    value: "Value",
  },
  notification: {
    sendNotification: "Send Notification",
    notificationTr: "Notification (TR)",
    notificationEn: "Notification (EN)",
    targetCompanies: "Target Companies",
  },
};

export default en;
