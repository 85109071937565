import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { t } from "i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAlert, positions } from "react-alert";
import { Input, Select } from "../../components";
import { softwareService } from "../../services";
import { File } from "../../assets/icons";
const SoftwareForm = ({
  setOpenModal,
  selectedSoftware,
  handleSoftwaresPaginated,
}) => {
  const alert = useAlert();
  const [file, setFile] = useState(null);
  const schema = yup.object({
    type: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    version: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    description: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      version: "",
      description: "",
      type: "",
    },
  });
  const onSubmit = (data) => {
    var formData = new FormData();
    formData.append("version", data.version);
    formData.append("description", data.description);
    formData.append("type", data.type);
    if (selectedSoftware && selectedSoftware.id) {
      if (file !== null) {
        formData.append("file", file);
      }
      softwareService
        .update(selectedSoftware.id, formData)
        .then((res) => {
          if (res.data.code == 0) {
            alert.success(t("software:softwareUpdated"), {
              position: positions.TOP_LEFT,
            });
            handleSoftwaresPaginated();
          } else {
            alert.error(t("software:softwareUpdateFailed"), {
              position: positions.TOP_LEFT,
            });
          }
          setOpenModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      formData.append("file", file);
      softwareService
        .upload(formData)
        .then((res) => {
          if (res.data.code == 0) {
            alert.success(t("software:softwareAdded"), {
              position: positions.TOP_LEFT,
            });
            handleSoftwaresPaginated();
          } else if (res.data.code == 2) {
            alert.success(res.data.message, {
              position: positions.TOP_LEFT,
            });
          } else {
            alert.error(t("software:softwareAddFailed"), {
              position: positions.TOP_LEFT,
            });
          }
          setOpenModal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  useEffect(() => {
    if (selectedSoftware) {
      setValue("version", selectedSoftware.version);
      setValue("description", selectedSoftware.description);
      setValue("type", selectedSoftware.type);
    }
  }, []);
  const hanfleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };
  return (
    <div className=" w-full h-full flex flex-col">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full h-full gap-6 mt-3 mb-3"
      >
        <div className="max-w-[90%] overflow-hidden">
          <div
            for="input"
            className={`flex gap-x-2 overflow-x-auto scrollbar-hide flex-row items-center focus:outline-none cursor-pointer group w-max h-12`}
          >
            <div
              className={`transition rounded-lg bg-primary-100 group-hover:bg-primary-200 relative flex items-center justify-center text-main w-12 h-12`}
            >
              <span className="text-main  flex items-center justify-center">
                <File width={26} />
              </span>
            </div>
            <div className="relative flex justify-center items-center gap-x-6 mt-2 xs:mt-0">
              <input
                id="input"
                className={"sr-only"}
                type="file"
                onChange={hanfleFileChange}
              />
              <label for="input" className="flex flex-col">
                <p className="text-[#101828] text-base font-medium ">
                  {file?.name
                    ? file?.name
                    : selectedSoftware?.name
                    ? selectedSoftware?.name
                    : t("fileInput:uploadFile")}
                </p>
                <p className={`underline text-xs text-[#667085] mt-1`}>
                  {selectedSoftware?.name || file?.name
                    ? t("fileInput:removeFile")
                    : t("fileInput:documentSubTitle")}
                </p>
              </label>
            </div>
          </div>
        </div>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t("software:version")}
              width={"100%"}
              value={value}
              disabled={false}
              onBlur={onBlur}
              onChange={onChange}
              validate={errors.version ? "error" : ""}
              errorMessage={errors.version ? errors.version.message : ""}
            />
          )}
          name="version"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              placeholder={t("software:type")}
              items={[
                { value: "esp", label: "ESP" },
                { value: "stm", label: "STM" },
              ]}
              onBlur={onBlur}
              onChange={(v) => {
                setValue("type", v);
              }}
              value={value}
              validate={errors.type ? "error" : ""}
              errorMessage={errors.type ? errors.type.message : ""}
            />
          )}
          name="type"
        />
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Input
              label={t("software:description")}
              width={"100%"}
              value={value}
              disabled={false}
              onBlur={onBlur}
              onChange={onChange}
              validate={errors.description ? "error" : ""}
              errorMessage={
                errors.description ? errors.description.message : ""
              }
            />
          )}
          name="description"
        />
        <div className="absolute w-full max-w-[400px] bg-white bottom-[12px] flex items-center justify-center gap-4 mt-auto">
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            onClick={""}
            className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
          >
            {t("buttons:save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SoftwareForm;
