import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { t } from "i18next";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAlert, positions } from "react-alert";
import { Input, MultiSelect, Loading } from "../../../components";
import { companyService, notificationService } from "../../../services";
const NotificationModal = ({ setIsOpen }) => {
  const alert = useAlert();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const schema = yup.object({
    en: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    tr: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      en: "",
      tr: "",
    },
  });
  const handleCompanies = async () => {
    await companyService
      .listCompanies()
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const onSubmit = async (data) => {
    if (selectedCompanies?.length > 0) {
      setLoading(true);
      await notificationService
        .sendNotify({
          ...data,
          companies: selectedCompanies.map((c) => ({
            id: c.value,
            name: c.label,
          })),
        })
        .then((res) => {
          if (res.data.code === 0) {
            alert.success("Sent.", {
              position: positions.TOP_LEFT,
            });
            setIsOpen(false);
          } else {
            alert.error("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      setLoading(false);
    } else {
      alert.error("Choose company.", {
        position: positions.TOP_LEFT,
      });
    }
  };
  useEffect(() => {
    handleCompanies();
  }, []);
  return (
    <div className=" w-full h-full flex flex-col">
      {loading ? (
        <div className="flex items-center w-full justify-center h-full">
          <div className="w-12 h-12">
            <Loading />
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col w-full h-full gap-6 mt-3 mb-3"
        >
          <MultiSelect
            placeholder={t("notification:targetCompanies")}
            width={"100%"}
            disabled={false}
            items={companies?.map((c) => {
              return { image: c.image, label: c.name, value: c.id };
            })}
            isImage={true}
            setSelectedItems={setSelectedCompanies}
            selectedItems={selectedCompanies}
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("notification:notificationTr")}
                width={"100%"}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.tr ? "error" : ""}
                errorMessage={errors.tr ? errors.tr.message : ""}
              />
            )}
            name="tr"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("notification:notificationEn")}
                width={"100%"}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.en ? "error" : ""}
                errorMessage={errors.en ? errors.en.message : ""}
              />
            )}
            name="en"
          />
          <div className="absolute w-full max-w-[330px] bg-white bottom-[12px] flex items-center justify-center gap-4 mt-auto">
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
            >
              {t("buttons:stop")}
            </button>
            <button
              type="submit"
              onClick={""}
              className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
            >
              {t("buttons:save")}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default NotificationModal;
