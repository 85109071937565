import React, { useState, useEffect } from "react";
import ReactECharts from "echarts-for-react";
import { nodeService } from "../../services";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../hooks/useWindowDimension";
import { pieChartTabularLabels } from "../../static/constants";
import NodesPage from "../NodesPage/NodesPage";

const NodesChart = () => {
  const navigate = useNavigate();
  const { height } = useWindowDimensions();
  const [datas, setDatas] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  const option = (name, data) => {
    return {
      title: {
        text: name,
        x: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        top: "5%",
        left: "center",
        formatter: (value) => {
          return `${value} : ${
            data?.find((item) => item?.name === value)?.value
          }`;
        },
      },
      series: [
        {
          name: name,
          type: "pie",
          radius: ["40%", "70%"],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: "#fff",
            borderWidth: 2,
          },
          label: {
            show: true,
            position: "inside",
            formatter: "{c}",
          },
          labelLine: {
            show: true,
          },
          data: data,
        },
      ],
    };
  };

  const getCompanyDatas = async () => {
    setLoading(true);
    const data = await nodeService.listNodesByCompany().then((res) => res.data);

    const companyLength = data.map((item) => ({
      name: item?.name,
      value: item?.nodes?.length,
      dataArray: item?.nodes,
    }));

    const groupByVersionSTM = (data) => {
      return data.reduce((acc, device) => {
        const key = `${device.stmVersion}`;
        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key]++;
        return acc;
      }, {});
    };

    const stmResult = Object.entries(
      data.reduce((acc, item) => {
        return Object.entries(groupByVersionSTM(item.nodes)).reduce(
          (acc, [key, value]) => {
            acc[key] = (acc[key] || 0) + value;
            return acc;
          },
          acc
        );
      }, {})
    ).map(([name, value]) => ({ name, value }));

    const groupByVersionESP = (data) => {
      return data.reduce((acc, device) => {
        const key = `${device.espVersion}`;
        if (!acc[key]) {
          acc[key] = 0;
        }
        acc[key]++;
        return acc;
      }, {});
    };

    const espResult = Object.entries(
      data.reduce((acc, item) => {
        return Object.entries(groupByVersionESP(item.nodes)).reduce(
          (acc, [key, value]) => {
            acc[key] = (acc[key] || 0) + value;
            return acc;
          },
          acc
        );
      }, {})
    ).map(([name, value]) => ({ name, value }));

    setDatas([companyLength, stmResult, espResult]);
    setLoading(false);
  };

  useEffect(() => {
    getCompanyDatas();
  }, []);

  const onChartClick = (params) => {
    const { name, seriesName } = params;
    let filtered = [];

    if (seriesName === "Nodes") {
      filtered = datas[0]?.filter((item) => item?.name === name);
    } else if (seriesName === "STM Versions") {
      const companiesWithNodes = datas[0]?.filter((company) =>
        company?.dataArray?.some((node) => node?.stmVersion === name)
      );

      filtered = companiesWithNodes?.map((company) => ({
        name: company?.name,
        dataArray: company?.dataArray?.filter(
          (node) => node?.stmVersion === name
        ),
      }));
    } else if (seriesName === "ESP Versions") {
      const companiesWithNodes = datas[0]?.filter((company) =>
        company?.dataArray?.some((node) => node?.espVersion === name)
      );

      filtered = companiesWithNodes?.map((company) => ({
        name: company?.name,
        dataArray: company?.dataArray?.filter(
          (node) => node?.espVersion === name
        ),
      }));
    }

    setFilteredData(filtered);
    setIsTableVisible(true);
  };

  const onEvents = {
    click: onChartClick,
  };

  return (
    <div className="w-full h-full flex flex-col relative overflow-y-auto">
      <div className="w-full flex flex-row items-center justify-start border-b pl-6 pb-3">
        <div
          onClick={() => {
            navigate("/dashboard/system-node");
          }}
          className="cursor-pointer w-[24px] h-[20px]"
        >
          <ArrowLeftIcon className="w-6 h-6 text-gray-700" />
        </div>
        <span className="text-secondary-900 text-[28px] font-medium ml-6 select-none">
          Node List Chart View
        </span>
      </div>
      <div className="w-full flex items-center flex-wrap min-h-[800px]">
        {loading ? (
          <div className="flex gap-x-5 h-full items-start w-full mt-6">
            <div className="w-1/3 h-[400px] animate-pulse rounded-lg bg-slate-300"></div>
            <div className="w-1/3 h-[400px] animate-pulse rounded-lg bg-slate-300"></div>
            <div className="w-1/3 h-[400px] animate-pulse rounded-lg bg-slate-300"></div>
          </div>
        ) : (
          <>
            <div className="w-1/3 h-full">
              <ReactECharts
                onEvents={onEvents}
                option={option("Nodes", datas[0])}
                style={{
                  height: "80%",
                  width: "98%",
                  top: "8px",
                  marginLeft: "8px",
                  marginTop: "36px",
                }}
              />
            </div>
            <div className="w-1/3 h-full">
              <ReactECharts
                onEvents={onEvents}
                option={option("STM Versions", datas[1])}
                style={{
                  height: "80%",
                  width: "98%",
                  top: "8px",
                  marginLeft: "8px",
                  marginTop: "36px",
                }}
              />
            </div>
            <div className="w-1/3 h-full">
              <ReactECharts
                onEvents={onEvents}
                option={option("ESP Versions", datas[2])}
                style={{
                  height: "80%",
                  width: "98%",
                  top: "8px",
                  marginLeft: "8px",
                  marginTop: "36px",
                }}
              />
            </div>
          </>
        )}

        {isTableVisible && (
          <div className=" w-full h-full flex  top-[600px] absolute ">
            <NodesPage
              location={location?.pathname}
              chartData={filteredData}
              tableHeader={pieChartTabularLabels}
              isOpen={setIsTableVisible}
            />
          </div>
        )}
        {/* {isTableVisible && filteredData.length > 0 && (
          <div className="w-full h-[250px] flex flex-wrap bg-yellow-400">
            {filteredData.flatMap((data, index) =>
              data.dataArray.map((array, arrayIndex) => (
                <NodeTable
                  key={`${index}-${arrayIndex}`}
                  node={array}
                  tableFilter={pieChartTabularLabels}
                  // setSelectedNode={setSelectedNode}
                  // onClick={() => setAddNodModal(true)}
                  // setAddNodModal={setAddNodModal}
                  // selectedNodes={selectedNodes}
                  // setSelectedNodes={setSelectedNodes}
                />
              ))
            )}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default NodesChart;
