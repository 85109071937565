import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { testingService } from "../../services";
import { testTableHead } from "../../static/constants";
import generateFileUrl from "../../utils/generateFileUrl";
import TestStep from "./TestStep";
import { TestTypesModal, StartTestModal } from "./components";
import {
  FileCheckIcon,
  PlusIcons,
  DownloadIcon,
  TrashIcons,
} from "../../assets/icons";
import {
  AdminModal,
  Loading,
  PaginationComponent,
  SearchInput,
} from "../../components";
const Test = () => {
  const { t } = useTranslation();
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [testTypes, setTestTypes] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [isTestTypeModalOpen, setIsTestTypeModalOpen] = useState(false);
  const [isStartTestModalOpen, setIsStartTestModalOpen] = useState(false);
  const [testStarted, setTestStarted] = useState(false);
  const [selectedTestType, setSelectedTestType] = useState({});
  const [selectedRecord, setSelectedRecord] = useState({});
  const [selectedNode, setSelectedNode] = useState({});
  const handleTestRecordsPaginated = async () => {
    setLoading(true);
    await testingService
      .getTestRecordsPaginated(pageNo, searchVal)
      .then((res) => {
        setTotalCount(res.data.meta.itemCount);
        setPageCount(res.data.meta.pageCount);
        setPageData(res.data.data);
        if (res.data.meta.pageCount < pageNo) {
          setPageNo(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  const handleTestTypes = async () => {
    setLoading(true);
    await testingService
      .getTestTypes(pageNo, searchVal)
      .then((res) => {
        setTestTypes(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  const deleteRecord = async (id) => {
    setLoading(true);
    await testingService
      .deleteRecord(id)
      .then((res) => {
        if (res.data.status == 1) {
          handleTestRecordsPaginated(pageNo, searchVal);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  useEffect(() => {
    handleTestRecordsPaginated();
  }, [pageNo, searchVal]);
  useEffect(() => {
    handleTestTypes();
  }, []);
  return (
    <>
      {testStarted ? (
        <TestStep
          selectedTestType={selectedTestType}
          selectedRecord={selectedRecord}
          setTestStarted={setTestStarted}
          setSelectedRecord={setSelectedRecord}
          setSelectedNode={setSelectedNode}
          setSelectedTestType={setSelectedTestType}
          handleTestRecordsPaginated={handleTestRecordsPaginated}
        />
      ) : (
        <div className="w-full h-full no-scrollbar xs:hidden overflow-y-hidden">
          <div className="w-full flex flex-row items-center justify-between overflow-y-hidden">
            <span className="text-secondary-900 text-[28px] font-medium ml-10 select-none">
              Test Manager
            </span>
            <div className="flex max-h-[44px] gap-3">
              <SearchInput />
              <button
                className="min-w-[131px] min-h-[44px] max-w-[131px] max-h-[44px] bg-white shadow-sm rounded-lg border border-[#D6BBFB] px-[14px] py-[10px] text-[#6941C6] text-sm font-semibold gap-x-1 flex items-center"
                onClick={() => {
                  setIsTestTypeModalOpen(true);
                }}
              >
                <FileCheckIcon />
                {t("test:testTypes")}
              </button>
              <button
                className="min-w-[157px] min-h-[44px] max-w-[157px] max-h-[44px] bg-[#7F56D9] shadow-sm rounded-lg border border-[#7F56D9] px-[14px] py-[10px] text-[#FFFFFF] text-sm font-semibold gap-x-1 flex items-center"
                onClick={() => {
                  setSelectedRecord(null);
                  setIsStartTestModalOpen(true);
                }}
              >
                <PlusIcons width={20} height={20} />
                {t("test:startTest")}
              </button>
            </div>
          </div>
          <div className="h-full w-full ">
            <div className="h-[85%] w-full mt-6  no-scrollbar border border-gray-300 rounded-xl shadow-md">
              <div
                className={`w-full border-b flex h-11 bg-gray-50 border-gray-300 rounded-t-xl`}
              >
                {testTableHead.map((item, i) => (
                  <div
                    key={i}
                    className={`flex gap-3 h-full items-center text-center text-xs px-6 py-3 font-medium text-[#475467] ${
                      i === testTableHead.length - 1
                        ? "w-[12%] max-w-[160px] justify-center"
                        : " w-[22%] max-w-[320px] border-r justify-start"
                    }`}
                  >
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
              {loading ? (
                <div className="flex items-center w-full justify-center h-full">
                  <div className="w-12 h-12">
                    <Loading />
                  </div>
                </div>
              ) : (
                <div className=" border-gray-200">
                  {pageData.map((record, i) => (
                    <ul
                      className="w-full h-[72px] flex items-center border-b border-gray-200 group"
                      key={i}
                    >
                      <div
                        onClick={() => {
                          setSelectedRecord(record);
                          setTestStarted(true);
                        }}
                        className="w-full"
                      >
                        <div className="w-full max-h-[72px] boder-[#EAECF0] flex text-center">
                          <div className="w-[22%] max-w-[320px] border-r border-[#EAECF0] px-6 py-4 text-sm font-normal text-[#475467] flex justify-start items-center">
                            {record?.nodeId}
                          </div>
                          <div className="w-[22%] max-w-[320px] border-r border-[#EAECF0] px-6 py-4 text-sm font-normal text-[#475467] flex justify-start items-center">
                            {record?.type?.name}
                          </div>
                          <div
                            className={`w-[22%] max-w-[320px] border-r border-[#EAECF0] px-6 py-4 text-sm font-semibold ${
                              record?.status
                                ? "text-[#079455]"
                                : "text-[#D92D20]"
                            } flex justify-start items-center`}
                          >
                            {record?.status ? "Başarılı" : "Başarısız"}
                          </div>
                          <div className="w-[22%] max-w-[320px] border-r border-[#EAECF0] px-6 py-4 flex justify-start items-center">
                            <div className="flex items-center gap-x-[10px]">
                              {record?.userImage ? (
                                <img
                                  src={generateFileUrl(record?.userImage)}
                                  className="max-w-[34px] max-h-[34px] min-w-[34px] min-h-[34px] rounded-full border border-[#EAECF0]"
                                />
                              ) : (
                                <div
                                  className={
                                    "min-w-[40px] max-w-[40px] min-h-[40px] max-h-[40px] bg-primary-700 flex rounded-full border border-[#000000] border-opacity-25 items-center justify-center font-medium text-white text-xl"
                                  }
                                >
                                  {record?.userName?.charAt(0).toUpperCase()}
                                </div>
                              )}
                              <div className="flex flex-col items-start">
                                <span className="text-sm font-semibold text-[#344054]">
                                  {record?.userName}
                                </span>
                                <span className="text-xs font-normal text-[#667085]">
                                  {record?.userDep}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="w-[22%] max-w-[320px] border-r border-[#EAECF0] px-6 py-4 text-sm font-normal text-[#475467] flex justify-start items-center">
                            {record?.date}
                          </div>
                        </div>
                      </div>
                      <div className="w-[12%] max-w-[160px] border-[#EAECF0] px-6 py-4 flex items-center justify-center">
                        <div className="p-[10px]">
                          <DownloadIcon />
                        </div>
                        <div
                          onClick={() => {
                            deleteRecord(record.id);
                          }}
                          className="p-[10px]"
                        >
                          <TrashIcons />
                        </div>
                      </div>
                    </ul>
                  ))}
                </div>
              )}
            </div>
            <PaginationComponent
              pageNo={pageNo}
              setPageNo={setPageNo}
              pageCount={pageCount}
            />
          </div>
          <AdminModal
            height={400}
            width={500}
            modalIsOpen={isTestTypeModalOpen}
            closeModal={() => setIsTestTypeModalOpen(false)}
            title={t("test:testTypes")}
            children={
              <TestTypesModal
                setOpenModal={setIsTestTypeModalOpen}
                t={t}
                testTypes={testTypes}
                handleTestTypes={handleTestTypes}
              />
            }
          />
          <AdminModal
            height={400}
            width={500}
            modalIsOpen={isStartTestModalOpen}
            closeModal={() => setIsStartTestModalOpen(false)}
            title={t("test:testTypes")}
            children={
              <StartTestModal
                setIsStartTestModalOpen={setIsStartTestModalOpen}
                t={t}
                testTypes={testTypes}
                setTestStarted={setTestStarted}
                setSelectedTestType={setSelectedTestType}
                selectedTestType={selectedTestType}
                setSelectedNode={setSelectedNode}
                selectedNode={selectedNode}
              />
            }
          />
        </div>
      )}
    </>
  );
};

export default Test;
