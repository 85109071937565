import { DateRangePicker, DatePicker } from "@mantine/dates";
import "./datePicker.css";
import "dayjs/locale/tr";

const NewDatePicker = ({
  placeholder,
  label,
  rangePicker,
  value,
  setValue,
  size = "md",
  onChange,
  rest,
  disabled,
  width,
  errorMessage = "",
  validate = "",
}) => {
  return (
    <div style={{ width: width }} className="h-9 mb-[34px]">
      {rangePicker ? (
        <DateRangePicker
          label={label}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          size={size}
          disabled={disabled}
          locale="tr"
          {...rest}
        />
      ) : (
        <div>
          <DatePicker
            placeholder={placeholder}
            label={label}
            value={value}
            disabled={disabled}
            onChange={onChange}
            locale="tr"
            size={"sm"}
          />
          {errorMessage && validate && (
            <p className="text-[10px] text-red-500 mt-0.5 mb-1">
              {errorMessage || validate.toLocaleUpperCase()}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default NewDatePicker;
