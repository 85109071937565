import React, { useState } from "react";
import { PlusIcons } from "../../../assets/icons";
import NodeListModal from "./NodeListModal";
import { AdminModal } from "../../../components";

export default function StartTestModal({
  t,
  testTypes,
  tableContent,
  setIsStartTestModalOpen,
  setTestStarted,
  setSelectedTestType,
  selectedTestType,
  setSelectedNode,
  selectedNode,
}) {
  const [isNodeListModalOpen, setIsNodeListModalOpen] = useState();

  const handleTypeChange = (type) => {
    setSelectedTestType(type);
  };

  return (
    <div className="flex w-full h-full mt-4">
      <div className="flex flex-col gap-3">
        {testTypes.map((type, i) => {
          return (
            <div
              className="w-full max-h-6 flex items-center gap-x-3 accent-[#7F56D9]"
              key={i}
            >
              <input
                type="radio"
                id={`type_${i}`}
                name="testType"
                checked={type.id === selectedTestType?.id}
                onChange={() => handleTypeChange(type)}
              />
              <label className="text-[#344054] font-semibold text-base">
                {type.name}
              </label>
            </div>
          );
        })}
      </div>
      <div className="absolute w-[434px] bottom-3 right-8 flex gap-x-3">
        <button
          className="w-full min-h-[44px] max-h-[44px] bg-white shadow-sm rounded-lg border border-[#D0D5DD] px-[14px] py-[10px] text-[#344054] text-sm font-semibold gap-x-1 flex items-center justify-center"
          onClick={() => {
            setIsStartTestModalOpen(false);
          }}
        >
          {t("buttons:stop")}
        </button>
        <button
          className="w-full min-h-[44px] max-h-[44px] bg-[#7F56D9] shadow-sm rounded-lg border border-[#D0D5DD] px-[14px] py-[10px] text-[#FFFFFF] text-sm font-semibold gap-x-1 flex items-center justify-center"
          onClick={() => {
            setIsNodeListModalOpen(true);
          }}
        >
          {t("buttons:next")}
        </button>
      </div>
      <AdminModal
        height={400}
        width={500}
        modalIsOpen={isNodeListModalOpen}
        closeModal={() => setIsNodeListModalOpen(false)}
        title={t("auth:nodeList")}
        children={
          <NodeListModal
            setOpenModal={setIsNodeListModalOpen}
            setIsStartTestModalOpen={setIsStartTestModalOpen}
            t={t}
            tableContent={tableContent}
            selectedTestType={selectedTestType}
            setTestStarted={setTestStarted}
            setSelectedNode={setSelectedNode}
            selectedNode={selectedNode}
          />
        }
      />
    </div>
  );
}
