import React from "react";
import ReactLoading from "react-loading";

const Loading = ({ color }) => {
  return (
    <div className="w-full h-full flex items-center justify-center">
      <ReactLoading
        type={"spin"}
        color={color ? color : "#6941C6"}
        height={"100%"}
        width={"100%"}
      />
    </div>
  );
};

export default Loading;
