import React, { useEffect, useState } from "react";
import { Button } from "../../../components";
import CustomModal from "../../../components/CustomModal";
import AnalogSensorSettings from "./AnalogSensorSettings";

const AnalogSensorCard = ({
  name,
  refetch,
  sensorId,
  deviceId,
  savedData,
  metadataId,
  whichSensor,
  priorityList,
  setDraggedSensor,
  selectedSettings,
  registeredSensors,
}) => {
  const isActive = true;
  const [value, setValue] = useState(0);
  const [isDrag, setIsDrag] = useState(false);
  const [isConsumption, setisConsumption] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const sensorData = { sensorId, value, isConsumption, whichSensor, metadataId, type: "analog" };

  const findRegisterSensor = () => {
    switch (whichSensor) {
      case "first":
        return registeredSensors?.firstAnalog;
      case "second":
        return registeredSensors?.secondAnalog;
      case "third":
        return registeredSensors?.thirdAnalog;
      case "fourth":
        return registeredSensors?.fourthAnalog;
      case "fifth":
        return registeredSensors?.fifthAnalog;
      default:
        break;
    }
  };
  const sensorInfo = findRegisterSensor();
  const findByPriorityCard = priorityList?.filter((a) => a?.analogSensor?.id === sensorId);
  const disableValueButton = findByPriorityCard?.map((e) => e?.value);
  const valueButtons = [{ value: 0 }, { value: 1 }, { value: 2 }];

  useEffect(() => {
    if (savedData?.isConsumption || savedData?.value) {
      setisConsumption(savedData?.isConsumption);
      setValue(savedData?.value);
    }
  }, [savedData]);
  return (
    <>
      <div className="w-full h-[48px] flex items-center gap-3">
        <div
          onDragStart={(e) => {
            setIsDrag(true);
            setDraggedSensor(sensorData);
          }}
          onDragEnd={(e) => {
            setDraggedSensor(null);
            setIsDrag(false);
          }}
          draggable={!findRegisterSensor() ? false : true}
          style={{ opacity: isDrag ? 0 : 1 }}
          className={`w-full h-[48px] border border-secondary-200 transition-all rounded-xl flex items-center ${
            isActive ? " hover:border-gray-300 active:shadow-none bg-white shadow cursor-grab" : "bg-gray-50"
          }`}
        >
          <div className="h-full w-[68.3%] flex items-center pl-3">
            <div className="w-[25%] flex items-center">
              <div style={{ backgroundColor: isActive ? "#17B26A" : "#F04438" }} className={`w-3 h-3 rounded`} />
              <p className="font-semibold text-secondary-700 ml-2">{name || "Sensor Name"}</p>
            </div>
            {sensorInfo && (
              <div className="flex-1 h-full flex flex-col justify-between py-1.5">
                <p className="text-xs font-medium text-secondary-700">{sensorInfo?.type?.name}</p>
                <div className="flex items-center gap-2">
                  <p className="text-[10px] text-secondary-600">Closeby: {sensorInfo?.closeBy}</p>
                  <p className="text-[10px] text-secondary-600">Standby: {sensorInfo?.standBy}</p>
                </div>
              </div>
            )}
          </div>
          <div className="flex items-center justify-center w-[21.2%] h-full">
            <div className="flex gap-2">
              {valueButtons.map((item, index) => {
                const isDisabled = disableValueButton?.includes(item.value);
                return (
                  <Button
                    width={38}
                    size={"sm"}
                    key={index}
                    label={String(item.value)}
                    disabled={!isActive || isDisabled}
                    colorType={value === item.value ? "" : "secondary"}
                    onClick={() => {
                      setValue(item.value);
                    }}
                  />
                );
              })}
            </div>
          </div>
          <div
            onClick={() => {
              if (isActive) {
                setisConsumption(!isConsumption);
              }
            }}
            className={`w-[10.4%] ml-auto flex items-center justify-center h-full hover:bg-gray-50 rounded-r-xl`}
          >
            <div className={`w-5 h-5 rounded-md flex items-center justify-center ${isConsumption ? "bg-[#3E4784]" : "border border-gray-300"}`}>
              {isConsumption && <CheckIcon />}
            </div>
          </div>
        </div>
        <button
          disabled={!isActive}
          type="button"
          onClick={() => setIsOpenModal(true)}
          className={`w-11 h-11 rounded-lg flex items-center justify-center border relative ${isActive ? "shadow" : "bg-gray-50"}`}
        >
          {!findRegisterSensor() && (
            <>
              <div className="bg-red-500 w-2 h-2 rounded-full absolute -top-0.5 -right-0.5 animate-ping" />
              <div className="bg-red-500 w-2 h-2 rounded-full absolute -top-0.5 -right-0.5" />
            </>
          )}
          <Settings />
        </button>
      </div>
      <CustomModal
        modalTitle={name}
        isOpen={isOpenModal}
        isPreventShutdown={true}
        setIsOpen={setIsOpenModal}
        children={
          <AnalogSensorSettings
            selectedSettings={selectedSettings}
            registeredSensor={findRegisterSensor()}
            getRegisteredSensors={refetch}
            whichSensor={whichSensor}
            metadataId={metadataId}
            deviceId={deviceId}
            onClose={() => {
              setIsOpenModal(false);
            }}
          />
        }
      />
    </>
  );
};

export default AnalogSensorCard;

const CheckIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path d="M12.1667 3.5L5.75004 9.91667L2.83337 7" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

const Settings = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <g clip-path="url(#clip0_15623_66101)">
        <path
          d="M9.99996 7.22237C8.41266 7.22237 7.12586 8.46602 7.12586 10.0002C7.12586 11.5343 8.41266 12.7779 9.99996 12.7779C11.5873 12.7779 12.8741 11.5343 12.8741 10.0002C12.8741 8.46602 11.5873 7.22237 9.99996 7.22237ZM18.067 8.01792L17.2888 8.63706C16.4012 9.34331 16.4012 10.657 17.2888 11.3632L18.067 11.9824C18.3427 12.2018 18.4139 12.5817 18.2354 12.8807L16.6988 15.4529C16.5202 15.7519 16.1441 15.8823 15.8097 15.7611L14.8658 15.4194C13.7891 15.0296 12.612 15.6864 12.423 16.7825L12.2573 17.7434C12.1986 18.0839 11.8937 18.3335 11.5366 18.3335H8.46337C8.1062 18.3335 7.80135 18.0839 7.74264 17.7434L7.57692 16.7825C7.38792 15.6864 6.2108 15.0296 5.13415 15.4194L4.1903 15.7611C3.85579 15.8823 3.47973 15.7519 3.30111 15.4529L1.76456 12.8807C1.58597 12.5817 1.65718 12.2018 1.93295 11.9824L2.71114 11.3632C3.59876 10.657 3.59876 9.34331 2.71114 8.63706L1.93295 8.01792C1.65718 7.7985 1.58597 7.41856 1.76456 7.11958L3.30111 4.54737C3.47973 4.2484 3.85579 4.11803 4.1903 4.23915L5.13415 4.58088C6.2108 4.97071 7.38792 4.3139 7.57692 3.21782L7.74264 2.25691C7.80135 1.91641 8.1062 1.66683 8.46337 1.66683H11.5366C11.8937 1.66683 12.1986 1.91641 12.2573 2.25691L12.423 3.21782C12.612 4.3139 13.7891 4.97071 14.8658 4.58088L15.8097 4.23915C16.1441 4.11803 16.5202 4.2484 16.6988 4.54737L18.2354 7.11958C18.4139 7.41856 18.3427 7.7985 18.067 8.01792Z"
          stroke="#475467"
          stroke-width="1.5"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15623_66101">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
