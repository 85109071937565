import React from "react";
import { Error, Success, CLoseIcon } from "../../assets/icons";

const AlertTemplate = ({ style, options, message, close }) => {
  return (
    <div className="w-[300px] flex flex-row m-4 items-center bg-primary-100 pl-2 p-4 rounded-lg shadow">
      {options.type === "error" ? (
        <Error className="text-red-300 ml-1" />
      ) : (
        <Success className="text-green-400 ml-1" />
      )}
      <div className="text-sm font-medium   ml-2.5 mr-4 whitespace-nowrap text-center items-center">
        {message}
      </div>

      <CLoseIcon
        className="text-gray-500  ml-auto w-3 h-3  mr-3  cursor-pointer"
        onClick={() => close()}
      />
    </div>
  );
};

export default AlertTemplate;
