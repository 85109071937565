import { instance } from "../libs/client";
/* eslint-disable camelcase */

class TestingService {
  getTestRecordsPaginated = async (pageNo, search = "") => {
    return instance.get(
      `testing/record?page=${pageNo}${search ? `&search=${search}` : ""}`
    );
  };
  getTestTypes = async () => {
    return instance.get(`testing/type`);
  };
  createTestTypes = async (data) => {
    return instance.post(`testing/type`, data);
  };
  updateTestTypes = async (id, data) => {
    return instance.put(`testing/type/${id}`, data);
  };
  deleteType = async (id) => {
    return instance.delete(`testing/type/${id}`);
  };
  deleteRecord = async (id) => {
    return instance.delete(`testing/record/${id}`);
  };
  getTestNodes = async () => {
    return instance.get(`testing`);
  };
  startTesting = async (data) => {
    return instance.post(`testing`, data);
  };
  stopTesting = async (data) => {
    return instance.put(`testing/stop`, data);
  };
}

const testingService = new TestingService();

export default testingService;
