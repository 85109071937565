import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";

const PhoneInputs = ({ value, onChange, label }) => {
  return (
    <label className="max-h-[70px] min-h-[70px]  max-w-[480px] min-w-[480px] flex flex-col justify-between text-gray-700 text-sm font-medium leading-5">
      {label}
      <PhoneInput
        buttonStyle={{
          background: "white",
          borderRadius: "8px",
          borderTopRightRadius: "0px",
          borderBottomRightRadius: "0px",
        }}
        inputStyle={{ height: "44px", width: "480px", borderRadius: "8px" }}
        className="max-w-[480px] min-w-[480px] max-h-[44px] h-[44px]"
        country={"tr"}
        value={value}
        onChange={onChange}
      />
    </label>
  );
};

export default PhoneInputs;
