import React, { useState } from "react";
import { DownArrow, DownIcon, FactoryIcon } from "../../../assets/icons";
import UserTable from "../../UserTable";
import NoData from "../../NoData/NoData";
const AttachedCompaniesList = ({ selectedIndustry, control, errors, t }) => {
  const [tableFilter, setTableFilter] = useState([
    {
      label: t("dashboard:country"),
      value: "country",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: true,
    },
    {
      label: t("dashboard:city"),
      value: "city",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: true,
    },
    {
      label: t("dashboard:industry"),
      value: "industry",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: true,
    },
    {
      label: t("dashboard:name"),
      value: "name",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: true,
    },
    {
      label: t("dashboard:nodeLimit"),
      value: "nodeLimit",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: true,
    },
    {
      label: t("dashboard:userLimit"),
      value: "userLimit",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: true,
    },
    {
      label: "E-mail",
      value: "email",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: false,
    },
    {
      label: t("dashboard:contactNo"),
      value: "contactPhone",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: false,
    },
    {
      label: t("dashboard:taxOffice"),
      value: "taxOffice",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: false,
    },
    {
      label: t("dashboard:taxNo"),
      value: "taxNo",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: false,
    },
    {
      label: t("dashboard:phone"),
      value: "phone",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: false,
    },
    {
      label: t("dashboard:contactName"),
      value: "contactName",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: false,
    },
    {
      label: t("dashboard:contactEmail"),
      value: "contactEmail",
      checked: true,
      ascd: true,
      sortable: true,
      mandatory: false,
    },
  ]);

  return (
    <div className="w-full flex gap-6 max-h-[300px] relative">
      <div className="w-full">
        {selectedIndustry?.company.length > 0 ? (
          <div className="w-full border border-t-0 border-gray-300 rounded-lg shadow-md">
            <div className="w-max sticky top-0 flex h-11 border-gray-300 rounded-lg shadow-md bg-gray-50 border  ">
              <span
                className={`flex justify-center gap-3 h-full items-center min-w-[200px] max-w-[200px] text-xs text-gray-500 cursor-pointer hover:bg-gray-100`}
              >
                {t("dashboard:image")}
                <DownIcon className="w-3 h-3" />
              </span>
              {tableFilter.map((item) => (
                <span
                  key={item.value}
                  className={` flex justify-center gap-3 h-full items-center min-w-[200px] w-[200px] max-w-[200px] text-xs text-gray-500 cursor-pointer hover:bg-gray-100 ${
                    item.checked || "hidden"
                  }`}
                >
                  {item.checked ? item.label : ""}
                  <DownIcon className="w-3 h-3" />
                </span>
              ))}
            </div>
            <div className="w-full flex flex-col">
              {selectedIndustry?.company.map((company) => {
                return (
                  <UserTable
                    tableFilter={tableFilter}
                    company={company}
                    companyOpen={() => {}}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <div className="mt-4">
            <NoData
              icon={<FactoryIcon />}
              text={"Endüstriye Kayıtlı Firma Bulunmamaktadır"}
              header={"No Company"}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AttachedCompaniesList;
