import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import Input from "../../components/Inputs/Input";
import i18n from "i18next";
import { useStore } from "../../hooks/useStores";
import { useAlert, positions } from "react-alert";
import { statusService } from "../../services/";
import { MinusCircleIcon } from "@heroicons/react/outline";
import { PlusIcon } from "../../assets/icons";
const StatusForm = ({
  setOpenModal,
  selectedStatus,
  handleStatusesPaginated,
}) => {
  const { app } = useStore();
  const [descriptionArr, setDescriptionArr] = useState([]);
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionTr, setDescriptionTr] = useState("");
  const alert = useAlert();
  const schema = yup.object({
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    code: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    color: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      code: "",
      color: "",
    },
  });
  useEffect(() => {
    if (selectedStatus) {
      setValue("name", selectedStatus?.name);
      setValue("code", selectedStatus?.code);
      setValue("color", selectedStatus?.color);
      setDescriptionArr(selectedStatus?.description);
    } else {
    }
  }, [selectedStatus]);
  const onSubmit = async (data) => {
    if (descriptionArr?.length > 0) {
      app.setLoadingOverlay(true);
      if (selectedStatus && selectedStatus.id) {
        await statusService
          .updateStatus(selectedStatus.id, {
            name: data.name,
            code: data.code,
            color: data.color,
            description: descriptionArr,
          })
          .then((res) => {
            if (res.data.status === 1) {
              app.setLoadingOverlay(false);
              alert.success("Updated.", {
                position: positions.TOP_LEFT,
              });
              handleStatusesPaginated();
              setOpenModal(false);
            } else {
              app.setLoadingOverlay(false);
              alert.success("Failed.", {
                position: positions.TOP_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            app.setLoadingOverlay(false);
          });
      } else {
        await statusService
          .createStatus({
            name: data.name,
            code: data.code,
            color: data.color,
            description: descriptionArr,
          })
          .then((res) => {
            if (res.data.status === 1) {
              app.setLoadingOverlay(false);
              alert.success("Successfull.", {
                position: positions.TOP_LEFT,
              });
              handleStatusesPaginated();
              setOpenModal(false);
            } else {
              app.setLoadingOverlay(false);
              alert.success("Failed.", {
                position: positions.TOP_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            app.setLoadingOverlay(false);
          });
      }
    } else {
      alert.error("Need an description.", {
        position: positions.TOP_LEFT,
      });
    }
  };
  const deleteStatus = async () => {
    await statusService
      .deleteStatus(selectedStatus.id)
      .then((res) => {
        if (res.data.status === 1) {
          app.setLoadingOverlay(false);
          alert.success("Deleted.", {
            position: positions.TOP_LEFT,
          });
          handleStatusesPaginated();
          setOpenModal(false);
        } else {
          app.setLoadingOverlay(false);
          alert.success("Failed.", {
            position: positions.TOP_LEFT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        app.setLoadingOverlay(false);
      });
  };
  const addNewUnit = () => {
    if (descriptionEn != "" && descriptionTr != "") {
      setDescriptionArr([
        ...descriptionArr,
        { en: descriptionEn, tr: descriptionTr },
      ]);
      setDescriptionEn("");
      setDescriptionTr("");
    }
  };
  const removeUnit = (e) => {
    setDescriptionArr(
      descriptionArr.filter((u, i) => {
        return i != e;
      })
    );
  };
  return (
    <div className=" w-full h-full flex flex-col">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full h-full gap-6 mt-3 mb-3"
      >
        <div className="w-full flex flex-col justify-between gap-y-4">
          <div className="w-full flex justify-between gap-x-4">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("company:statusName")}
                  width={"45%"}
                  value={value}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  validate={errors.name ? "error" : ""}
                  errorMessage={errors.name ? errors.name.message : ""}
                />
              )}
              name="name"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("company:statusCode")}
                  width={"45%"}
                  value={value}
                  disabled={false}
                  onBlur={onBlur}
                  onChange={onChange}
                  validate={errors.code ? "error" : ""}
                  errorMessage={errors.code ? errors.code.message : ""}
                />
              )}
              name="code"
            />
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Input
                  label={t("company:color")}
                  width={50}
                  value={value}
                  disabled={false}
                  onBlur={onBlur}
                  inputType={"color"}
                  onChange={onChange}
                  validate={errors.color ? "error" : ""}
                  errorMessage={errors.color ? errors.color.message : ""}
                />
              )}
              name="color"
            />
          </div>
          <div className="w-full flex flex-col gap-y-5 gap-x-4">
            {descriptionArr?.length > 0 &&
              descriptionArr?.map((unit, i) => {
                return (
                  <div className="flex items-center w-full justify-between gap-x-2">
                    <Input
                      disabled={true}
                      label={t("status:descriptionTr")}
                      width={"50%"}
                      value={unit.tr}
                      onChange={(e) => {
                        setDescriptionTr(e.target.value);
                      }}
                    />
                    <Input
                      disabled={true}
                      label={t("status:descriptionEn")}
                      width={"50%"}
                      value={unit.en}
                      onChange={(e) => {
                        setDescriptionEn(e.target.value);
                      }}
                    />
                    <div
                      onClick={() => {
                        removeUnit(i);
                      }}
                      className="flex mt-[25px] items-center justify-center"
                    >
                      <MinusCircleIcon width={20} color={"#475467"} />
                    </div>
                  </div>
                );
              })}
            <div className="flex items-center w-full justify-between gap-x-2">
              <Input
                label={t("status:descriptionTr")}
                width={"50%"}
                value={descriptionTr}
                onChange={(e) => {
                  setDescriptionTr(e.target.value);
                }}
              />
              <Input
                label={t("status:descriptionEn")}
                width={"50%"}
                value={descriptionEn}
                onChange={(e) => {
                  setDescriptionEn(e.target.value);
                }}
              />
            </div>
            <button
              type="button"
              onClick={() => addNewUnit()}
              className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center gap-x-2 justify-center text-sm font-medium text-gray-700 border border-gray-300 hover:bg-slate-100"
            >
              <PlusIcon />
              {t("status:addNewDescription")}
            </button>
          </div>
        </div>
        <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
          {selectedStatus?.id && (
            <button
              type="button"
              onClick={() => deleteStatus()}
              className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-red-600 shadow hover:bg-red-500"
            >
              {t("buttons:delete")}
            </button>
          )}
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
          >
            {t("buttons:save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default StatusForm;
