import React from "react";
import { SecondaryButton } from "../index";
import { PlusIcon } from "../../assets/icons";
import NoDataImage from "../../assets/icons/NoDataImage.svg";
import { t } from "i18next";
import AddNewIndustryButton from '../Buttons/AddNewIndustryButton';
const NoData = ({ icon, header, text, label, addNew }) => {
  return (
    <div className="flex flex-col items-center max-w-[352px] gap-3 mx-auto mt-5">
      <div
        className="w-[172px] h-[128px] relative flex justify-center "
        style={{
          backgroundImage: `url(${NoDataImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="text-white absolute bottom-6 ml-1.5 w-8 h-8 max-w-8 max-h-8 flex items-center justify-center">
          {icon}
        </div>
      </div>
      <div className="flex flex-col items-center justify-between text-center">
        <p className="font-medium text-[18px] text-gray-900 xs:text-base">
          {header}
        </p>
        <p className="text-[14px] font-normal text-gray-500 my-2 xs:text-xs ">
          {text}
        </p>
      </div>
      {/* <div className="font-medium text-gray-700 text-[16px] w-[150px]">
        <Button
          colorType={"secondary"}
          size={"md"}
          label={
            <div
              className=" flex justify-center items-center gap-1"
              onClick={addNew}
            >
              <PlusIcon width={12} />
              {t("buttons:add")}
            </div>
          }
        />
      </div> */}
    </div>
  );
};

export default NoData;
