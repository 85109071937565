import { instance } from "../libs/client";
/* eslint-disable camelcase */

class NotificationService {
  sendNotify = async (data) => {
    return instance.post("notification/companies", data);
  };
}

const notificationService = new NotificationService();

export default notificationService;
