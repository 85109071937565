import React, { useEffect, useState } from "react";
import Input from "./Inputs/Input";
import { useForm, Controller, set } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStore } from "../hooks/useStores";
import { useAlert, positions } from "react-alert";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import nodeService from "../services/node.service";
import NewDatePicker from "./Inputs/DatePicker/DatePicker";
import Select from "./Select/Select";
import { LeftArrow, Upload, CheckCircle, PencilIcon } from "../assets/icons";
import AdminModal from "../components/Modals/AdminModal";
import { CreateMaintenance, ImageUploader } from "../components";
import { BsTrash } from "react-icons/bs";
import AlertModal from "./Modals/AlertModal";
import ChangeNodeModal from "./ChangeNodeModal";
import moment from "moment";
const AddNewNod = ({
  setIsOpen,
  selectedNode,
  setSelectedNode,
  children,
  companies,
  handleNodes,
  companyNodes,
}) => {
  const mockData = [
    {
      name: "Yazilim 1",
      version: "1.0.0",
      date: "01.01.2022",
    },
    {
      name: "Yazilim 2",
      version: "1.1.0",
      date: "06.01.2022",
    },
    {
      name: "Yazilim 1",
      version: "1.0.0",
      date: "01.01.2022",
    },
    {
      name: "Yazilim 2",
      version: "1.1.0",
      date: "06.01.2022",
    },
    {
      name: "Yazilim 1",
      version: "1.0.0",
      date: "01.01.2022",
    },
    {
      name: "Yazilim 2",
      version: "1.1.0",
      date: "06.01.2022",
    },
    {
      name: "Yazilim 1",
      version: "1.0.0",
      date: "01.01.2022",
    },
    {
      name: "Yazilim 2",
      version: "1.1.0",
      date: "06.01.2022",
    },
    {
      name: "Yazilim 1",
      version: "1.0.0",
      date: "01.01.2022",
    },
    {
      name: "Yazilim 2",
      version: "1.1.0",
      date: "06.01.2022",
    },
  ];
  const tableFilter = [
    {
      label: "softwareName",
      value: "name",
    },
    {
      label: "softwareVersion",
      value: "version",
    },
    {
      label: "softwareDate",
      value: "date",
    },
  ];
  const [selectedTab, setSelectedTab] = useState("registerNode");
  const [maintenance, setMaintenance] = useState([]);
  const [openMaintenance, setOpenMaintenance] = useState(false);
  const [selectedMaintenance, setSelectedMaintenance] = useState();
  const [isAlert, setIsAlert] = useState(false);

  //change node id states
  const [isOpenChangeNode, setIsOpenChangeNode] = useState(false);
  const [changeNodeId, setChangeNodeId] = useState();

  const { app } = useStore();
  const alert = useAlert();
  const { t } = useTranslation();
  const schema = yup.object({
    softwareUpdateFile: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    nodeId: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    hardwareVersion: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    electronicCardVersion: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    electronicCardSerialNo: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    espVersion: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    stmVersion: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    productDate: yup
      .date()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:date")}`),
    softwareTest: yup.bool().required(`${t("validation:empty")}`),
    hardwareTest: yup.bool().required(`${t("validation:empty")}`),
    company: yup.string().notRequired(),
    deviceState: yup.string().notRequired(`${t("validation:empty")}`),
    ipAddress: yup
      .string()
      .nullable()
      .notRequired(`${t("validation:empty")}`),
    status: yup.string().required(`${t("validation:empty")}`),
    rssi: yup
      .string()
      .nullable()
      .notRequired(`${t("validation:empty")}`),
    nodeSnapLimit: yup.number(),
  });
  const NodeStatus = [
    { label: "NOT_REGISTERED", value: "not_registered" },
    { label: "REGISTERED", value: "registered" },
    { label: "ACTIVATED", value: "activated" },
    { label: "DELETED", value: "deleted" },
    { label: "WAITING_ACTIVATION", value: "waiting_activation" },
  ];
  const Tests = [
    { label: "True", value: true },
    { label: "False", value: false },
  ];
  const tabs = [
    { key: "registerNode", title: "dashboard:registerNode", mandatory: true },
    { key: "nodeInfo", title: "dashboard:nodeInfo", mandatory: true },
    { key: "liveStatus", title: "dashboard:liveStatus", mandatory: false },
    {
      key: "faultAndMaintanence",
      title: "dashboard:maintanence",
      mandatory: true,
    },
    { key: "logRecord", title: "dashboard:logRecord", mandatory: false },
    // { key: "software", title: "dashboard:software", mandatory: false },
  ];
  // const companyOptions = companies.map((company) => {
  //   return {
  //     label: company.name,
  //     value: company.id,
  //   };
  // });

  const RealCompanyOptions = companies
    .filter((company) => {
      return company.status != null;
    })
    .map((company) => {
      return {
        label: company.name,
        value: company.id,
      };
    });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      company: "",
      deviceState: "",
      espVersion: "",
      ipAddress: "",
      rssi: "",
      status: "registered",
      stmVersion: "",
      softwareTest: "",
      hardwareTest: "",
      productDate: new Date(),
      electronicCardSerialNo: "",
      electronicCardVersion: "",
      nodeSnapLimit: "5000",
    },
  });
  const handleMaintenance = async () => {
    await nodeService
      .findOneMaintenance(selectedNode.id)
      .then((res) => {
        setMaintenance(res.data.faultMaintenance);
      })
      .catch((err) => console.log(err));
  };
  const deleteMaintenance = async () => {
    await nodeService
      .deleteMaintenance(selectedMaintenance.id)
      .then((res) => {
        if (res.data.status == 1) {
          alert.success("Successfull.", {
            position: positions.TOP_LEFT,
          });
          handleMaintenance();
          setIsAlert(false);
        } else {
          alert.success("Failed.", {
            position: positions.TOP_LEFT,
          });
          setIsAlert(false);
        }
      })
      .catch((err) => console.log(err));
  };
  const resetDevice = async () => {
    await nodeService
      .reseteDevice(selectedNode.deviceId)
      .then((res) => console.log("res", res))
      .catch((err) => console.log("err", err));
  };
  useEffect(() => {
    if (selectedNode) {
      setValue("company", selectedNode?.company?.id);
      setValue("deviceState", selectedNode?.deviceState);
      setValue("hardwareVersion", selectedNode?.hardwareVersion);
      setValue("productDate", new Date(selectedNode?.productDate));
      setValue("hardwareTest", selectedNode?.hardwareTest);
      setValue("softwareTest", selectedNode?.softwareTest);
      setValue("espVersion", selectedNode?.espVersion);
      setValue("ipAddress", selectedNode?.ipAddress);
      setValue("rssi", selectedNode?.rssi);
      setValue("status", selectedNode?.status);
      setValue("stmVersion", selectedNode?.stmVersion);
      setValue("nodeId", selectedNode?.deviceId);
      setValue("nodeSnapLimit", selectedNode?.nodeSnapLimit);
      setValue("electronicCardSerialNo", selectedNode?.electronicCardSerialNo);
      setValue("electronicCardVersion", selectedNode?.electronicCardVersion);
      handleMaintenance();
    } else {
    }
  }, [selectedNode, setSelectedNode]);
  const deleteNode = (id) => {
    // app.setLoadingOverlay(true);
    // nodeService
    //   .deleteNode(id)
    //   .then((res) => {
    //     if (res.data.code === 0) {
    //       app.setLoadingOverlay(false);
    //       handleNodes();
    //       alert.success("Deleted.", {
    //         position: positions.TOP_LEFT,
    //       });
    //       setIsOpen(false);
    //     } else {
    //       app.setLoadingOverlay(false);
    //       alert.success("Failed.", {
    //         position: positions.TOP_LEFT,
    //       });
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     app.setLoadingOverlay(false);
    //   });
  };
  const onSubmit = (data) => {
    if (selectedNode && selectedNode.id) {
      app.setLoadingOverlay(true);
      nodeService
        .updateNode(selectedNode.id, data)
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Updated.", {
              position: positions.TOP_LEFT,
            });
            handleNodes();
            setIsOpen(false);
          } else if (res.data.status === 2) {
            alert.success("Same Node name or serial no", {
              position: positions.TOP_LEFT,
            });
            setIsOpen(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    } else {
      app.setLoadingOverlay(true);
      nodeService
        .registerNode(data)
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Successfull.", {
              position: positions.TOP_LEFT,
            });
            handleNodes();
            setIsOpen(false);
          } else if (res.data.status === 2) {
            alert.success("Same Node name or serial no", {
              position: positions.TOP_LEFT,
            });
            setIsOpen(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    }
  };
  return (
    <>
      <div className="w-full mt-1.5 pb-2 px-1 no-scrollbar">
        <div className="w-full items-center justify-between border-b border-gray-200 ">
          {tabs.map((tab, i) => {
            if ((!selectedNode && tab.mandatory) || selectedNode) {
              return (
                <button
                  className={`${
                    selectedTab === tab.key
                      ? "border-primary-700 text-primary-700"
                      : "border-transparent text-gray-500 hover:text-gray-600 hover:border-gray-300/50"
                  } ${
                    selectedNode ? "w-[calc(100%/5)]" : "w-[calc(100%/3)]"
                  }  border-b-2 pb-2.5 text-sm font-semibold`}
                  key={i}
                  id={tab.id}
                  onClick={() => {
                    setSelectedTab(tab.key);
                  }}
                >
                  {t(`${tab.title}`)}
                </button>
              );
            }
          })}
        </div>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="w-full flex flex-col gap-3 "
        >
          <div className="w-full mt-6">
            {selectedTab === "registerNode" && (
              <div className="flex flex-col gap-y-2">
                <div className="flex items-end gap-x-3 w-full">
                  <div className="flex-1">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:nodeId")}
                          value={value}
                          disabled={selectedNode ? true : false}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.nodeId ? "error" : ""}
                          errorMessage={
                            errors.nodeId ? errors.nodeId.message : ""
                          }
                        />
                      )}
                      name="nodeId"
                    />
                  </div>

                  {selectedNode && (
                    <button
                      onClick={() => {
                        setIsOpenChangeNode(true);
                        setChangeNodeId(selectedNode);
                      }}
                      type="button"
                      className="flex items-center border-[1px] border-[#D6BBFB] rounded-md h-11 min-h-11 px-5 w-fit gap-x-2 justify-center  "
                    >
                      <PencilIcon width={24} height={24} />
                      <p className="text-base text-[#6941C6] font-semibold">
                        Change Node ID
                      </p>
                    </button>
                  )}
                </div>
                <div className="w-full gap-6 flex justify-between">
                  <div className="flex flex-col w-[calc(100%/2)] gap-4">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:cardVersion")}
                          value={value}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.electronicCardVersion ? "error" : ""}
                          errorMessage={
                            errors.electronicCardVersion
                              ? errors.electronicCardVersion.message
                              : ""
                          }
                        />
                      )}
                      name="electronicCardVersion"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:cardSerialNo")}
                          value={value}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={
                            errors.electronicCardSerialNo ? "error" : ""
                          }
                          errorMessage={
                            errors.electronicCardSerialNo
                              ? errors.electronicCardSerialNo.message
                              : ""
                          }
                        />
                      )}
                      name="electronicCardSerialNo"
                    />
                    <Input
                      label={t("company:date")}
                      value={moment(selectedNode?.createdDate).format(
                        "DD-MM-YYYY HH:mm:ss"
                      )}
                      disabled={true}
                    />
                    <Input
                      label={"WiFi Mac Address"}
                      value={selectedNode?.macAddress}
                      disabled={true}
                    />
                  </div>
                  <div className="flex flex-col w-[calc(100%/2)] gap-4">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <NewDatePicker
                          placeholder={t("form:selectDate")}
                          label={t("company:productionDate")}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          value={value}
                          validate={errors.productDate ? "error" : ""}
                          errorMessage={
                            errors.productDate ? errors.productDate.message : ""
                          }
                        />
                      )}
                      name="productDate"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={"Status"}
                          items={NodeStatus}
                          onBlur={onBlur}
                          onChange={(v) => {
                            setValue("status", v);
                          }}
                          disabled={!selectedNode ? true : false}
                          value={value}
                          validate={errors.status ? "error" : ""}
                          errorMessage={
                            errors.status ? errors.status.message : ""
                          }
                        />
                      )}
                      name="status"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:nodeSnapLimit")}
                          value={value}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.nodeId ? "error" : ""}
                          errorMessage={
                            errors.nodeId ? errors.nodeId.message : ""
                          }
                        />
                      )}
                      name="nodeSnapLimit"
                    />
                    <Input
                      label={"Ethernet Mac Address"}
                      value={selectedNode?.ethernetMacAddress}
                      disabled={true}
                    />
                  </div>
                  <div className="absolute bottom-3 left-8 flex bg-white">
                    {selectedNode && (
                      <button
                        type={"button"}
                        onClick={resetDevice}
                        className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-white text-[#B42318] border border-[#FDA29B] shadow hover:bg-red-50 hover:text-red-700"
                      >
                        {"Reset Device"}
                      </button>
                    )}
                  </div>
                  <div className="absolute bg-white bottom-3 right-8 flex gap-4">
                    <button
                      type="button"
                      onClick={() => setIsOpen(false)}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                    >
                      {t("buttons:stop")}
                    </button>
                    {/* {selectedNode && (
                      <button
                        type={"button"}
                        onClick={() => deleteNode(selectedNode.id)}
                        className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                      >
                        {t("buttons:delete")}
                      </button>
                    )} */}
                    <button
                      type={"button"}
                      onClick={() => {
                        setSelectedTab("nodeInfo");
                      }}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                    >
                      {t("buttons:next")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "nodeInfo" && (
              <div className="flex flex-col gap-y-2">
                <div className="w-full gap-6 flex justify-between">
                  <div className="flex flex-col w-[calc(100%/2)] gap-4">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={t("company:softwareTest")}
                          items={Tests}
                          onBlur={onBlur}
                          onChange={(v) => {
                            setValue("softwareTest", v);
                          }}
                          value={value}
                          validate={errors.softwareTest ? "error" : ""}
                          errorMessage={
                            errors.softwareTest
                              ? errors.softwareTest.message
                              : ""
                          }
                        />
                      )}
                      name="softwareTest"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:espVersionNo")}
                          value={value}
                          disabled={true}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.espVersion ? "error" : ""}
                          errorMessage={
                            errors.espVersion ? errors.espVersion.message : ""
                          }
                        />
                      )}
                      name="espVersion"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:stmVersionNo")}
                          value={value}
                          disabled={true}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.stmVersion ? "error" : ""}
                          errorMessage={
                            errors.stmVersion ? errors.stmVersion.message : ""
                          }
                        />
                      )}
                      name="stmVersion"
                    />
                  </div>
                  <div className="flex flex-col w-[calc(100%/2)] gap-4">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Select
                          placeholder={t("company:hardwareTest")}
                          items={Tests}
                          onBlur={onBlur}
                          onChange={(v) => {
                            setValue("hardwareTest", v);
                          }}
                          value={value}
                          validate={errors.hardwareTest ? "error" : ""}
                          errorMessage={
                            errors.hardwareTest
                              ? errors.hardwareTest.message
                              : ""
                          }
                        />
                      )}
                      name="hardwareTest"
                    />
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:hardwareVersion")}
                          value={value}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.hardwareVersion ? "error" : ""}
                          errorMessage={
                            errors.hardwareVersion
                              ? errors.hardwareVersion.message
                              : ""
                          }
                        />
                      )}
                      name="hardwareVersion"
                    />
                  </div>
                  <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                    <button
                      onClick={() => setSelectedTab("registerNode")}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                    >
                      {t("buttons:prev")}
                    </button>
                    {/* {selectedNode && (
                      <button
                        type={"button"}
                        onClick={() => deleteNode(selectedNode.id)}
                        className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                      >
                        {t("buttons:delete")}
                      </button>
                    )} */}
                    <button
                      type={"button"}
                      onClick={() => {
                        if (selectedNode) {
                          setSelectedTab("liveStatus");
                        } else {
                          setSelectedTab("faultAndMaintanence");
                        }
                      }}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                    >
                      {t("buttons:next")}
                    </button>
                  </div>
                </div>
                {selectedNode && (
                  <div className="mr-2 mt-2 w-max self-end">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <ImageUploader
                          title={"Update Software"}
                          subTitle={t("fileInput:removePicture")}
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                      )}
                      name="softwareUpdateFile"
                    />
                  </div>
                )}
              </div>
            )}
            {selectedTab === "liveStatus" && (
              <div className="flex flex-col gap-y-2">
                <div className="w-full gap-6 flex justify-between">
                  <div className="flex flex-col w-[calc(100%/2)] gap-4">
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:deviceState")}
                          value={value}
                          disabled={true}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.deviceState ? "error" : ""}
                          errorMessage={
                            errors.deviceState ? errors.deviceState.message : ""
                          }
                        />
                      )}
                      name="deviceState"
                    />
                    <div className="flex w-full flex-row items-end gap-3 justify-between">
                      <div className="w-[88%]">
                        <Controller
                          control={control}
                          render={({ field: { onChange, onBlur, value } }) => (
                            <Input
                              label={t("company:ipAddress")}
                              value={value}
                              disabled={true}
                              onBlur={onBlur}
                              onChange={onChange}
                              validate={errors.ipAddress ? "error" : ""}
                              errorMessage={
                                errors.ipAddress ? errors.ipAddress.message : ""
                              }
                            />
                          )}
                          name="ipAddress"
                        />
                      </div>
                      <a
                        target={"_blank"}
                        href={`http://${selectedNode?.ipAddress}`}
                      >
                        <div className="h-11 w-11 shadow rounded-lg border group border-gray-300 bg-gray-50 hover:text-primary-700 hover:bg-primary-50 flex items-center justify-center text-gray-600">
                          <LeftArrow className="rotate-[140deg] group-hover:rotate-180 transition-[400ms]" />
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col w-[calc(100%/2)] gap-4">
                    {selectedNode && (
                      <Controller
                        control={control}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Select
                            placeholder={t("dashboard:company")}
                            items={RealCompanyOptions}
                            onBlur={onBlur}
                            onChange={(v) => {
                              setValue("company", v);
                            }}
                            value={value}
                            validate={errors.company ? "error" : ""}
                            errorMessage={
                              errors.company ? errors.company.message : ""
                            }
                          />
                        )}
                        name="company"
                      />
                    )}
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={"RSSI"}
                          value={value}
                          disabled={true}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.rrsi ? "error" : ""}
                          errorMessage={errors.rrsi ? errors.rrsi.message : ""}
                        />
                      )}
                      name="rssi"
                    />
                  </div>
                  <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                    <button
                      onClick={() => setSelectedTab("nodeInfo")}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                    >
                      {t("buttons:prev")}
                    </button>
                    {/* {selectedNode && (
                      <button
                        type={"button"}
                        onClick={() => deleteNode(selectedNode.id)}
                        className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                      >
                        {t("buttons:delete")}
                      </button>
                    )} */}
                    <button
                      type={"button"}
                      onClick={() => {
                        setSelectedTab("faultAndMaintanence");
                      }}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                    >
                      {t("buttons:next")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "faultAndMaintanence" && (
              <div className="flex flex-col gap-y-2 w-full h-full">
                <div className="w-full h-full gap-6 flex justify-between">
                  <div className="flex w-full h-full flex-col gap-4">
                    <div className="w-full h-[290px] overflow-y-scroll rounded-lg border border-gray-300 flex flex-col gap-2 shadow-sm">
                      {maintenance?.length ? (
                        <div className="flex flex-col text-sm font-medium">
                          {maintenance?.map((maintenance, i) => {
                            return (
                              <div
                                key={i}
                                onClick={() => {
                                  setSelectedMaintenance(maintenance);
                                }}
                                className="bg-gray-50 hover:bg-gray-100 p-3 border border-gray-200 w-full flex items-center justify-between cursor-pointer"
                              >
                                <div
                                  onClick={() => {
                                    setSelectedMaintenance(maintenance);
                                    setOpenMaintenance(true);
                                  }}
                                  className="w-[99%]"
                                >
                                  {`${new Date(
                                    maintenance?.faultRepairDate
                                  ).toLocaleDateString("tr-TR", {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })} tarihli bakım (${
                                    maintenance?.creator?.name
                                  } ${maintenance?.creator?.lastName})`}
                                </div>
                                {/* <div
                                  onClick={() => {
                                    setIsAlert(true);
                                  }}
                                  className="cursor-pointer text-red-600 bg-red-100 p-2 rounded-lg hover:bg-red-200"
                                >
                                  <BsTrash />
                                </div> */}
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <span className="p-3 text-sm font-medium text-validate-error">
                          {t("company:previousRecord")}
                        </span>
                      )}
                    </div>
                    <div className="self-end flex gap-4 ">
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedMaintenance(null);
                          setOpenMaintenance(true);
                        }}
                        className="h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-green-600 shadow hover:bg-green-500"
                      >
                        Create Maintenance
                      </button>
                    </div>
                  </div>
                  <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                    <button
                      onClick={() => {
                        if (selectedNode) {
                          setSelectedTab("liveStatus");
                        } else {
                          setSelectedTab("nodeInfo");
                        }
                      }}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                    >
                      {t("buttons:prev")}
                    </button>
                    {/* {selectedNode && (
                      <button
                        type={"button"}
                        onClick={() => deleteNode(selectedNode.id)}
                        className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                      >
                        {t("buttons:delete")}
                      </button>
                    )} */}
                    <button
                      type={selectedNode ? "button" : "submit"}
                      onClick={() => {
                        if (selectedNode) {
                          setSelectedTab("logRecord");
                        } else {
                        }
                      }}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                    >
                      {selectedNode ? t("buttons:next") : t("buttons:save")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === "logRecord" && (
              <div>
                {selectedNode && (
                  <div
                    className={`${
                      children ? "w-full my-3 min-h-[300px]" : "hidden"
                    }`}
                  >
                    {children}
                  </div>
                )}
                <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => setSelectedTab("faultAndMaintanence")}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                  >
                    {t("buttons:prev")}
                  </button>
                  {/* {selectedNode && (
                    <button
                      type={"button"}
                      onClick={() => deleteNode(selectedNode.id)}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                    >
                      {t("buttons:delete")}
                    </button>
                  )} */}
                  <button
                    type="submit"
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                  >
                    {t("buttons:save")}
                  </button>
                </div>
              </div>
            )}
            {selectedTab === "software" && (
              <div className="max-h-[20px]">
                {selectedNode && (
                  <div className={`w-full relative`}>
                    <div className="w-full justify-between flex h-11 bg-gray-50 border border-gray-300">
                      {tableFilter.map((item, i) => (
                        <li
                          key={item.value}
                          className={`h-full w-full border-r border-gray-300 min-w-[240px] flex items-center justify-center text-xs text-gray-500 cursor-pointer hover:bg-gray-100`}
                        >
                          {t(`software:${item.label}`)}
                        </li>
                      ))}
                      <li
                        className={`h-full w-[240px] flex items-center justify-center text-gray-500 cursor-pointer hover:bg-gray-100`}
                      />
                    </div>
                    <div className="overflow-y-scroll w-full flex flex-col justify-between scrollbar-hide max-h-[320px]">
                      {mockData.map((role, i) => (
                        <ul
                          className="w-full min-h-[72px] flex items-center border-b border-x border-gray-200 group"
                          key={i}
                        >
                          {tableFilter.map((d, index) => {
                            return (
                              <li
                                key={index}
                                onClick={() => {}}
                                className="h-full min-h-[72px] w-full border-r border-gray-300 select-none min-w-[240px] flex items-center justify-center text-sm text-secondary-600 group-hover:bg-slate-50"
                              >
                                {role[d.value] && role[d.value]}
                              </li>
                            );
                          })}
                          <li
                            onClick={() => {}}
                            className="h-full cursor-pointer min-h-[72px] w-[240px] select-none flex items-center justify-center text-secondary-600 group-hover:bg-slate-50"
                          >
                            <Upload width={24} height={24} />
                          </li>
                        </ul>
                      ))}
                    </div>
                  </div>
                )}
                <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
                  <button
                    type="button"
                    onClick={() => setSelectedTab("faultAndMaintanence")}
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
                  >
                    {t("buttons:prev")}
                  </button>
                  {/* {selectedNode && (
                    <button
                      type={"button"}
                      onClick={() => deleteNode(selectedNode.id)}
                      className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
                    >
                      {t("buttons:delete")}
                    </button>
                  )} */}
                  <button
                    type="submit"
                    className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
                  >
                    {t("buttons:save")}
                  </button>
                </div>
              </div>
            )}
          </div>
        </form>
      </div>
      <AdminModal
        modalIsOpen={openMaintenance}
        closeModal={() => {
          setSelectedMaintenance(null);
          setOpenMaintenance(false);
        }}
        height={"650px"}
        title={selectedMaintenance ? "Edit Maintenance" : "Create Maintenance"}
        children={
          <CreateMaintenance
            setSelectedMaintenance={setSelectedMaintenance}
            selectedMaintenance={selectedMaintenance}
            selectedNode={selectedNode}
            setOpenMaintenance={setOpenMaintenance}
            handleMaintenance={handleMaintenance}
          />
        }
      />

      <AdminModal
        modalIsOpen={isOpenChangeNode}
        closeModal={() => {
          setIsOpenChangeNode(false);
        }}
        height={"650px"}
        title={"Change Node ID"}
        children={
          <ChangeNodeModal
            changeNode={changeNodeId}
            setIsOpenChangeNode={setIsOpenChangeNode}
            setIsOpen={setIsOpen}
            handleNodes={handleNodes}
            companyNodes={
              companyNodes?.filter((x) => x.id == changeNodeId?.company?.id)[0]
                ?.nodes
            }
          />
        }
      />
      <AlertModal
        isAlert={isAlert}
        setIsAlert={setIsAlert}
        text={"Secili bakim kaydini silmek istediginize emin misiniz?"}
        icon={<BsTrash className="text-2xl" />}
        header={"Bakim Kaydini Sil"}
        label={"Sil"}
        applyFunction={() => deleteMaintenance()}
      />
    </>
  );
};

export default AddNewNod;
