import React, { useEffect } from "react";
import Input from "./Inputs/Input";
import { useForm, Controller, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useStore } from "../hooks/useStores";
import { useAlert, positions } from "react-alert";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import nodeService from "../services/node.service";
import NewDatePicker from "./Inputs/DatePicker/DatePicker";
const CreateMaintenance = ({
  selectedMaintenance,
  setSelectedMaintenance,
  selectedNode,
  setOpenMaintenance,
  handleMaintenance,
}) => {
  const { app } = useStore();
  const alert = useAlert();
  const { t } = useTranslation();
  const schema = yup.object({
    fault: yup
      .string()
      .nullable()
      .notRequired(`${t("validation:empty")}`),
    faultRepairDate: yup
      .date()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:date")}`),
    faultDescription: yup.string().notRequired(`${t("validation:empty")}`),
    faultNotificationDate: yup
      .date()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:date")}`),
    transactionsMade: yup.string().notRequired(`${t("validation:empty")}`),
    changeItems: yup.string().notRequired(`${t("validation:empty")}`),
    sendingTime: yup
      .date()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:date")}`),
    sendingMethod: yup.string().notRequired(`${t("validation:empty")}`),
    faultReporter: yup.string().notRequired(`${t("validation:empty")}`),
    faultApprover: yup.string().notRequired(`${t("validation:empty")}`),
    faultRepairman: yup.string().notRequired(`${t("validation:empty")}`),
    description: yup.string().notRequired(`${t("validation:empty")}`),
    finalStatus: yup.boolean(),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      fault: "",
      faultDescription: "",
      faultNotificationDate: new Date(),
      faultRepairDate: new Date(),
      transactionsMade: "",
      changeItems: "",
      sendingMethod: "",
      sendingTime: new Date(),
      faultRepairman: "",
      faultApprover: "",
      faultReporter: "",
      description: "",
      finalStatus: false,
    },
  });
  const status = useWatch({
    control: control,
    name: "finalStatus",
  });
  useEffect(() => {
    if (selectedMaintenance) {
      setValue("fault", selectedMaintenance?.fault);
      setValue("faultDescription", selectedMaintenance?.faultDescription);
      setValue(
        "faultNotificationDate",
        new Date(selectedMaintenance?.faultNotificationDate)
      );
      setValue(
        "faultRepairDate",
        new Date(selectedMaintenance?.faultRepairDate)
      );
      setValue("transactionsMade", selectedMaintenance?.transactionsMade);
      setValue("changeItems", selectedMaintenance?.changeItems);
      setValue("sendingMethod", selectedMaintenance?.sendingMethod);
      setValue("sendingTime", new Date(selectedMaintenance?.sendingTime));
      setValue("faultRepairman", selectedMaintenance?.faultRepairman);
      setValue("faultApprover", selectedMaintenance?.faultApprover);
      setValue("faultReporter", selectedMaintenance?.faultReporter);
      setValue("description", selectedMaintenance?.description);
      setValue("finalStatus", selectedMaintenance?.finalStatus);
    } else {
    }
  }, [selectedMaintenance, setSelectedMaintenance]);
  const onSubmit = (data) => {
    if (selectedMaintenance && selectedMaintenance.id) {
      app.setLoadingOverlay(true);
      nodeService
        .updateMaintenance(selectedMaintenance.id, data)
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Maintenance Updated.", {
              position: positions.TOP_LEFT,
            });
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
          handleMaintenance();
          setOpenMaintenance(false);
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    } else {
      app.setLoadingOverlay(true);
      nodeService
        .createMaintenance(selectedNode.id, data)
        .then((res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Maintenance Created.", {
              position: positions.TOP_LEFT,
            });
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
          handleMaintenance();
          setOpenMaintenance(false);
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    }
  };
  return (
    <div className="w-full mt-1.5 pb-2 px-1 no-scrollbar">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="w-full flex flex-col gap-3 "
      >
        <div className="w-full mt-6">
          <div className="flex flex-col gap-y-4 w-full h-full">
            <div className="w-full h-full gap-6 flex justify-between">
              <>
                <div className={`flex flex-col gap-6 ${"w-[calc(100%/3)]"}`}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NewDatePicker
                        placeholder={t("form:selectDate")}
                        label={t("company:faultRepairDate")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.faultRepairDate ? "error" : ""}
                        errorMessage={
                          errors.faultRepairDate
                            ? errors.faultRepairDate.message
                            : ""
                        }
                      />
                    )}
                    name="faultRepairDate"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("company:faultDescription")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.faultDescription ? "error" : ""}
                        errorMessage={
                          errors.faultDescription
                            ? errors.faultDescription.message
                            : ""
                        }
                      />
                    )}
                    name="faultDescription"
                  />
                  <div>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:sendingMethod")}
                          value={value}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.sendingMethod ? "error" : ""}
                          errorMessage={
                            errors.sendingMethod
                              ? errors.sendingMethod.message
                              : ""
                          }
                        />
                      )}
                      name="sendingMethod"
                    />
                  </div>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("company:fault")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.fault ? "error" : ""}
                        errorMessage={errors.fault ? errors.fault.message : ""}
                        textarea={true}
                      />
                    )}
                    name="fault"
                  />
                </div>
                <div className={`flex flex-col gap-6 ${"w-[calc(100%/3)]"}`}>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <NewDatePicker
                        placeholder={t("form:selectDate")}
                        label={t("company:sendingDate")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.sendingTime ? "error" : ""}
                        errorMessage={
                          errors.sendingTime ? errors.sendingTime.message : ""
                        }
                      />
                    )}
                    name="sendingTime"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("company:transactionsMade")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.transactionsMade ? "error" : ""}
                        errorMessage={
                          errors.transactionsMade
                            ? errors.transactionsMade.message
                            : ""
                        }
                      />
                    )}
                    name="transactionsMade"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("company:replacedModule")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.changeItems ? "error" : ""}
                        errorMessage={
                          errors.changeItems ? errors.changeItems.message : ""
                        }
                      />
                    )}
                    name="changeItems"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("company:description")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.description ? "error" : ""}
                        errorMessage={
                          errors.description ? errors.description.message : ""
                        }
                        textarea={true}
                      />
                    )}
                    name="description"
                  />
                </div>
                <div className={`flex flex-col gap-6 ${"w-[calc(100%/3)]"}`}>
                  <div>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <NewDatePicker
                          placeholder={t("form:selectDate")}
                          label={t("company:faultNotification")}
                          value={value}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.faultNotificationDate ? "error" : ""}
                          errorMessage={
                            errors.faultNotificationDate
                              ? errors.faultNotificationDate.message
                              : ""
                          }
                        />
                      )}
                      name="faultNotificationDate"
                    />
                  </div>
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("company:faultReporter")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.faultReporter ? "error" : ""}
                        errorMessage={
                          errors.faultReporter
                            ? errors.faultReporter.message
                            : ""
                        }
                      />
                    )}
                    name="faultReporter"
                  />
                  <Controller
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        label={t("company:faultRepairman")}
                        value={value}
                        disabled={false}
                        onBlur={onBlur}
                        onChange={onChange}
                        validate={errors.faultRepairman ? "error" : ""}
                        errorMessage={
                          errors.faultRepairman
                            ? errors.faultRepairman.message
                            : ""
                        }
                      />
                    )}
                    name="faultRepairman"
                  />
                  <div>
                    <Controller
                      control={control}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          label={t("company:faultApprover")}
                          value={value}
                          disabled={false}
                          onBlur={onBlur}
                          onChange={onChange}
                          validate={errors.faultApprover ? "error" : ""}
                          errorMessage={
                            errors.faultApprover
                              ? errors.faultApprover.message
                              : ""
                          }
                        />
                      )}
                      name="faultApprover"
                    />
                  </div>
                  <div className="flex justify-between font-medium">
                    <div className="text-gray-700 text-sm font-medium leading-5 flex flex-col relative">
                      Final Status
                    </div>
                    <div className="flex flex- gap-x-2">
                      <div
                        onClick={() => {
                          setValue("finalStatus", true);
                        }}
                        className="w-full text-sm font-medium flex items-center px-2 rounded-lg py-1 gap-x-4 text-white bg-green-600"
                      >
                        <input
                          name="finalStatusTrue"
                          id={`radio-true`}
                          type="radio"
                          checked={status == true ? true : false}
                          className={`ring-0 focus:ring-0 flex items-center justify-center`}
                        />
                        <label className="w-full flex items-center justify-center text-center">
                          True
                        </label>
                      </div>
                      <div
                        onClick={() => {
                          setValue("finalStatus", false);
                        }}
                        className="w-full text-sm font-medium flex items-center px-2 rounded-lg py-1 gap-x-4 text-white bg-red-600"
                      >
                        <input
                          id={`radio-false`}
                          type="radio"
                          checked={status == false ? true : false}
                          className={`ring-0 focus:ring-0 flex items-center justify-center`}
                        />
                        <div className="w-full flex items-center justify-center text-center">
                          False
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
          <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
            <button
              type="button"
              onClick={() => {
                setOpenMaintenance(false);
              }}
              className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
            >
              {t("buttons:stop")}
            </button>
            {!selectedMaintenance && (
              <button
                type="submit"
                className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
              >
                {t("buttons:save")}
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateMaintenance;
