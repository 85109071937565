import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { companyService } from "../../services";
import { useStore } from "../../hooks/useStores";
import { useAlert, positions } from "react-alert";
import { useForm, Controller, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AttachedCompaniesList, GeneralTab } from "./components";
const AddNewIndustry = ({
  setIsOpen,
  setSelectedIndustry,
  selectedIndustry,
  handleIndustries,
}) => {
  const { app } = useStore();
  const alert = useAlert();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState("general");
  const tabs = [
    { key: "general", title: "company:general", mandatory: true },
    { key: "companies", title: "company:companyList", mandatory: false },
  ];
  const schema = yup.object({
    tr: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    en: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      tr: "",
      en: "",
    },
  });
  useEffect(() => {
    if (selectedIndustry) {
      setValue("tr", selectedIndustry.name.tr);
      setValue("en", selectedIndustry.name.en);
    } else {
    }
  }, [selectedIndustry, setSelectedIndustry]);
  const tr = useWatch({
    control: control,
    name: "tr",
  });
  const en = useWatch({
    control: control,
    name: "en",
  });
  const onSubmit = async (data) => {
    if (selectedIndustry) {
      app.setLoadingOverlay(true);
      await companyService
        .updateIndustry(selectedIndustry.id, {
          tr: data.tr,
          en: data.en,
        })
        .then(async (res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Updated.", {
              position: positions.TOP_LEFT,
            });
            await handleIndustries();
            setIsOpen(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    } else {
      app.setLoadingOverlay(true);
      await companyService
        .addNewIndustry({
          tr: data.tr,
          en: data.en,
        })
        .then(async (res) => {
          if (res.data.status === 1) {
            app.setLoadingOverlay(false);
            alert.success("Successfull.", {
              position: positions.TOP_LEFT,
            });
            await handleIndustries();
            setIsOpen(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    }
  };
  return (
    <div className="w-full flex flex-col">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-full items-center justify-between border-b border-gray-200 ">
          {tabs.map((tab, i) => {
            if ((!selectedIndustry && tab.mandatory) || selectedIndustry) {
              return (
                <button
                  type="button"
                  className={`${
                    selectedTab === tab.key
                      ? "border-primary-700 text-primary-700"
                      : "border-transparent text-gray-500 hover:text-gray-600 hover:border-gray-300/50"
                  } ${
                    selectedIndustry ? "w-[calc(100%/2)]" : "w-[calc(100%/1)]"
                  } border-b-2 pb-2.5 text-sm font-semibold`}
                  key={i}
                  id={tab.id}
                  onClick={() => {
                    setSelectedTab(tab.key);
                  }}
                >
                  {t(`${tab.title}`)}
                </button>
              );
            }
          })}
        </div>
        <div className="w-full mt-6 overflow-y-auto scrollbar-hide">
          {selectedTab === "general" && (
            <GeneralTab control={control} errors={errors} />
          )}
          {selectedTab === "companies" && (
            <AttachedCompaniesList
              t={t}
              selectedIndustry={selectedIndustry}
              control={control}
              errors={errors}
            />
          )}
        </div>
        <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
          {selectedIndustry && (
            <button
              type="button"
              onClick={() => setIsOpen(false)}
              className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100"
            >
              {t("buttons:delete")}
            </button>
          )}
          <button
            type="button"
            onClick={() => setIsOpen(false)}
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
          >
            {t("buttons:stop")}
          </button>
          <button
            type={"submit"}
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
          >
            {t("buttons:save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNewIndustry;
