import { t } from "i18next";
const nodeListSearchTypes = [
  "deviceId",
  "electronicCardSerialNo",
  "electronicCardVersion",
  "espVersion",
  "name",
  "stmVersion",
  "metadata",
];
const userTableFilter = [
  {
    label: "avatar",
    value: "avatar",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "name",
    value: "name",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "email",
    value: "email",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
];
const nodeTableFilter = [
  {
    label: "Node ID",
    value: "deviceId",
    sortable: true,
    ascd: true,
  },
  {
    label: "Machine Brand",
    value: "brand",
    sortable: true,
    ascd: true,
  },
  {
    label: "Serial No",
    value: "electronicCardSerialNo",
    sortable: true,
    ascd: true,
  },
  {
    label: "Device State",
    value: "deviceState",
    sortable: true,
    ascd: true,
  },
  // {
  //   label: "Status",
  //   value: "status",
  //   sortable: true,
  //   ascd: true,
  // },
  {
    label: "Date",
    value: "createdDate",
    sortable: true,
    ascd: true,
  },
  {
    label: "Updated Date",
    value: "updatedDate",
    sortable: true,
    ascd: true,
  },
  {
    label: "STM Version",
    value: "stmVersion",
    sortable: true,
    ascd: true,
  },
  {
    label: "Esp Version",
    value: "espVersion",
    sortable: true,
    ascd: true,
  },
  {
    label: "Update",
    value: "update",
    sortable: true,
    ascd: true,
  },
];
const newNodeTableFilter = [
  {
    label: "Company",
    value: "company",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Node ID",
    value: "deviceId",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Machine Brand",
    value: "brand",
    sortable: true,
    canFilter: false,
    ascd: true,
  },
  {
    label: "Serial No",
    value: "electronicCardSerialNo",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Device State",
    value: "deviceState",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  // {
  //   label: "Status",
  //   value: "status",
  //   sortable: true,
  //   ascd: true,
  // },
  {
    label: "Date",
    value: "createdDate",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Updated Date",
    value: "updatedDate",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "STM Version",
    value: "stmVersion",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Esp Version",
    value: "espVersion",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Update",
    value: "update",
    sortable: true,
    canFilter: false,
    ascd: true,
  },
];

const testNodeTable = [
  {
    label: "Node ID",
    value: "deviceId",
    sortable: true,
    ascd: true,
  },
  {
    label: "Machine Brand",
    value: "brand",
    sortable: true,
    ascd: true,
  },
  {
    label: "Device State",
    value: "deviceState",
    sortable: true,
    ascd: true,
  },
  {
    label: "IP Adress",
    value: "ipAddress",
    sortable: true,
    ascd: true,
  },
  {
    label: "Status",
    value: "status",
    sortable: true,
    ascd: true,
  },
  {
    label: "STM Version",
    value: "stmVersion",
    sortable: true,
    ascd: true,
  },
  {
    label: "Esp Version",
    value: "espVersion",
    sortable: true,
    ascd: true,
  },
  {
    label: "Test",
    value: "test",
    sortable: true,
    ascd: true,
  },
];
const softwaresTableFilter = [
  {
    label: "softwareName",
    value: "name",
  },
  {
    label: "softwareVersion",
    value: "version",
  },
  {
    label: "softwareDate",
    value: "createdDate",
  },
  {
    label: "softwareArchitectureApproval",
    value: "softwareArchitectureApproval",
  },
  {
    label: "testEngineerApproval",
    value: "testEngineerApproval",
  },
  {
    label: "fieldTestApproval",
    value: "fieldTestApproval",
  },
];
const embeddedTableFilter = [
  {
    label: "ID",
    value: "code",
  },
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Sensors Included",
    value: "sensors_included",
  },
  {
    label: "Description",
    value: "description",
  },
];
const updateReportFilter = [
  {
    label: "Node ID",
    value: "nodeId",
  },
  {
    label: "Company",
    value: "company",
  },
  {
    label: "Machine Brand",
    value: "brand",
  },
  {
    label: "Software Type",
    value: "type",
  },
  {
    label: "Old Version",
    value: "oldVersion",
  },
  {
    label: "New Version",
    value: "newVersion",
  },
  {
    label: "Update Status",
    value: "updateStatus",
  },
];
const timezones = [
  {
    name: "(GMT-11:00) Midway Island",
    timezone: "Pacific/Midway",
  },
  {
    name: "(GMT-11:00) Samoa",
    timezone: "US/Samoa",
  },
  {
    name: "(GMT-10:00) Hawaii",
    timezone: "US/Hawaii",
  },
  {
    name: "(GMT-09:00) Alaska",
    timezone: "US/Alaska",
  },
  {
    name: "(GMT-08:00) Pacific Time (US & Canada)",
    timezone: "US/Pacific",
  },
  {
    name: "(GMT-08:00) Tijuana",
    timezone: "America/Tijuana",
  },
  {
    name: "(GMT-07:00) Arizona",
    timezone: "US/Arizona",
  },
  {
    name: "(GMT-07:00) Mountain Time (US & Canada)",
    timezone: "US/Mountain",
  },
  {
    name: "(GMT-07:00) Chihuahua",
    timezone: "America/Chihuahua",
  },
  {
    name: "(GMT-07:00) Mazatlan",
    timezone: "America/Mazatlan",
  },
  {
    name: "(GMT-06:00) Mexico City",
    timezone: "America/Mexico_City",
  },
  {
    name: "(GMT-06:00) Monterrey",
    timezone: "America/Monterrey",
  },
  {
    name: "(GMT-06:00) Saskatchewan",
    timezone: "Canada/Saskatchewan",
  },
  {
    name: "(GMT-06:00) Central Time (US & Canada)",
    timezone: "US/Central",
  },
  {
    name: "(GMT-05:00) Eastern Time (US & Canada)",
    timezone: "US/Eastern",
  },
  {
    name: "(GMT-05:00) Indiana (East)",
    timezone: "US/East-Indiana",
  },
  {
    name: "(GMT-05:00) Bogota",
    timezone: "America/Bogota",
  },
  {
    name: "(GMT-05:00) Lima",
    timezone: "America/Lima",
  },
  {
    name: "(GMT-04:30) Caracas",
    timezone: "America/Caracas",
  },
  {
    name: "(GMT-04:00) Atlantic Time (Canada)",
    timezone: "Canada/Atlantic",
  },
  {
    name: "(GMT-04:00) La Paz",
    timezone: "America/La_Paz",
  },
  {
    name: "(GMT-04:00) Santiago",
    timezone: "America/Santiago",
  },
  {
    name: "(GMT-03:30) Newfoundland",
    timezone: "Canada/Newfoundland",
  },
  {
    name: "(GMT-03:00) Buenos Aires",
    timezone: "America/Buenos_Aires",
  },
  {
    name: "(GMT-03:00) Greenland",
    timezone: "Greenland",
  },
  {
    name: "(GMT-02:00) Stanley",
    timezone: "Atlantic/Stanley",
  },
  {
    name: "(GMT-01:00) Azores",
    timezone: "Atlantic/Azores",
  },
  {
    name: "(GMT-01:00) Cape Verde Is.",
    timezone: "Atlantic/Cape_Verde",
  },
  {
    name: "(GMT) Casablanca",
    timezone: "Africa/Casablanca",
  },
  {
    name: "(GMT) Dublin",
    timezone: "Europe/Dublin",
  },
  {
    name: "(GMT) Lisbon",
    timezone: "Europe/Lisbon",
  },
  {
    name: "(GMT) London",
    timezone: "Europe/London",
  },
  {
    name: "(GMT) Monrovia",
    timezone: "Africa/Monrovia",
  },
  {
    name: "(GMT+01:00) Amsterdam",
    timezone: "Europe/Amsterdam",
  },
  {
    name: "(GMT+01:00) Belgrade",
    timezone: "Europe/Belgrade",
  },
  {
    name: "(GMT+01:00) Berlin",
    timezone: "Europe/Berlin",
  },
  {
    name: "(GMT+01:00) Bratislava",
    timezone: "Europe/Bratislava",
  },
  {
    name: "(GMT+01:00) Brussels",
    timezone: "Europe/Brussels",
  },
  {
    name: "(GMT+01:00) Budapest",
    timezone: "Europe/Budapest",
  },
  {
    name: "(GMT+01:00) Copenhagen",
    timezone: "Europe/Copenhagen",
  },
  {
    name: "(GMT+01:00) Ljubljana",
    timezone: "Europe/Ljubljana",
  },
  {
    name: "(GMT+01:00) Madrid",
    timezone: "Europe/Madrid",
  },
  {
    name: "(GMT+01:00) Paris",
    timezone: "Europe/Paris",
  },
  {
    name: "(GMT+01:00) Prague",
    timezone: "Europe/Prague",
  },
  {
    name: "(GMT+01:00) Rome",
    timezone: "Europe/Rome",
  },
  {
    name: "(GMT+01:00) Sarajevo",
    timezone: "Europe/Sarajevo",
  },
  {
    name: "(GMT+01:00) Skopje",
    timezone: "Europe/Skopje",
  },
  {
    name: "(GMT+01:00) Stockholm",
    timezone: "Europe/Stockholm",
  },
  {
    name: "(GMT+01:00) Vienna",
    timezone: "Europe/Vienna",
  },
  {
    name: "(GMT+01:00) Warsaw",
    timezone: "Europe/Warsaw",
  },
  {
    name: "(GMT+01:00) Zagreb",
    timezone: "Europe/Zagreb",
  },
  {
    name: "(GMT+02:00) Athens",
    timezone: "Europe/Athens",
  },
  {
    name: "(GMT+02:00) Bucharest",
    timezone: "Europe/Bucharest",
  },
  {
    name: "(GMT+02:00) Cairo",
    timezone: "Africa/Cairo",
  },
  {
    name: "(GMT+02:00) Harare",
    timezone: "Africa/Harare",
  },
  {
    name: "(GMT+02:00) Helsinki",
    timezone: "Europe/Helsinki",
  },
  {
    name: "(GMT+03:00) Istanbul",
    timezone: "Europe/Istanbul",
  },
  {
    name: "(GMT+02:00) Jerusalem",
    timezone: "Asia/Jerusalem",
  },
  {
    name: "(GMT+02:00) Kyiv",
    timezone: "Europe/Kiev",
  },
  {
    name: "(GMT+02:00) Minsk",
    timezone: "Europe/Minsk",
  },
  {
    name: "(GMT+02:00) Riga",
    timezone: "Europe/Riga",
  },
  {
    name: "(GMT+02:00) Sofia",
    timezone: "Europe/Sofia",
  },
  {
    name: "(GMT+02:00) Tallinn",
    timezone: "Europe/Tallinn",
  },
  {
    name: "(GMT+02:00) Vilnius",
    timezone: "Europe/Vilnius",
  },
  {
    name: "(GMT+03:00) Baghdad",
    timezone: "Asia/Baghdad",
  },
  {
    name: "(GMT+03:00) Kuwait",
    timezone: "Asia/Kuwait",
  },
  {
    name: "(GMT+03:00) Nairobi",
    timezone: "Africa/Nairobi",
  },
  {
    name: "(GMT+03:00) Riyadh",
    timezone: "Asia/Riyadh",
  },
  {
    name: "(GMT+03:00) Moscow",
    timezone: "Europe/Moscow",
  },
  {
    name: "(GMT+03:30) Tehran",
    timezone: "Asia/Tehran",
  },
  {
    name: "(GMT+04:00) Baku",
    timezone: "Asia/Baku",
  },
  {
    name: "(GMT+04:00) Volgograd",
    timezone: "Europe/Volgograd",
  },
  {
    name: "(GMT+04:00) Muscat",
    timezone: "Asia/Muscat",
  },
  {
    name: "(GMT+04:00) Tbilisi",
    timezone: "Asia/Tbilisi",
  },
  {
    name: "(GMT+04:00) Yerevan",
    timezone: "Asia/Yerevan",
  },
  {
    name: "(GMT+04:30) Kabul",
    timezone: "Asia/Kabul",
  },
  {
    name: "(GMT+05:00) Karachi",
    timezone: "Asia/Karachi",
  },
  {
    name: "(GMT+05:00) Tashkent",
    timezone: "Asia/Tashkent",
  },
  {
    name: "(GMT+05:30) Kolkata",
    timezone: "Asia/Kolkata",
  },
  {
    name: "(GMT+05:45) Kathmandu",
    timezone: "Asia/Kathmandu",
  },
  {
    name: "(GMT+06:00) Ekaterinburg",
    timezone: "Asia/Yekaterinburg",
  },
  {
    name: "(GMT+06:00) Almaty",
    timezone: "Asia/Almaty",
  },
  {
    name: "(GMT+06:00) Dhaka",
    timezone: "Asia/Dhaka",
  },
  {
    name: "(GMT+07:00) Novosibirsk",
    timezone: "Asia/Novosibirsk",
  },
  {
    name: "(GMT+07:00) Bangkok",
    timezone: "Asia/Bangkok",
  },
  {
    name: "(GMT+07:00) Jakarta",
    timezone: "Asia/Jakarta",
  },
  {
    name: "(GMT+08:00) Krasnoyarsk",
    timezone: "Asia/Krasnoyarsk",
  },
  {
    name: "(GMT+08:00) Chongqing",
    timezone: "Asia/Chongqing",
  },
  {
    name: "(GMT+08:00) Hong Kong",
    timezone: "Asia/Hong_Kong",
  },
  {
    name: "(GMT+08:00) Kuala Lumpur",
    timezone: "Asia/Kuala_Lumpur",
  },
  {
    name: "(GMT+08:00) Perth",
    timezone: "Australia/Perth",
  },
  {
    name: "(GMT+08:00) Singapore",
    timezone: "Asia/Singapore",
  },
  {
    name: "(GMT+08:00) Taipei",
    timezone: "Asia/Taipei",
  },
  {
    name: "(GMT+08:00) Ulaan Bataar",
    timezone: "Asia/Ulaanbaatar",
  },
  {
    name: "(GMT+08:00) Urumqi",
    timezone: "Asia/Urumqi",
  },
  {
    name: "(GMT+09:00) Irkutsk",
    timezone: "Asia/Irkutsk",
  },
  {
    name: "(GMT+09:00) Seoul",
    timezone: "Asia/Seoul",
  },
  {
    name: "(GMT+09:00) Tokyo",
    timezone: "Asia/Tokyo",
  },
  {
    name: "(GMT+09:30) Adelaide",
    timezone: "Australia/Adelaide",
  },
  {
    name: "(GMT+09:30) Darwin",
    timezone: "Australia/Darwin",
  },
  {
    name: "(GMT+10:00) Yakutsk",
    timezone: "Asia/Yakutsk",
  },
  {
    name: "(GMT+10:00) Brisbane",
    timezone: "Australia/Brisbane",
  },
  {
    name: "(GMT+10:00) Canberra",
    timezone: "Australia/Canberra",
  },
  {
    name: "(GMT+10:00) Guam",
    timezone: "Pacific/Guam",
  },
  {
    name: "(GMT+10:00) Hobart",
    timezone: "Australia/Hobart",
  },
  {
    name: "(GMT+10:00) Melbourne",
    timezone: "Australia/Melbourne",
  },
  {
    name: "(GMT+10:00) Port Moresby",
    timezone: "Pacific/Port_Moresby",
  },
  {
    name: "(GMT+10:00) Sydney",
    timezone: "Australia/Sydney",
  },
  {
    name: "(GMT+11:00) Vladivostok",
    timezone: "Asia/Vladivostok",
  },
  {
    name: "(GMT+12:00) Magadan",
    timezone: "Asia/Magadan",
  },
  {
    name: "(GMT+12:00) Auckland",
    timezone: "Pacific/Auckland",
  },
  {
    name: "(GMT+12:00) Fiji",
    timezone: "Pacific/Fiji",
  },
];
const countries = [
  { name: "Afghanistan", code: "AF" },
  { name: "Åland Islands", code: "AX" },
  { name: "Albania", code: "AL" },
  { name: "Algeria", code: "DZ" },
  { name: "American Samoa", code: "AS" },
  { name: "AndorrA", code: "AD" },
  { name: "Angola", code: "AO" },
  { name: "Anguilla", code: "AI" },
  { name: "Antarctica", code: "AQ" },
  { name: "Antigua and Barbuda", code: "AG" },
  { name: "Argentina", code: "AR" },
  { name: "Armenia", code: "AM" },
  { name: "Aruba", code: "AW" },
  { name: "Australia", code: "AU" },
  { name: "Austria", code: "AT" },
  { name: "Azerbaijan", code: "AZ" },
  { name: "Bahamas", code: "BS" },
  { name: "Bahrain", code: "BH" },
  { name: "Bangladesh", code: "BD" },
  { name: "Barbados", code: "BB" },
  { name: "Belarus", code: "BY" },
  { name: "Belgium", code: "BE" },
  { name: "Belize", code: "BZ" },
  { name: "Benin", code: "BJ" },
  { name: "Bermuda", code: "BM" },
  { name: "Bhutan", code: "BT" },
  { name: "Bolivia", code: "BO" },
  { name: "Bosnia and Herzegovina", code: "BA" },
  { name: "Botswana", code: "BW" },
  { name: "Bouvet Island", code: "BV" },
  { name: "Brazil", code: "BR" },
  { name: "British Indian Ocean Territory", code: "IO" },
  { name: "Brunei Darussalam", code: "BN" },
  { name: "Bulgaria", code: "BG" },
  { name: "Burkina Faso", code: "BF" },
  { name: "Burundi", code: "BI" },
  { name: "Cambodia", code: "KH" },
  { name: "Cameroon", code: "CM" },
  { name: "Canada", code: "CA" },
  { name: "Cape Verde", code: "CV" },
  { name: "Cayman Islands", code: "KY" },
  { name: "Central African Republic", code: "CF" },
  { name: "Chad", code: "TD" },
  { name: "Chile", code: "CL" },
  { name: "China", code: "CN" },
  { name: "Christmas Island", code: "CX" },
  { name: "Cocos (Keeling) Islands", code: "CC" },
  { name: "Colombia", code: "CO" },
  { name: "Comoros", code: "KM" },
  { name: "Congo", code: "CG" },
  { name: "Congo, The Democratic Republic of the", code: "CD" },
  { name: "Cook Islands", code: "CK" },
  { name: "Costa Rica", code: "CR" },
  { name: "Cote D'Ivoire", code: "CI" },
  { name: "Croatia", code: "HR" },
  { name: "Cuba", code: "CU" },
  { name: "Cyprus", code: "CY" },
  { name: "Czech Republic", code: "CZ" },
  { name: "Denmark", code: "DK" },
  { name: "Djibouti", code: "DJ" },
  { name: "Dominica", code: "DM" },
  { name: "Dominican Republic", code: "DO" },
  { name: "Ecuador", code: "EC" },
  { name: "Egypt", code: "EG" },
  { name: "El Salvador", code: "SV" },
  { name: "Equatorial Guinea", code: "GQ" },
  { name: "Eritrea", code: "ER" },
  { name: "Estonia", code: "EE" },
  { name: "Ethiopia", code: "ET" },
  { name: "Falkland Islands (Malvinas)", code: "FK" },
  { name: "Faroe Islands", code: "FO" },
  { name: "Fiji", code: "FJ" },
  { name: "Finland", code: "FI" },
  { name: "France", code: "FR" },
  { name: "French Guiana", code: "GF" },
  { name: "French Polynesia", code: "PF" },
  { name: "French Southern Territories", code: "TF" },
  { name: "Gabon", code: "GA" },
  { name: "Gambia", code: "GM" },
  { name: "Georgia", code: "GE" },
  { name: "Germany", code: "DE" },
  { name: "Ghana", code: "GH" },
  { name: "Gibraltar", code: "GI" },
  { name: "Greece", code: "GR" },
  { name: "Greenland", code: "GL" },
  { name: "Grenada", code: "GD" },
  { name: "Guadeloupe", code: "GP" },
  { name: "Guam", code: "GU" },
  { name: "Guatemala", code: "GT" },
  { name: "Guernsey", code: "GG" },
  { name: "Guinea", code: "GN" },
  { name: "Guinea-Bissau", code: "GW" },
  { name: "Guyana", code: "GY" },
  { name: "Haiti", code: "HT" },
  { name: "Heard Island and Mcdonald Islands", code: "HM" },
  { name: "Holy See (Vatican City State)", code: "VA" },
  { name: "Honduras", code: "HN" },
  { name: "Hong Kong", code: "HK" },
  { name: "Hungary", code: "HU" },
  { name: "Iceland", code: "IS" },
  { name: "India", code: "IN" },
  { name: "Indonesia", code: "ID" },
  { name: "Iran, Islamic Republic Of", code: "IR" },
  { name: "Iraq", code: "IQ" },
  { name: "Ireland", code: "IE" },
  { name: "Isle of Man", code: "IM" },
  { name: "Israel", code: "IL" },
  { name: "Italy", code: "IT" },
  { name: "Jamaica", code: "JM" },
  { name: "Japan", code: "JP" },
  { name: "Jersey", code: "JE" },
  { name: "Jordan", code: "JO" },
  { name: "Kazakhstan", code: "KZ" },
  { name: "Kenya", code: "KE" },
  { name: "Kiribati", code: "KI" },
  { name: "Korea, Democratic People'S Republic of", code: "KP" },
  { name: "Korea, Republic of", code: "KR" },
  { name: "Kuwait", code: "KW" },
  { name: "Kyrgyzstan", code: "KG" },
  { name: "Lao People'S Democratic Republic", code: "LA" },
  { name: "Latvia", code: "LV" },
  { name: "Lebanon", code: "LB" },
  { name: "Lesotho", code: "LS" },
  { name: "Liberia", code: "LR" },
  { name: "Libyan Arab Jamahiriya", code: "LY" },
  { name: "Liechtenstein", code: "LI" },
  { name: "Lithuania", code: "LT" },
  { name: "Luxembourg", code: "LU" },
  { name: "Macao", code: "MO" },
  { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
  { name: "Madagascar", code: "MG" },
  { name: "Malawi", code: "MW" },
  { name: "Malaysia", code: "MY" },
  { name: "Maldives", code: "MV" },
  { name: "Mali", code: "ML" },
  { name: "Malta", code: "MT" },
  { name: "Marshall Islands", code: "MH" },
  { name: "Martinique", code: "MQ" },
  { name: "Mauritania", code: "MR" },
  { name: "Mauritius", code: "MU" },
  { name: "Mayotte", code: "YT" },
  { name: "Mexico", code: "MX" },
  { name: "Micronesia, Federated States of", code: "FM" },
  { name: "Moldova, Republic of", code: "MD" },
  { name: "Monaco", code: "MC" },
  { name: "Mongolia", code: "MN" },
  { name: "Montserrat", code: "MS" },
  { name: "Morocco", code: "MA" },
  { name: "Mozambique", code: "MZ" },
  { name: "Myanmar", code: "MM" },
  { name: "Namibia", code: "NA" },
  { name: "Nauru", code: "NR" },
  { name: "Nepal", code: "NP" },
  { name: "Netherlands", code: "NL" },
  { name: "Netherlands Antilles", code: "AN" },
  { name: "New Caledonia", code: "NC" },
  { name: "New Zealand", code: "NZ" },
  { name: "Nicaragua", code: "NI" },
  { name: "Niger", code: "NE" },
  { name: "Nigeria", code: "NG" },
  { name: "Niue", code: "NU" },
  { name: "Norfolk Island", code: "NF" },
  { name: "Northern Mariana Islands", code: "MP" },
  { name: "Norway", code: "NO" },
  { name: "Oman", code: "OM" },
  { name: "Pakistan", code: "PK" },
  { name: "Palau", code: "PW" },
  { name: "Palestinian Territory, Occupied", code: "PS" },
  { name: "Panama", code: "PA" },
  { name: "Papua New Guinea", code: "PG" },
  { name: "Paraguay", code: "PY" },
  { name: "Peru", code: "PE" },
  { name: "Philippines", code: "PH" },
  { name: "Pitcairn", code: "PN" },
  { name: "Poland", code: "PL" },
  { name: "Portugal", code: "PT" },
  { name: "Puerto Rico", code: "PR" },
  { name: "Qatar", code: "QA" },
  { name: "Reunion", code: "RE" },
  { name: "Romania", code: "RO" },
  { name: "Russian Federation", code: "RU" },
  { name: "RWANDA", code: "RW" },
  { name: "Saint Helena", code: "SH" },
  { name: "Saint Kitts and Nevis", code: "KN" },
  { name: "Saint Lucia", code: "LC" },
  { name: "Saint Pierre and Miquelon", code: "PM" },
  { name: "Saint Vincent and the Grenadines", code: "VC" },
  { name: "Samoa", code: "WS" },
  { name: "San Marino", code: "SM" },
  { name: "Sao Tome and Principe", code: "ST" },
  { name: "Saudi Arabia", code: "SA" },
  { name: "Senegal", code: "SN" },
  { name: "Serbia and Montenegro", code: "CS" },
  { name: "Seychelles", code: "SC" },
  { name: "Sierra Leone", code: "SL" },
  { name: "Singapore", code: "SG" },
  { name: "Slovakia", code: "SK" },
  { name: "Slovenia", code: "SI" },
  { name: "Solomon Islands", code: "SB" },
  { name: "Somalia", code: "SO" },
  { name: "South Africa", code: "ZA" },
  { name: "South Georgia and the South Sandwich Islands", code: "GS" },
  { name: "Spain", code: "ES" },
  { name: "Sri Lanka", code: "LK" },
  { name: "Sudan", code: "SD" },
  { name: "Suriname", code: "SR" },
  { name: "Svalbard and Jan Mayen", code: "SJ" },
  { name: "Swaziland", code: "SZ" },
  { name: "Sweden", code: "SE" },
  { name: "Switzerland", code: "CH" },
  { name: "Syrian Arab Republic", code: "SY" },
  { name: "Taiwan, Province of China", code: "TW" },
  { name: "Tajikistan", code: "TJ" },
  { name: "Tanzania, United Republic of", code: "TZ" },
  { name: "Thailand", code: "TH" },
  { name: "Timor-Leste", code: "TL" },
  { name: "Togo", code: "TG" },
  { name: "Tokelau", code: "TK" },
  { name: "Tonga", code: "TO" },
  { name: "Trinidad and Tobago", code: "TT" },
  { name: "Tunisia", code: "TN" },
  { name: "Turkey", code: "TR" },
  { name: "Turkmenistan", code: "TM" },
  { name: "Turks and Caicos Islands", code: "TC" },
  { name: "Tuvalu", code: "TV" },
  { name: "Uganda", code: "UG" },
  { name: "Ukraine", code: "UA" },
  { name: "United Arab Emirates", code: "AE" },
  { name: "United Kingdom", code: "GB" },
  { name: "United States", code: "US" },
  { name: "United States Minor Outlying Islands", code: "UM" },
  { name: "Uruguay", code: "UY" },
  { name: "Uzbekistan", code: "UZ" },
  { name: "Vanuatu", code: "VU" },
  { name: "Venezuela", code: "VE" },
  { name: "Viet Nam", code: "VN" },
  { name: "Virgin Islands, British", code: "VG" },
  { name: "Virgin Islands, U.S.", code: "VI" },
  { name: "Wallis and Futuna", code: "WF" },
  { name: "Western Sahara", code: "EH" },
  { name: "Yemen", code: "YE" },
  { name: "Zambia", code: "ZM" },
  { name: "Zimbabwe", code: "ZW" },
];
const lang = [
  { label: "Turkce", value: "tr" },
  { label: "English", value: "en" },
];
const accountTypes = [
  { label: "Demo", value: "demo" },
  { label: "Normal", value: "normal" },
];
const status = [
  { label: "Registered", value: "registered" },
  { label: "Activated", value: "activate" },
  { label: "Waiting Activation", value: "waiting_activation" },
  { label: "Restricted", value: "restricted" },
  { label: "Waiting Payment", value: "waiting_payment" },
];

const solutions = [
  { label: "upu.machine", value: "upu.machine" },
  { label: "upu.person", value: "upu.person" },
  { label: "upu.product", value: "upu.product" },
];
const companyTableFilter = [
  {
    label: "Company",
    value: "companyName",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Node ID",
    value: "nodeId",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Device State",
    value: "deviceState",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "IP Adress",
    value: "ipAddress",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Status",
    value: "status",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "STM Version",
    value: "stmVersion",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Esp Version",
    value: "espVersion",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "RSSI",
    value: "rssi",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Yazılım Testi",
    value: "softwareTest",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Donanım Testi",
    value: "hardwareTest",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Üretim Tarihi",
    value: "productDate",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Donanım Versiyonu",
    value: "hardwareVersion",
    checked: true,
    sortable: true,
    ascd: true,
  },
];
const companyTabs = [
  { key: "companyInfo", title: "dashboard:companyInfo", mandatory: true },
  { key: "contactInfo", title: "dashboard:contactInfo", mandatory: true },
  { key: "contractInfo", title: "dashboard:contractInfo", mandatory: true },
  { key: "nodeInfo", title: "dashboard:nodeList", mandatory: false },
  { key: "userList", title: "dashboard:userList", mandatory: false },
  { key: "logRecord", title: "dashboard:logRecord", mandatory: false },
];
const companyUserListTab = [
  {
    label: "Image",
    value: "image",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Name",
    value: "name",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Last Name",
    value: "lastName",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "E-Mail",
    value: "email",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Add to Another Company",
    value: "addToAnotherCompany",
    checked: true,
    sortable: true,
    ascd: true,
  },
  {
    label: "Delete User",
    value: "deleteUser",
    checked: true,
    sortable: true,
    ascd: true,
  },
];
const testTableHead = [
  {
    name: "Node ID",
  },
  {
    name: "Test Tipi",
  },
  {
    name: "Sonuç",
  },
  {
    name: "Yapan Kişi",
  },
  {
    name: "Tarih",
  },
  {
    name: "Rapor",
  },
];
const TestingTypesDTO = {
  ADC: "ADC-values",
  DIGITAL: "Digital-values",
  FAILED: "Failed",
  ADC_TEST: "adcTest",
  DIGITAL_TEST: "digitalTest",
  TEST_START: "testStart",
  STARTED: "started",
  ANDON: "andonTest",
  ANALOG_1: "analogSensor1",
  ANALOG_2: "analogSensor2",
  DIGITAL_1: "digitalSensor1",
  DIGITAL_2: "digitalSensor2",
  STATUS: "status",
  FINISHED: "finished",
};
const companyTableFilterDashboard = [
  {
    label: t("dashboard:country"),
    value: "country",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: t("dashboard:name"),
    value: "name",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "E-mail",
    value: "email",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: false,
  },
  {
    label: t("dashboard:contactName"),
    value: "contactName",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: false,
  },
  {
    label: t("dashboard:contactEmail"),
    value: "contactEmail",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: false,
  },
];
const industryTableFilter = [
  {
    label: "nameEn",
    value: "name.en",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "nameTr",
    value: "name.tr",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "companies",
    value: "company",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
];
const permissionTableFilter = [
  {
    label: "permissionKey",
    value: "key",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "role",
    value: "role",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "nameEn",
    value: `name.en`,
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "nameTr",
    value: `name.tr`,
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
];
const rolesTableFilter = [
  {
    label: "roleKey",
    value: "key",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "role",
    value: "roles",
    checked: false,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "nameEn",
    value: "name.en",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "nameTr",
    value: "name.tr",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
];
const sensorTableFilter = [
  {
    label: "name",
    value: "name",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "type",
    value: "type",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
];
const cCountTableFilter = [
  {
    label: "zone",
    value: "zone",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "value",
    value: "value",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
];
const statusesTableFilter = [
  {
    label: "statusName",
    value: "name",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "statusCode",
    value: "code",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
  {
    label: "statusColor",
    value: "color",
    checked: true,
    ascd: true,
    sortable: true,
    mandatory: true,
  },
];

const pieChartTabularLabels = [
  {
    label: "Company",
    value: "company",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Node ID",
    value: "deviceId",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Machine Brand",
    value: "brand",
    sortable: true,
    canFilter: false,
    ascd: true,
  },
  {
    label: "Serial No",
    value: "electronicCardSerialNo",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Device State",
    value: "deviceState",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  // {
  //   label: "Status",
  //   value: "status",
  //   sortable: true,
  //   ascd: true,
  // },
  {
    label: "Date",
    value: "createdDate",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Updated Date",
    value: "updatedDate",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "STM Version",
    value: "stmVersion",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Esp Version",
    value: "espVersion",
    sortable: true,
    canFilter: true,
    ascd: true,
  },
  {
    label: "Update",
    value: "update",
    sortable: true,
    canFilter: false,
    ascd: true,
  },
];

export {
  timezones,
  countries,
  lang,
  accountTypes,
  solutions,
  status,
  nodeTableFilter,
  softwaresTableFilter,
  updateReportFilter,
  nodeListSearchTypes,
  companyTableFilter,
  companyTabs,
  companyUserListTab,
  testNodeTable,
  testTableHead,
  TestingTypesDTO,
  userTableFilter,
  companyTableFilterDashboard,
  industryTableFilter,
  permissionTableFilter,
  rolesTableFilter,
  sensorTableFilter,
  statusesTableFilter,
  cCountTableFilter,
  newNodeTableFilter,
  embeddedTableFilter,
  pieChartTabularLabels,
};
