import React, { useEffect, useState } from "react";
import { useAlert, positions } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { useStore } from "../../hooks/useStores";
import { userService } from "../../services";
import { yupResolver } from "@hookform/resolvers/yup";
import { Input, NewInput, ImageUploader } from "../../components";
import * as yup from "yup";
import { t } from "i18next";
const UserForm = ({ setOpenModal, selectedUser, handleUsersPaginated }) => {
  const { app } = useStore();
  const alert = useAlert();
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const schema = yup.object({
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    lastName: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    email: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    avatar: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    password: yup
      .string()
      .notRequired(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      lastName: "",
      email: "",
      avatar: "",
      password: "",
    },
  });
  useEffect(() => {
    if (selectedUser) {
      setValue("avatar", selectedUser.avatar);
      setValue("name", selectedUser.name);
      setValue("lastName", selectedUser.lastName);
      setValue("email", selectedUser.email);
      setValue("password", selectedUser.password);
    } else {
      setValue("avatar", "");
      setValue("name", "");
      setValue("lastName", "");
      setValue("email", "");
      setValue("password", "");
    }
  }, []);
  const onSubmit = async (data) => {
    app.setLoadingOverlay(true);
    if (selectedUser?.id) {
      await userService
        .updateUser(selectedUser.id, data)
        .then((res) => {
          if (res.data.code === 0) {
            app.setLoadingOverlay(false);
            alert.success("Updated.", {
              position: positions.TOP_LEFT,
            });
            handleUsersPaginated();
            setOpenModal(false);
          } else {
            app.setLoadingOverlay(false);
            alert.success("Failed.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    } else {
      if (data.password && data.password != passwordRepeat) {
        app.setLoadingOverlay(false);
        alert.success("Passwords not matching.", {
          position: positions.TOP_LEFT,
        });
      } else {
        await userService
          .createUser(data)
          .then((res) => {
            if (res.data.code === 0) {
              app.setLoadingOverlay(false);
              alert.success("Successfull.", {
                position: positions.TOP_LEFT,
              });
              handleUsersPaginated();
              setOpenModal(false);
            } else {
              app.setLoadingOverlay(false);
              alert.success("Failed.", {
                position: positions.TOP_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            app.setLoadingOverlay(false);
          });
      }
    }
  };
  const deleteUser = async (id) => {
    await userService
      .deleteUser(id)
      .then((res) => {
        if (res.data.code === 0) {
          app.setLoadingOverlay(false);
          alert.success("Successfull.", {
            position: positions.TOP_LEFT,
          });
          handleUsersPaginated();
          setOpenModal(false);
        } else {
          app.setLoadingOverlay(false);
          alert.success("Failed.", {
            position: positions.TOP_LEFT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        app.setLoadingOverlay(false);
      });
  };
  return (
    <div className=" w-full h-full flex flex-col">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col w-full h-full gap-6 mt-3 mb-3"
      >
        <div className="ml-5 w-max">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <ImageUploader
                title={t("fileInput:uploadAvatar")}
                subTitle={t("fileInput:removePicture")}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            )}
            name="avatar"
          />
        </div>
        <div className="w-full flex justify-between">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("company:name")}
                width={320}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.name ? "error" : ""}
                errorMessage={errors.name ? errors.name.message : ""}
              />
            )}
            name="name"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("company:lastName")}
                width={320}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.lastName ? "error" : ""}
                errorMessage={errors.lastName ? errors.lastName.message : ""}
              />
            )}
            name="lastName"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("company:email")}
                width={320}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.email ? "error" : ""}
                errorMessage={errors.email ? errors.email.message : ""}
              />
            )}
            name="email"
          />
        </div>
        {!selectedUser?.id && (
          <div className="w-full flex justify-start gap-x-6">
            <Controller
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <NewInput
                  label={t("company:password")}
                  width={320}
                  value={value}
                  disabled={false}
                  type="password"
                  onBlur={onBlur}
                  onChange={onChange}
                  validate={errors.password ? "error" : ""}
                  errorMessage={errors.password ? errors.password.message : ""}
                />
              )}
              name="password"
            />
            <NewInput
              type="password"
              label={t("company:passwordRepeat")}
              width={320}
              onChange={(e) => {
                setPasswordRepeat(e.target.value);
              }}
            />
          </div>
        )}
        {selectedUser?.id && (
          <div className="absolute bg-white bottom-3 left-8 flex gap-4 ">
            <button
              type="button"
              onClick={() => deleteUser(selectedUser.id)}
              className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-red-600 text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
            >
              {t("buttons:delete")}
            </button>
          </div>
        )}
        <div className="absolute bg-white bottom-3 right-8 flex gap-4 ">
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            className="w-[153px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
          >
            {t("buttons:save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default UserForm;
