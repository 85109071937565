import { PlusIcon } from "@heroicons/react/outline";
import React from "react";

const NewAddButton = ({ onClick, icon, label, width, primary = false }) => {
  return (
    <button
      style={{ width: width }}
      onClick={onClick}
      className={`h-[42px] gap-2 flex items-center justify-center border ${
        primary
          ? "bg-primary-600 border-primary-600 text-white hover:bg-primary-500"
          : "text-gray-600 border-gray-300 hover:bg-gray-50"
      } rounded-lg shadow`}
    >
      {icon}
      {label}
    </button>
  );
};

export default NewAddButton;
