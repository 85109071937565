import React from "react";
import { useTranslation } from "react-i18next";
import { PlusIcon } from "../../assets/icons";

const AddNewIndustryButton = ({ width, onClick, placeholder=`Add New Industry`}) => {
  const { t } = useTranslation();
  return (
    <button
      onClick={onClick}
      className="flex items-center justify-center h-11 gap-3 w-full text-base font-medium text-gray-700 border border-gray-300 rounded-lg hover:bg-slate-50"
      style={{ boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)", width: width }}
    >
      <PlusIcon />
      {placeholder}
    </button>
  );
};

export default AddNewIndustryButton;
