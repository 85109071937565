import { instance } from "../libs/client";
/* eslint-disable camelcase */

class RoleMappingService {
  listPermissions = async () => {
    return instance.get("roles/permissions");
  };
  listRoles = async () => {
    return instance.get("roles/list/role-group");
  };
  createPermission = async (data) => {
    return instance.post("roles/create/permission", data);
  };
  updatePermission = async (id, data) => {
    return instance.put(`roles/update/permission/${id}`, data);
  };
  createRole = async (data) => {
    return instance.post("roles/create/role-group", data);
  };
  updateRole = async (id, data) => {
    return instance.put(`roles/update/role-group/${id}`, data);
  };
  getPermissionsPaginated = async (pageNo, search = "") => {
    return instance.get(
      `roles/permissions/paginated?page=${pageNo}${
        search ? `&search=${search}` : ""
      }`
    );
  };
  getRolesPaginated = async (pageNo, search = "") => {
    return instance.get(
      `roles/list/role-group/paginated?page=${pageNo}${
        search ? `&search=${search}` : ""
      }`
    );
  };
}

const roleService = new RoleMappingService();

export default roleService;
