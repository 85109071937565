import { instance } from "../libs/client";
/* eslint-disable camelcase */

class CompanyService {
  addNewIndustry = async (names) => {
    return instance.post("company/industry", {
      name: names,
    });
  };
  listIndustry = async () => {
    return instance.get("company/industry");
  };
  getNodes = async () => {
    return instance.get("company/nodes");
  };
  listIndustryPaginated = async (pageNo, search = "") => {
    return instance.get(
      `company/industry/paginated?page=${pageNo}${
        search ? `&search=${search}` : ""
      }`
    );
  };
  listAllCompanies = async () => {
    return instance.get("company/all");
  };
  listCompaniesPaginated = async (pageNo, search = "", type = "") => {
    return instance.get(
      `company/paginated?page=${pageNo}${search ? `&search=${search}` : ""}${
        type != "all" ? `&type=${type}` : ""
      }`
    );
  };
  listCompanies = async () => {
    return instance.get("company/");
  };
  addCompany = async (data) => {
    return instance.post("company/", data);
  };
  updateCompany = async (id, data) => {
    return instance.put(`/company/${id}`, data);
  };
  updateIndustry = async (id, data) => {
    return instance.put(`/company/industry/${id}`, data);
  };
  deleteCompany = async (id) => {
    return instance.delete(`/company/${id}`);
  };
  deleteUser = async (id) => {
    return instance.delete(`/company/user/${id}`);
  };
  getCompany = async (id) => {
    return instance.get(`company/${id}`);
  };
  listAllUsers = async () => {
    return instance.get("company/allUsers");
  };
  changeUserCompanies = async (id, data) => {
    return instance.put(`company/multiCompany/${id}`, data);
  };

  getTimezoneList = async () => {
    return instance.get("company/list/timezones");
  };
  listCCounts = async () => {
    return instance.get("company/ccount");
  };
  listCCountsPaginated = async (pageNo, search = "", type = "") => {
    return instance.get(
      `company/ccount/paginated?page=${pageNo}${
        search ? `&search=${search}` : ""
      }${type != "all" ? `&type=${type}` : ""}`
    );
  };
  addCCount = async (data) => {
    return instance.post("company/ccount", data);
  };
  updateCCount = async (id, data) => {
    return instance.put(`company/ccount/${id}`, data);
  };
  deleteCCount = async (id) => {
    return instance.delete(`company/ccount/${id}`);
  };
}

const companyService = new CompanyService();

export default companyService;
