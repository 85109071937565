import React from "react";

const Button = ({
  label,
  size,
  width,
  colorType,
  onClick,
  disabled,
  icon,
  theme,
  type = "button",
  ...rest
}) => {
  const getClassName = () => {
    let className = "";
    switch (size) {
      case "xxs":
        className += "px-[12.5px] py-1 text-[10px] leading-5";
        break;
      case "xs":
        className += "px-[7px] py-1 text-[12px] leading-5";
        break;
      case "sm":
        className += "px-3 py-1.5 text-[12px] leading-5";
        break;
      case "md":
        className += "px-[14px] py-2 text-[14px] leading-5";
        break;
      case "lg":
        className += "px-4 py-2.5 text-[14px] leading-5";
        break;
      case "xl":
        className += "px-5 py-3 text-[16px] leading-6";
        break;
      case "xxl":
        className += "px-7 py-4 text-[18px] leading-7";
        break;
      default:
        className += "px-[18px] py-2.5 text-base leading-6";
        break;
    }
    return className;
  };

  return (
    <button
      type={type ? type : "button"}
      className={
        `w-full font-medium  rounded-lg border-[1px] flex items-center sm:text-xs justify-center md:h-[40px] md:text-xs box-border ${
          colorType == "secondary"
            ? "bg-white border border-secondary-300 "
            : colorType == "ghost"
            ? "bg-white border border-secondary-300  border-solid"
            : colorType == "alert"
            ? "bg-red-600 border border-red-600 "
            : theme == "person"
            ? "bg-teal-600 border border-teal-600 "
            : "bg-primary-600 border border-primary-600 "
        }` + getClassName()
      }
      onClick={onClick}
      {...(disabled ? disabled : null)}
      {...rest}
    >
      {icon ? (
        <span className="w-4 h-4 max-h-4 max-w-4 flex items-center justify-center mr-1 dark:text-[#E5E5E5]">
          {icon}
        </span>
      ) : null}
      <span
        className={`${
          colorType == "secondary"
            ? localStorage.color_theme === "dark"
              ? "text-[#FFFFFF]"
              : "text-secondary-700"
            : colorType == "ghost"
            ? localStorage.color_theme === "dark"
              ? "text-[#FFFFFF]"
              : "text-black"
            : " text-white"
        }`}
      >
        {label}
      </span>
    </button>
  );
};

export default Button;
