import { instance } from "../libs/client";
/* eslint-disable camelcase */

class FileService {
  uploadFile = async (data) => {
    return instance.post("file/software/upload", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  };
}

const fileService = new FileService();

export default fileService;
