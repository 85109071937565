import React from "react";
import DigitalSensorCard from "./DigitalSensorCard";

const DigitalSensorList = ({ metadataId, data, setDraggedSensor, priorityList, refetch, selectedSettings }) => {
  const sensors = [
    {
      name: "D1",
      whichSensor: "first",
      id: data?.firstDigital?.id,
      isActive: true,
      value: data?.firstDigital?.value,
      isConsumption: data?.firstDigital?.isConsumption,
    },
    {
      name: "D2",
      whichSensor: "second",
      id: data?.secondDigital?.id,
      isActive: true,
      value: data?.secondDigital?.value,
      isConsumption: data?.secondDigital?.isConsumption,
    },
    {
      name: "D3",
      whichSensor: "third",
      id: data?.thirdDigital?.id,
      isActive: true,
      value: data?.thirdDigital?.value,
      isConsumption: data?.thirdDigital?.isConsumption,
    },
    {
      name: "D4",
      whichSensor: "fourth",
      id: data?.fourthDigital?.id,
      isActive: true,
      value: data?.fourthDigital?.value,
      isConsumption: data?.fourthDigital?.isConsumption,
    },
  ];
  return (
    <div className="w-full flex flex-col border-b pb-4 mb-4 border-secondary-200">
      <div className="h-[52px] w-full flex items-center">
        <div className="h-[28px] w-full flex items-end">
          <p className="text-lg font-semibold text-gray-900 w-1/2">Dijital Sensörler</p>
          <p className="text-xs font-medium text-gray-600 w-[20%] flex items-center justify-center text-center gap-1">
            Değer <Info />
          </p>
          <p className="text-xs font-medium text-gray-600 w-[20%] flex items-center justify-center text-clip">Consumption</p>
        </div>
      </div>

      <div className="w-full flex flex-col px-0.5 gap-3 ">
        {sensors.map((item, index) => {
          return (
            <DigitalSensorCard
              key={index}
              name={item.name}
              refetch={refetch}
              sensorId={item.id}
              metadataId={metadataId}
              isActive={item.isActive}
              priorityList={priorityList}
              whichSensor={item.whichSensor}
              setDraggedSensor={setDraggedSensor}
              defaultSensorId={selectedSettings}
              savedData={{ isConsumption: item?.isConsumption, value: item?.value }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default DigitalSensorList;

const Info = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14" viewBox="0 0 15 14" fill="none">
      <path
        d="M7.49996 9.33317V6.99984M7.49996 4.6665H7.50579M13.3333 6.99984C13.3333 10.2215 10.7216 12.8332 7.49996 12.8332C4.2783 12.8332 1.66663 10.2215 1.66663 6.99984C1.66663 3.77818 4.2783 1.1665 7.49996 1.1665C10.7216 1.1665 13.3333 3.77818 13.3333 6.99984Z"
        stroke="#98A2B3"
        stroke-width="1.33"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
