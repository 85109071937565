import React, { useEffect, useState } from "react";
import { SearchInput } from "../../../components";
import generateFileUrl from "../../../utils/generateFileUrl";
import { companyService } from "../../../services";
import { useTranslation } from "react-i18next";

const ChooseCompany = ({ setIsOpenCompanySelect, user, getOneCompany }) => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [searchCompany, setSearchCompany] = useState("");
  const { t } = useTranslation();
  const getAllCompanies = async () => {
    await companyService
      .listCompanies()
      .then((res) => {
        setCompanies(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const filteredCompanies = companies?.filter((item) =>
    item.name.toLowerCase().includes(searchCompany.toLowerCase())
  );
  useEffect(() => {
    getAllCompanies();
    setSelectedCompanies(
      user?.company?.map((u, i) => {
        return u.id;
      })
    );
  }, []);
  const onSubmit = async () => {
    if (selectedCompanies.length > 0) {
      await companyService
        .changeUserCompanies(user.id, selectedCompanies)
        .then((res) => {
          if (res.data.code == 0) {
            getOneCompany();
            setIsOpenCompanySelect(false);
          } else {
            alert("Alert");
          }
        });
    } else {
      alert("Please select company");
    }
  };
  return (
    <>
      <p className="h-6 w-full -mt-1 text-[#667085] text-sm font-normal ">
        {t("dashboard:chooseCompanyContent")}
      </p>
      <div className="w-full h-[421px] flex flex-col items-start gap-4 self-stretch">
        <div className="w-full h-11 flex-col items-center justify-center gap-1.5 self-stretch mt-3 bg-[#FFFFFF]">
          <SearchInput
            setSearchVal={setSearchCompany}
            placeholder={t("auth:search")}
            width={705}
          />
        </div>

        <div className="w-full h-[167px] gap-2 flex flex-col items-start self-stretch">
          {filteredCompanies?.map((item, index) => {
            return (
              <div
                onClick={() => {
                  if (selectedCompanies.includes(item.id)) {
                    setSelectedCompanies(
                      selectedCompanies.filter((el, i) => {
                        return el != item.id;
                      })
                    );
                  } else {
                    setSelectedCompanies([...selectedCompanies, item.id]);
                  }
                }}
                className="w-full h-[55px] flex justify-between items-center self-stretch border-b pb-2"
              >
                <div className=" h-full flex justify-center items-center gap-x-4">
                  <img
                    src={generateFileUrl(item.image)}
                    className="w-[60px] h-[45px] rounded  border border-solid border-secondary-300"
                  />
                  <p className="text-[#344054] text-base not-italic font-medium  ">
                    {item.name}
                  </p>
                </div>

                <div
                  style={{
                    borderColor: selectedCompanies.includes(item.id)
                      ? "#7F56D9"
                      : "#D0D5DD",
                  }}
                  className={`w-5 h-5 rounded-md border flex items-center justify-center ml-auto`}
                >
                  {selectedCompanies.includes(item.id) && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                    >
                      <path
                        d="M11.6666 4L5.24998 10.4167L2.33331 7.5"
                        stroke="#7F56D9"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <button
        onClick={() => setIsOpenCompanySelect(false)}
        className="w-[153px] absolute bottom-3 right-[200px] h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-[#FFFFFF] text-[#344054] border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
      >
        {t("buttons:stop")}
      </button>
      <button
        onClick={() => {
          onSubmit();
        }}
        className="w-[153px] absolute bottom-3 right-8 h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium bg-[#7F56D9] text-white border border-gray-300 shadow hover:bg-slate-100 hover:text-gray-700"
      >
        {t("buttons:save")}
      </button>
    </>
  );
};

export default ChooseCompany;
