import React from "react";

const IconButton = ({ onClick, icon, className }) => {
  return (
    <button
      style={{
        alignItems: "center",
        right: -25,
        position: "",
        cursor: "pointer",
        justifyContent: "center",
        borderRigth: "0px solid black",
      }}
      onClick={onClick}
      className={className}
    >
      {icon}
    </button>
  );
};

export default IconButton;
