import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { LoginAvatarPng, LoginBackground, UpuLogo } from "../../assets/images";
import Input from "../../components/Inputs/Input";
import { BlackLogo } from "../../assets/icons";
import { authService } from "../../services";
import { useStore } from "../../hooks/useStores";
import { useNavigate } from "react-router-dom";
import { useAlert, positions } from "react-alert";

const Login = () => {
  const { auth, app } = useStore();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const navigate = useNavigate();
  const alert = useAlert();
  const onLogin = (e) => {
    if (!email && !password) {
      setEmailError(true);
      setPasswordError(true);
    } else if (!password) {
      setPasswordError(true);
      setEmailError(false);
    } else if (!email) {
      setEmailError(true);
      setPasswordError(false);
    } else {
      e.preventDefault();
      app.setLoadingOverlay(true);
      authService
        .login({
          email,
          password,
        })
        .then(async ({ data: { user, ...rest } }) => {
          if (user) {
            auth.setToken(rest);
            auth.setUser(user);
            auth.setLogged(true);
            authService.setAuthInterceptor(auth.accessToken);
            app.setLoadingOverlay(false);
            alert.success("Successfully logged in.", {
              position: positions.TOP_LEFT,
            });
            if (user.isChangePassword) {
              navigate("/change-password");
            } else {
              navigate("/dashboard");
            }
          } else {
            alert.error("Invalid credentials.", {
              position: positions.TOP_LEFT,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          app.setLoadingOverlay(false);
        });
    }
  };
  return (
    <>
      <div className="flex md:flex-row w-screen overflow-hidden overflow-y-hidden">
        <div className="lg:w-7/12  md:flex  xs:hidden h-screen relative">
          <img
            src={LoginBackground}
            className="h-screen w-full drop-shadow-4xl"
          />
          <img
            src={LoginAvatarPng}
            className="absolute left-10 mt-auto w-11/12 z-20 top-4 lg:w-full lg:mt-6 "
          />
        </div>
        <div className="lg:w-5/12 xs:w-screen h-screen flex flex-col items-center w-5/12 justify-center relative ">
          <div className="flex items-center absolute top-16 gap-2 -translate-x-2">
            <BlackLogo width={48} height={48} className="" />
            <UpuLogo
              width={120}
              height={44}
              className="text-main translate-y-2"
            />
          </div>
          <div className=" md:w-2/4 xs:w-screen flex flex-col gap-3 items-center">
            <p className="text-3xl text-secondary-900 text-center font-semibold leading-8">
              {" "}
              Welcome!
            </p>
            <p className="text-center text-[20px] text-secondary-500 mb-1.5">
              Please enter your details.
            </p>
            <div className={"flex flex-col xs:px-6 w-[320px]"}>
              <Input
                width={"100%"}
                label={"E-mail"}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div
              className={
                "flex flex-col xs:px-6 w-[320px] text-secondary-500 mt-2.5"
              }
            >
              <Input
                width={"100%"}
                label={"Password"}
                inputType={"password"}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className={"flex flex-col xs:px-6 w-[320px] box-border"}>
              <button
                onClick={(e) => {
                  onLogin(e);
                }}
                className="w-full h-11 bg-primary-600 rounded-lg text-white"
              >
                Login
              </button>
              <div className="w-full">
                <p className="  mt-5 mb-12 text-sm ">
                  Don’t have an account?
                  <a
                    className="text-main ml-2"
                    target={"blank"}
                    href="https://www.upu.io/"
                  >
                    upu.io
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
