import { TestingTypesDTO } from "../static/constants";
const isCheckedControl = (type, values) => {
  let plugCheck = false;
  let valueCheck = false;
  switch (type) {
    case TestingTypesDTO.STARTED:
      return true;
    case TestingTypesDTO.ANDON:
      return values[TestingTypesDTO.ANDON]?.node?.AndonTest == "Completed"
        ? true
        : false;
    case TestingTypesDTO.ANALOG_1:
      plugCheck =
        (values[TestingTypesDTO.ANALOG_1]?.calibrator?.a1Plug == 1 &&
          values[TestingTypesDTO.ANALOG_1]?.node?.analogSensor1) ||
        (values[TestingTypesDTO.ANALOG_1]?.calibrator?.a1Plug == 0 &&
          values[TestingTypesDTO.ANALOG_1]?.node?.analogSensor1 == undefined)
          ? true
          : false;
      valueCheck =
        values[TestingTypesDTO.ANALOG_1]?.calibrator?.a1CurrentValue ==
        values[TestingTypesDTO.ANALOG_1]?.node?.analogSensor1
          ? true
          : false;
      return plugCheck && valueCheck ? true : false;
    case TestingTypesDTO.ANALOG_2:
      plugCheck =
        (values[TestingTypesDTO.ANALOG_2]?.calibrator?.a2Plug == 1 &&
          values[TestingTypesDTO.ANALOG_2]?.node?.analogSensor2) ||
        (values[TestingTypesDTO.ANALOG_2]?.calibrator?.a2Plug == 0 &&
          values[TestingTypesDTO.ANALOG_2]?.node?.analogSensor2 == undefined)
          ? true
          : false;
      valueCheck =
        values[TestingTypesDTO.ANALOG_2]?.calibrator?.a2CurrentValue ==
        values[TestingTypesDTO.ANALOG_2]?.node?.analogSensor2
          ? true
          : false;
      return plugCheck && valueCheck ? true : false;
    case TestingTypesDTO.DIGITAL_1:
      plugCheck =
        (values[TestingTypesDTO.DIGITAL_1]?.calibrator?.d1Plug == 1 &&
          values[TestingTypesDTO.DIGITAL_1]?.node?.digitalValues) ||
        (values[TestingTypesDTO.DIGITAL_1]?.calibrator?.d1Plug == 0 &&
          values[TestingTypesDTO.DIGITAL_1]?.node?.digitalValues == undefined)
          ? true
          : false;
      valueCheck =
        values[TestingTypesDTO.DIGITAL_1]?.calibrator?.d1Status ==
        values[TestingTypesDTO.DIGITAL_1]?.node?.digitalValues
          ? true
          : false;
      return plugCheck && valueCheck ? true : false;
    case TestingTypesDTO.DIGITAL_2:
      plugCheck =
        (values[TestingTypesDTO.DIGITAL_2]?.calibrator?.d2Plug == 1 &&
          values[TestingTypesDTO.DIGITAL_2]?.node?.digitalValues) ||
        (values[TestingTypesDTO.DIGITAL_2]?.calibrator?.d2Plug == 0 &&
          values[TestingTypesDTO.DIGITAL_2]?.node?.digitalValues == undefined)
          ? true
          : false;
      valueCheck =
        values[TestingTypesDTO.DIGITAL_2]?.calibrator?.d2Status ==
        values[TestingTypesDTO.DIGITAL_2]?.node?.digitalValues
          ? true
          : false;
      return plugCheck && valueCheck ? true : false;
    case TestingTypesDTO.STATUS:
      valueCheck =
        values[TestingTypesDTO.STATUS]?.node?.Status == 70 ? true : false;
      return valueCheck ? true : false;
    default:
      return false;
  }
};
export default isCheckedControl;
