import React, { useState } from "react";
import { Selector } from "./index";
import { t } from "i18next";
import { softwareService } from "../services";
import { useAlert, positions } from "react-alert";
const SelectSoftwareModal = ({
  setIsOpen,
  selectedNodes,
  softwares,
  handleCompanyNodes,
  setSelectedNodes,
  setUpdateReportData,
  setUpdateReportModal,
}) => {
  const alert = useAlert();
  const [selectedSoftware, setSelectedSoftware] = useState("");
  const sendSoftware = async () => {
    await softwareService
      .sendUpdate({ data: selectedNodes, softwareId: selectedSoftware })
      .then((res) => {
        alert.success(t("software:softwareUpdateSent"), {
          position: positions.TOP_LEFT,
        });
        setIsOpen(false);
        setUpdateReportModal(true);
        setUpdateReportData(res.data);
        setSelectedNodes([]);
        handleCompanyNodes();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="w-full h-full flex flex-col gap-y-2">
      <div className="w-full mt-2">
        <Selector
          placeholder={`${t("software:selectSoftware")} (STM)`}
          items={softwares
            ?.filter((a) => {
              return a?.type == "stm";
            })
            .map((item) => ({
              label: item.name,
              value: item.id,
            }))}
          onChange={(v) => {
            setSelectedSoftware(v);
          }}
          width={"100%"}
          disabled={false}
          setSelectedItem={setSelectedSoftware}
          selectedItem={selectedSoftware}
        />
      </div>
      <div className="w-full mt-2">
        <Selector
          placeholder={`${t("software:selectSoftware")} (ESP)`}
          items={softwares
            ?.filter((a) => {
              return a?.type == "esp";
            })
            ?.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
          width={"100%"}
          disabled={false}
          setSelectedItem={setSelectedSoftware}
          selectedItem={selectedSoftware}
        />
      </div>
      <div className="w-full mt-2">
        {selectedNodes
          .map((item) => {
            return item?.nodes?.join(", ");
          })
          .join(", ")}
      </div>
      <div className="absolute w-full max-w-[440px] bg-white bottom-[16px] flex items-center justify-center gap-4 right-7">
        <button
          type="button"
          onClick={() => setIsOpen(false)}
          className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
        >
          {t("buttons:stop")}
        </button>
        <button
          type="submit"
          onClick={() => {
            sendSoftware();
          }}
          className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
        >
          {t("buttons:save")}
        </button>
      </div>
    </div>
  );
};

export default SelectSoftwareModal;
