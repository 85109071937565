import React, { useState, Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { DownArrow, DownIcon } from "../../assets/icons";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Select = ({
  items,
  value,
  onChange,
  className,
  placeholder,
  icon,
  colliderClassName = "",
  textPlaceholder,
  textClassName = "text-secondary-300",
  menuClassName = "",
  menuItemClassName = "",
  disabled,
}) => {
  return (
    <Listbox
      disabled={disabled}
      value={value}
      onChange={(i) => onChange(i.value)}
    >
      {({ open }) => (
        <div>
          {placeholder && (
            <span className="text-sm mb-2 pl-0 text-secondary-700 font-medium leading-5 sm:text-[10px] sm:leading-4 sm:mb-[2px]  md:mb-1 lg:mb-1 lg:text-xs md:text-xs md:leading-[18px] lg:leading-[18px]">
              {placeholder}
            </span>
          )}
          <div className={`relative ${colliderClassName}`}>
            <Listbox.Button
              className={`${
                open
                  ? ` ${
                      className ? " " + className : ""
                    } !rounded-t-default !rounded-b-none`
                  : `${
                      className ? className : "bg-white border"
                    } text-secondary-600`
              }   sm:min-h-[36px] lg:min-h-[44px] min-h-[44px]  relative w-full px-[14px] sm:px-xs py-[9px]  flex items-center border rounded-lg border-secondary-300 outline-secondary-300 text-secondary-500 leading-6  lg:text-sm md:text-sm sm:text-xs focus:ring-0 text-[16px]`}
            >
              {icon && icon}
              <span className="block truncate ml-0.5">
                {items?.find((d) => d.value === value)?.label}
              </span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-4 pointer-events-none">
                <DownIcon
                  className={`h-2 w-2  ${
                    open
                      ? "rotate-180 text-secondary-600"
                      : " text-secondary-600"
                  } `}
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={`absolute z-10  mt-0 w-full bg-white shadow-lg max-h-60 py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm ${
                  open
                    ? "rounded-bl-lg rounded-br-lg border-gray-300 text-secondary-700 border-t-0 border-[1px]"
                    : ""
                }`}
              >
                {items &&
                  items.map((item) => (
                    <Listbox.Option
                      key={item.value}
                      className={({ active }) =>
                        classNames(
                          item.value === value
                            ? "bg-white text-black"
                            : "text-black",
                          "cursor-pointer select-none relative hover:bg-gray-200 rounded-lg mx-1 py-2 pl-3 text-xs"
                        )
                      }
                      value={item}
                    >
                      {({ active }) => (
                        <>
                          <span
                            className={classNames(
                              item.value === value
                                ? "font-semibold"
                                : "font-normal",
                              "block truncate"
                            )}
                          >
                            {item.label}
                          </span>
                        </>
                      )}
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </div>
        </div>
      )}
    </Listbox>
  );
};

export default Select;
