import React, { useState } from "react";
import { PlusIcon, Update, FilterIcon } from "../../../assets/icons";
import {
  SearchInput,
  ChangeLanguage,
  NewAddButton,
  AdminModal,
  SelectSoftwareModal,
} from "../../../components";
import { softwareService } from "../../../services";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
const NodesPageHeader = ({
  t,
  refetch,
  //
  //
  setSelectedNode,
  selectedNodes,
  setSelectedNodes,
  selectLanguage,
  setSelectLanguage,
  visibleLanguage,
  setVisibleLanguage,
  setUpdateReportData,
  setUpdateReportModal,
  setAddNodModal,
  setSearchParams,
  filter,
  sort,
  order,
  companyNodes,
}) => {
  const [selectSoftwareModal, setSelectSoftwareModal] = useState(false);
  const softwares = useQuery({
    queryKey: ["get-softwares"],
    queryFn: async () => await softwareService.getSoftwares(),
    retry: 0,
  });

  const navigate = useNavigate();

  return (
    <div className="w-full flex flex-row items-center justify-between">
      <span className="text-secondary-900 text-[28px] font-medium ml-10 select-none">
        {t("auth:nodeList")}
      </span>
      <div className="flex gap-4 items-center">
      <NewAddButton
              width={140}
              label={
               "Graph View"
              }
              icon={<Update className="w-5 h-5 text-gray-600" />}
              onClick={() => {
                navigate("/dashboard/system-node-chart")
              }}
            />
        <NewAddButton
          width={240}
          label={
            selectedNodes?.length == 0
              ? t("buttons:updateAllDevices")
              : t("buttons:updateSelectedDevices")
          }
          icon={<Update className="w-5 h-5 text-gray-600" />}
          onClick={() => {
            setSelectedNode(null);
            if (selectedNodes?.length == 0) {
              setSelectedNodes(
                companyNodes?.map((item) => {
                  return {
                    companyId: item?.id,
                    nodes: item?.nodes?.map((item2) => {
                      return item2?.deviceId;
                    }),
                  };
                })
              );
            }
            setSelectSoftwareModal(true);
          }}
        />
        <NewAddButton
          width={170}
          label={t("buttons:addNewNode")}
          primary={true}
          icon={
            <PlusIcon width={16} height={16} className="w-4 h-4 text-white" />
          }
          onClick={() => {
            setSelectedNode(null);
            setAddNodModal(true);
          }}
        />
        <SearchInput
          width={200}
          setSearchVal={(searchVal) => {
            const newParams = {
              page: 1,
              search: searchVal,
              filter: filter ? filter : "all",
              sort: sort,
              order: order,
            };
            filter == null && delete newParams.filter;
            sort == null && delete newParams.sort;
            searchVal == "" && delete newParams.search;
            order == null && delete newParams.order;
            setSearchParams(newParams);
          }}
          placeholder={t("auth:search")}
        />
        <ChangeLanguage
          setVisibleLanguage={setVisibleLanguage}
          setSelectedLanguage={setSelectLanguage}
          selectedLanguage={selectLanguage}
          visibleLanguage={visibleLanguage}
        />
      </div>
      <AdminModal
        modalIsOpen={selectSoftwareModal}
        closeModal={() => {
          setSelectSoftwareModal(false);
          setSelectedNodes([]);
        }}
        height={"550px"}
        width={"500px"}
        title={t("software:updateDevice")}
        children={
          <SelectSoftwareModal
            setIsOpen={setSelectSoftwareModal}
            selectedNodes={selectedNodes}
            softwares={softwares?.data?.data}
            handleCompanyNodes={refetch}
            setSelectedNodes={setSelectedNodes}
            setUpdateReportData={setUpdateReportData}
            setUpdateReportModal={setUpdateReportModal}
          />
        }
      />
    </div>
  );
};

export default NodesPageHeader;
