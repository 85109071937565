import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { companyTableFilterDashboard } from "../../static/constants";
import { PlusIcon, UpuCompany, BellIcon } from "../../assets/icons";
import { companyService } from "../../services";
import i18n from "i18next";
import {
  AddNewIndustryButton,
  PaginationComponent,
  Loading,
  SearchInput,
  AdminModal,
  AddNewIndustry,
  NewAddButton,
  UserTable,
  FilterButton,
  FilterModal,
  CompanyForm,
  ChangeLanguage,
  IconButton,
  NotificationModal,
} from "../../components";
const Dashboard = observer(() => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [filterModalIsOpen, setFilterModalIsOpen] = useState(false);
  const [addCompany, setAddCompany] = useState(false);
  const [searchVal, setSearchVal] = useState(null);
  const [selectLanguage, setSelectLanguage] = useState(i18next.language);
  const [selectedCompany, setSelectedCompany] = useState({});
  const [visibleLanguage, setVisibleLanguage] = useState(false);
  const [industries, setIndustries] = useState([]);
  const [ccounts, setCCounts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notifyModal, setNotifyModal] = useState(false);
  const [filterVal, setFilterVal] = useState("all");
  useEffect(() => {
    setSelectLanguage(i18next.language);
  }, [i18next.language]);
  const handleIndustries = async () => {
    await companyService
      .listIndustry()
      .then((res) => {
        setIndustries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCCounts = async () => {
    await companyService
      .listCCounts()
      .then((res) => {
        setCCounts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllCompaniesPaginated = async () => {
    setLoading(true);
    await companyService
      .listCompaniesPaginated(pageNo, searchVal, filterVal)
      .then((res) => {
        setTotalCount(res.data.meta.itemCount);
        setPageCount(res.data.meta.pageCount);
        setPageData(res.data.data);
        if ((Number(pageNo) + 1) >> res.data.meta.pageCount) {
          setPageNo(1);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };
  useEffect(() => {
    handleIndustries();
    handleCCounts();
  }, []);
  useEffect(() => {
    getAllCompaniesPaginated();
  }, [pageNo, searchVal, filterVal]);
  return (
    <>
      <div className="w-full h-full no-scrollbar xs:hidden overflow-hidden">
        <div className="flex flex-row items-center justify-between">
          <span className="text-secondary-900 text-[28px] font-medium ml-10">
            <UpuCompany className="w-40 h-9 translate-y-2" />
          </span>
          <div className="flex gap-4 items-center">
            {/* <FilterButton onClick={() => setFilterModalIsOpen(true)} /> */}
            <SearchInput
              width={250}
              setSearchVal={setSearchVal}
              placeholder={t("auth:search")}
            />
            <AddNewIndustryButton
              width={190}
              onClick={() => setOpenModal(true)}
              placeholder={t("buttons:addNewIndustry")}
            />
            <AddNewIndustryButton
              width={190}
              placeholder={t("company:addNewCompany")}
              onClick={() => {
                setSelectedCompany(null);
                setAddCompany(true);
              }}
            />
            <IconButton
              icon={<BellIcon />}
              className="bg-white select-none rounded-md w-11 h-11 border border-primary-300 text-primary-700 flex hover:bg-secondary-100"
              onClick={() => {
                setNotifyModal(true);
              }}
            />
            <ChangeLanguage
              setVisibleLanguage={setVisibleLanguage}
              setSelectedLanguage={setSelectLanguage}
              selectedLanguage={selectLanguage}
              visibleLanguage={visibleLanguage}
            />
          </div>
        </div>
        <div className="h-px w-full bg-secondary-200 drop-shadow-md mt-3" />
        <div className="h-[85%] w-full mt-6 overflow-y-scroll overflow-x-scroll no-scrollbar border border-gray-300 rounded-lg shadow-md relative">
          <div className="sticky justify-between top-0 flex h-11 bg-gray-50 border-b border-gray-200 ">
            <span
              className={`flex justify-center gap-3 h-full items-center min-w-[200px] max-w-[200px] text-xs text-gray-500 cursor-pointer hover:bg-gray-100`}
            >
              {t("dashboard:image")}
            </span>
            {companyTableFilterDashboard.map((item) => (
              <span
                key={item.value}
                className={`flex justify-center gap-3 h-full items-center min-w-[200px] w-[200px] text-xs text-gray-500 cursor-pointer hover:bg-gray-100 ${
                  item.checked || "hidden"
                }`}
              >
                {item.checked ? t(`dashboard:${item.value}`) : ""}
              </span>
            ))}
          </div>
          <div className="w-full h-[90%] flex flex-col">
            {loading ? (
              <div className="flex items-center w-full justify-center h-full">
                <div className="w-12 h-12">
                  <Loading />
                </div>
              </div>
            ) : (
              pageData.map((company) => {
                return (
                  <UserTable
                    tableFilter={companyTableFilterDashboard}
                    setSelectedCompany={setSelectedCompany}
                    company={company}
                    companyOpen={() => setAddCompany(true)}
                  />
                );
              })
            )}
          </div>
        </div>
        <PaginationComponent
          pageNo={pageNo}
          setPageNo={setPageNo}
          pageCount={pageCount}
        />
      </div>
      <AdminModal
        modalIsOpen={addCompany}
        closeModal={() => setAddCompany(false)}
        children={
          <CompanyForm
            setIsOpen={setAddCompany}
            industries={industries?.map((com) => {
              return {
                value: com?.id,
                label: com?.name[i18n.language],
              };
            })}
            ccounts={ccounts?.map((cc) => {
              return {
                value: cc?.id,
                label: `${cc?.zone}-${cc?.value}`,
              };
            })}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            getAllCompanies={getAllCompaniesPaginated}
          />
        }
        height={"850px"}
        title={
          !selectedCompany
            ? t("company:addNewCompany")
            : t("company:editCompany")
        }
      />
      <AdminModal
        modalIsOpen={openModal}
        closeModal={() => setOpenModal(false)}
        height={"420px"}
        width={"859px"}
        children={
          <AddNewIndustry
            setIsOpen={setOpenModal}
            getAllCompanies={getAllCompaniesPaginated}
            handleIndustries={handleIndustries}
          />
        }
        title={t("buttons:addNewIndustry")}
      />
      <AdminModal
        modalIsOpen={notifyModal}
        closeModal={() => setNotifyModal(false)}
        height={"440px"}
        width={"400px"}
        children={<NotificationModal setIsOpen={setNotifyModal} />}
        title={t("notification:sendNotification")}
      />
      <FilterModal
        isOpen={filterModalIsOpen}
        onRequestClose={() => setFilterModalIsOpen(false)}
        filterVal={filterVal}
        setFilterVal={setFilterVal}
        companies={[
          { label: "All", value: "all" },
          ...industries?.map((com) => {
            return {
              value: com.id,
              label: com.name[i18n.language],
            };
          }),
          ,
        ]}
      />
      {/* mobile wiew */}
      <div className="hidden xs:flex w-full h-full gap-3 flex-col">
        <div className=" w-full flex items-center justify-between gap-2">
          <SearchInput width={"86%"} placeholder={"Search"} />
          <NewAddButton icon={<PlusIcon />} />
        </div>

        <div className="w-full h-full border mt-2 border-gray-200 rounded-lg shadow flex flex-col">
          <article className="flex flex-col w-full">
            <div
              className={`flex w-full items-center justify-between h-[76px] px-6  border-b border-gray-300`}
            >
              <div className="flex gap-4 items-center">
                <span className="text-lg font-medium text-gray-900 cursor-pointer select-none">
                  Company
                </span>
                <span className="py-0.5 px-2 bg-[#F9F5FF] rounded-2xl text-xs text-primary-700 select-none">
                  {t("dashboard:company")}
                </span>
              </div>
            </div>
            <div className=" w-full h-36 bg-secondary-200 pl-2 overflow-y-scroll no-scrollbar flex">
              <div className="flex flex-col w-1/2 h-9 border-b border-secondary-200 bg-white"></div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
});

export default Dashboard;
