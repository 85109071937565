import React from "react";
import { useTranslation } from "react-i18next";
import { SearchIcon } from "../../assets/icons";
const SearchInput = ({ placeholder, width, border, setSearchVal, onClick }) => {
  return (
    <div
      className={`relative border rounded-lg flex items-center border-gray-300 px-[10px] placeholder-secondary-700 text-secondary-400 h-11`}
      style={{
        width: width,
        border: border,
      }}
    >
      <SearchIcon className="w-5 h-5" />
      <input
        className="w-full h-full ml-1.5 text-base text-secondary-800 focus:outline-none focus:border-none "
        placeholder={placeholder}
        onChange={(e) => setSearchVal(e.target.value)}
        onClick={onClick}
      />
    </div>
  );
};

export default SearchInput;
