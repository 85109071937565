import React from "react";
import { UpIcon } from "../../../assets/icons";
import moment from "moment";

const NodeTable = ({
  onClick,
  node,
  setSelectedNode,
  tableFilter,
  setAddNodModal,
  selectedNodes,
  setSelectedNodes,
}) => {
  const handleSelect = (companyId, nodeId) => {
    if (selectedNodes.length > 0) {
      let index = selectedNodes.findIndex((x) => x.companyId === companyId);
      if (index !== -1) {
        let index2 = selectedNodes[index].nodes.findIndex((x) => x === nodeId);
        if (index2 !== -1) {
          const newNodes = (selectedNodes[index].nodes = selectedNodes[
            index
          ].nodes.filter((item) => item !== nodeId));
          if (newNodes.length === 0) {
            selectedNodes.splice(index, 1);
            setSelectedNodes([...selectedNodes]);
          } else {
            setSelectedNodes([...selectedNodes]);
          }
        } else {
          selectedNodes[index].nodes.push(nodeId);
          setSelectedNodes([...selectedNodes]);
        }
      } else {
        setSelectedNodes([
          ...selectedNodes,
          {
            companyId: companyId,
            nodes: [nodeId],
          },
        ]);
      }
    } else {
      setSelectedNodes([
        {
          companyId: companyId,
          nodes: [nodeId],
        },
      ]);
    }
  };
  return (
    <div className="w-full max-h-[70.2px] min-h-[70.2px] flex flex-col select-none overflow-x-hidden overflow-y-scroll h-full">
      <ul className="flex w-full h-full border-b border-gray-200 items-center bg-white">
        <li className="h-full min-w-[100px] border-r border-gray-200 flex items-center justify-center group-hover:bg-slate-50">
          <span
            style={{
              backgroundColor:
                node.deviceState === "NOT_CONNECTED" ? "#D92D00" : "#0E9384",
            }}
            className="w-3 h-3 rounded-full border border-gray-300 shadow-sm"
          ></span>
        </li>
        {tableFilter.map((d, i) => {
          return (
            <li
              key={i}
              onClick={() => {
                setSelectedNode(node);
                if (
                  !(
                    d.value == "ipAddress" ||
                    d.value == "espVersion" ||
                    d.value == "update"
                  )
                ) {
                  setAddNodModal(true);
                }
              }}
              className="h-full border-r select-none w-full text-center min-w-[111px] flex items-center justify-center text-sm text-secondary-600 group-hover:bg-slate-50"
            >
              {d.value == "ipAddress" ? (
                <a
                  target={"_blank"}
                  href={`http://${node[d.value]}`}
                  className="flex items-center gap-1"
                  style={{ cursor: "pointer" }}
                >
                  {node[d.value]}
                  <UpIcon width={10} height={10} />
                </a>
              ) : d.value == "brand" ? (
                <div className="text-xs">
                  {node?.metadata?.brand ? node?.metadata?.brand : "NONE"}
                  {" / "}
                  {node?.metadata?.model ? node?.metadata?.model : "NONE"}
                </div>
              ) : d.value == "company" ? (
                <div className="text-xs">
                  {node?.company
                    ? node?.company?.name
                      ? node?.company?.name
                      : "NONE"
                    : "NONE"}
                </div>
              ) : d.value == "update" ? (
                <div
                  onClick={() => {
                    handleSelect(node?.company?.id, node.deviceId);
                  }}
                  className="text-xs w-full h-full flex items-center justify-center cursor-pointer"
                >
                  <input
                    onChange={() => {}}
                    checked={selectedNodes?.some(
                      (n) =>
                        n?.companyId === node?.company?.id &&
                        n?.nodes?.includes(node?.deviceId)
                    )}
                    type="checkbox"
                    className="w-4 h-4 rounded-md border text-primary-600 bg-primary-100 border-primary-50 accent-primary-500"
                  />
                </div>
              ) : d.value == "createdDate" ? (
                <span>{moment(node?.createdDate).format("DD/MM/YYYY")}</span>
              ) : d.value == "updatedDate" ? (
                <span>{moment(node?.updatedDate).format("DD/MM/YYYY")}</span>
              ) : (
                node[d.value] && node[d.value]
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NodeTable;
