import { yupResolver } from "@hookform/resolvers/yup";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import Input from "../../components/Inputs/Input";
import i18n from "i18next";
import { useStore } from "../../hooks/useStores";
import { useAlert, positions } from "react-alert";
import { sensorService } from "../../services";
import Select from "../../components/Inputs/Option";
import { MinusCircleIcon } from "@heroicons/react/outline";
import { PlusIcon } from "../../assets/icons";
const SensorForm = ({
  setOpenModal,
  selectedSensor,
  handleSensorsPaginated,
}) => {
  const { app } = useStore();
  const alert = useAlert();
  const [sensorUnitArr, setSensorUnitArr] = useState([]);
  const [unitName, setUnitName] = useState("");
  const [unitNameShort, setUnitNameShort] = useState("");
  const schema = yup.object({
    name: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
    type: yup
      .string()
      .required(`${t("validation:empty")}`)
      .typeError(`${t("validation:string")}`),
  });
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      type: "",
    },
  });
  useEffect(() => {
    if (selectedSensor) {
      setValue("name", selectedSensor?.name);
      setValue("type", selectedSensor?.type);
      setSensorUnitArr(selectedSensor?.unitList);
    } else {
    }
  }, [selectedSensor]);
  const onSubmit = async (data) => {
    if (sensorUnitArr.length > 0) {
      app.setLoadingOverlay(true);
      if (selectedSensor && selectedSensor.id) {
        await sensorService
          .updateSensor(selectedSensor.id, {
            name: data.name,
            type: data.type,
            unitList: sensorUnitArr,
          })
          .then((res) => {
            if (res.data.status === 1) {
              app.setLoadingOverlay(false);
              alert.success("Updated.", {
                position: positions.TOP_LEFT,
              });
              handleSensorsPaginated();
              setOpenModal(false);
            } else {
              app.setLoadingOverlay(false);
              alert.success("Failed.", {
                position: positions.TOP_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            app.setLoadingOverlay(false);
          });
      } else {
        await sensorService
          .createSensor({
            name: data.name,
            type: data.type,
            unitList: sensorUnitArr,
          })
          .then((res) => {
            if (res.data.status === 1) {
              app.setLoadingOverlay(false);
              alert.success("Successfull.", {
                position: positions.TOP_LEFT,
              });
              handleSensorsPaginated();
              setOpenModal(false);
            } else {
              app.setLoadingOverlay(false);
              alert.success("Failed.", {
                position: positions.TOP_LEFT,
              });
            }
          })
          .catch((err) => {
            console.log(err);
            app.setLoadingOverlay(false);
          });
      }
    } else {
      alert.error("Need an unit.", {
        position: positions.TOP_LEFT,
      });
    }
  };
  const deleteSensor = async () => {
    await sensorService
      .deleteSensor(selectedSensor.id)
      .then((res) => {
        if (res.data.status === 1) {
          app.setLoadingOverlay(false);
          alert.success("Deleted.", {
            position: positions.TOP_LEFT,
          });
          handleSensorsPaginated();
          setOpenModal(false);
        } else {
          app.setLoadingOverlay(false);
          alert.success("Failed.", {
            position: positions.TOP_LEFT,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        app.setLoadingOverlay(false);
      });
  };
  const addNewUnit = () => {
    if (unitName != "" && unitNameShort != "") {
      setSensorUnitArr([
        ...sensorUnitArr,
        { unitName: unitName, unitNameShort: unitNameShort },
      ]);
      setUnitName("");
      setUnitNameShort("");
    }
  };
  const removeUnit = (e) => {
    setSensorUnitArr(
      sensorUnitArr.filter((u, i) => {
        return i != e;
      })
    );
  };
  return (
    <div className="w-full h-full flex flex-col justify-between">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-between w-full h-full gap-6 mt-3 mb-3"
      >
        <div className="w-full flex flex-col justify-between gap-4">
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Input
                label={t("sensor:name")}
                width={"100%"}
                value={value}
                disabled={false}
                onBlur={onBlur}
                onChange={onChange}
                validate={errors.name ? "error" : ""}
                errorMessage={errors.name ? errors.name.message : ""}
              />
            )}
            name="name"
          />
          <Controller
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <Select
                placeholder={t("sensor:type")}
                items={[
                  { label: "Analog", value: "analog" },
                  { label: "Digital", value: "digital" },
                ]}
                onBlur={onBlur}
                onChange={(v) => {
                  setValue("type", v);
                }}
                value={value}
                validate={errors.type ? "error" : ""}
                errorMessage={errors.type ? errors.type.message : ""}
              />
            )}
            name="type"
          />
          {sensorUnitArr.length > 0 &&
            sensorUnitArr.map((unit, i) => {
              return (
                <div className="flex items-center w-full justify-between gap-x-2">
                  <Input
                    disabled={true}
                    label={t("sensor:unitNameShort")}
                    width={"30%"}
                    value={unit.unitNameShort}
                    onChange={(e) => {
                      setUnitNameShort(e.target.value);
                    }}
                  />
                  <Input
                    disabled={true}
                    label={t("sensor:unitName")}
                    width={"70%"}
                    value={unit.unitName}
                    onChange={(e) => {
                      setUnitName(e.target.value);
                    }}
                  />
                  <div
                    onClick={() => {
                      removeUnit(i);
                    }}
                    className="flex mt-[25px] items-center justify-center"
                  >
                    <MinusCircleIcon width={20} color={"#475467"} />
                  </div>
                </div>
              );
            })}
          <div className="flex items-center w-full justify-between gap-x-2">
            <Input
              label={t("sensor:unitNameShort")}
              width={"30%"}
              value={unitNameShort}
              onChange={(e) => {
                setUnitNameShort(e.target.value);
              }}
            />
            <Input
              label={t("sensor:unitName")}
              width={"70%"}
              value={unitName}
              onChange={(e) => {
                setUnitName(e.target.value);
              }}
            />
          </div>
          <button
            type="button"
            onClick={() => addNewUnit()}
            className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center gap-x-2 justify-center text-sm font-medium text-gray-700 border border-gray-300 hover:bg-slate-100"
          >
            <PlusIcon />
            {t("sensor:addNewUnit")}
          </button>
        </div>
        <div className="w-[390px] absolute bottom-3 mx-auto flex gap-4">
          {selectedSensor?.id && (
            <button
              type="button"
              onClick={() => deleteSensor()}
              className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-red-600 shadow hover:bg-red-500"
            >
              {t("buttons:delete")}
            </button>
          )}
          <button
            type="button"
            onClick={() => setOpenModal(false)}
            className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-gray-700 border border-gray-300 shadow hover:bg-slate-100"
          >
            {t("buttons:stop")}
          </button>
          <button
            type="submit"
            className="w-full h-10 rounded-lg py-2.5 px-4 flex items-center justify-center text-sm font-medium text-white bg-primary-600 shadow hover:bg-primary-500"
          >
            {t("buttons:save")}
          </button>
        </div>
      </form>
    </div>
  );
};

export default SensorForm;
