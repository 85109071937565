import React, { useEffect } from "react";

const UnitInput = ({
  width,
  setValue,
  value,
  label,
  placeholder,
  disabled,
  units,
  unitValue,
  setUnitValue,
}) => {
  useEffect(() => {
    setUnitValue(units?.length > 0 ? units[0]?.unitNameShort : "-");
  }, [units, unitValue, setUnitValue]);

  return (
    <div style={{ width: width || "100%" }} className="flex flex-col">
      {label && (
        <span className="text-sm mb-1.5 pl-0  w-full text-secondary-700 dark:text-darkTrue-200 font-medium">
          {label}
        </span>
      )}
      <div className="flex h-[40px] w-full border border-gray-300 rounded-lg">
        <input
          value={value}
          onChange={({ target }) => {
            setValue(target?.value);
          }}
          disabled={disabled}
          type="text"
          className={`h-full shrink border-transparent text-base font-normal outline-none ${
            placeholder
              ? "text-secondary-900"
              : disabled
              ? "text-secondary-500"
              : "text-secondary-900"
          } focus:border-transparent focus:ring-0 border-none rounded-lg w-full px-4
          ${disabled ? "bg-secondary-50 " : <></>} `}
        />
        <div className="min-w-[43px] h-full border-l border-gray-300 flex items-center justify-center ml-auto select-none relative">
          <select
            name="unit"
            disabled={disabled}
            className={`absolute inset-0 opacity-0`}
            value={unitValue}
            onChange={({ target }) => {
              setUnitValue(target.value);
            }}
          >
            {units?.map((item, index) => {
              return (
                <option key={index} value={item?.unitNameShort}>
                  {item?.unitNameShort}
                </option>
              );
            })}
          </select>
          <p className="text-secondary-600 font-semibold">{unitValue}</p>
        </div>
      </div>
    </div>
  );
};

export default UnitInput;
