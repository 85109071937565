import React, { useState, useEffect } from "react";
import { MultiSelect } from "../../../components";
const FilterSortDropdown = ({
  t,
  filterDropdownData,
  setFilterDropdown,
  setFilterDropdownData,
  setSearchParams,
  filter,
  search,
  sort,
  order,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [appliedFilterByType, setAppliedFilterByType] = useState(false);
  const [appliedFilter, setAppliedFilter] = useState(false);
  useEffect(() => {
    if (filter) {
      const spilt = filter?.split(";");
      setAppliedFilter(true);
      const find = spilt.find((item) => item.includes(filterDropdownData.type));
      if (find) {
        setAppliedFilterByType(true);
        const split = find.split("=");
        const selected = filterDropdownData.items.filter((item) =>
          split[1].split(":").includes(item.value)
        );
        setSelectedItems(selected);
      }
    }
  }, []);
  const applyFilter = () => {
    if (selectedItems.length > 0) {
      let newFilter;
      const newFilterByType = `${filterDropdownData?.type}=${selectedItems
        ?.map((item) => item?.value)
        .filter((item) => item !== "")
        .join(":")}`;
      if (filter) {
        const spilt = filter?.split(";");
        const filterSplitAndJoin = spilt.filter(
          (item) => !item.includes(filterDropdownData.type)
        );
        if (filterSplitAndJoin.length > 0)
          newFilter = `${filterSplitAndJoin.join(";")};${newFilterByType}`;
        else newFilter = newFilterByType;
      } else {
        newFilter = newFilterByType;
      }
      const newParams = {
        page: 1,
        search: search ? search : "",
        filter: newFilter,
        sort: sort,
        order: order,
      };
      sort == null && delete newParams.sort;
      search == null && delete newParams.search;
      order == null && delete newParams.order;
      setFilterDropdown(false);
      setFilterDropdownData(null);
      setSearchParams(newParams);
    }
  };
  const removeFilterByType = () => {
    const spilt = filter?.split(";");
    const filterSplitAndJoin = spilt
      .filter((item) => !item.includes(filterDropdownData.type))
      .join(";");
    const newParams = {
      page: 1,
      search: search ? search : "",
      filter: filterSplitAndJoin.length > 0 ? filterSplitAndJoin : null,
      sort: sort,
      order: order,
    };
    newParams.filter == null && delete newParams.filter;
    sort == null && delete newParams.sort;
    search == null && delete newParams.search;
    order == null && delete newParams.order;
    setFilterDropdown(false);
    setFilterDropdownData(null);
    setSearchParams(newParams);
  };
  const removeAllFilters = () => {
    const newParams = {
      page: 1,
      search: search ? search : "",
      sort: sort,
      order: order,
    };
    sort == null && delete newParams.sort;
    search == null && delete newParams.search;
    order == null && delete newParams.order;
    setFilterDropdown(false);
    setFilterDropdownData(null);
    setSearchParams(newParams);
  };
  const handleSorting = (type) => {
    const newParams = {
      page: 1,
      search: search ? search : "",
      filter: filter,
      sort: filterDropdownData.type,
      order: type,
    };
    filterDropdownData.type == null && delete newParams.sort;
    filter == null && delete newParams.filter;
    search == null && delete newParams.search;
    type == null && delete newParams.order;
    setFilterDropdown(false);
    setFilterDropdownData(null);
    setSearchParams(newParams);
  };
  return (
    <div className="border bg-white border-gray-200 w-[300px] min-w-[300px] max-w-[300px]  flex flex-col items-center justify-between p-2 rounded gap-y-3">
      <div className="w-full flex flex-col items-center justify-center gap-1">
        <div className="w-full hover:bg-gray-100 flex items-center text-sm pl-0 text-secondary-700 font-medium p-2 rounded cursor-pointer">
          <span
            className=""
            onClick={() => {
              handleSorting("ASC");
            }}
          >
            {t("buttons:sortBy")} {filterDropdownData.name} (A-Z)
          </span>
        </div>
        <div className="w-full hover:bg-gray-100 flex items-center text-sm pl-0 text-secondary-700 font-medium p-2 rounded cursor-pointer">
          <span
            className=""
            onClick={() => {
              handleSorting("DESC");
            }}
          >
            {t("buttons:sortBy")} {filterDropdownData.name} (Z-A)
          </span>
        </div>
      </div>
      {filterDropdownData?.items?.length > 0 && (
        <button
          style={{ width: "100%" }}
          onClick={applyFilter}
          className={`h-[36px] flex items-center justify-center border rounded-lg shadow text-sm
        ${
          selectedItems.length > 0
            ? "bg-primary-600 border-primary-600 text-white hover:bg-primary-500"
            : "text-gray-600 border-gray-300 bg-gray-100 disabled"
        }
       `}
        >
          {t("buttons:applyFilter")}
        </button>
      )}
      {appliedFilter && (
        <div className="flex items-center justify-center gap-2 w-full">
          {appliedFilterByType && (
            <button
              style={{ width: "100%" }}
              onClick={removeFilterByType}
              className={`h-[36px] flex items-center justify-center border rounded-lg shadow text-sm bg-red-600 border-red-600 text-white hover:bg-red-500`}
            >
              {t("buttons:removeFilter")}
            </button>
          )}
          <button
            style={{ width: "100%" }}
            onClick={removeAllFilters}
            className={`h-[36px] flex items-center justify-center border rounded-lg shadow text-sm bg-red-600 border-red-600 text-white hover:bg-red-500`}
          >
            {t("buttons:removeAllFilters")}
          </button>
        </div>
      )}
      {filterDropdownData?.items?.length > 0 && (
        <div className="w-full">
          <MultiSelect
            placeholder={`Filter ${filterDropdownData.name}`}
            width={"100%"}
            disabled={false}
            items={filterDropdownData?.items}
            isImage={filterDropdownData?.type == "company" ? true : false}
            setSelectedItems={setSelectedItems}
            selectedItems={selectedItems}
          />
        </div>
      )}
    </div>
  );
};

export default FilterSortDropdown;
